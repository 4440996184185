import * as types from "../actions/types";

const INITIAL_STATE = {
  orders: null,
  orders_loading: false,
  orders_fulfilled: 0,
  work_station_printer_id: 68935012,
  order_parse_type: "all",
  work_station_printer_ids: null,
  generating_pick_slips_loading: false,
  pick_slip_id: null,
  last_refreshed: new Date().toLocaleString("en-US", {
    timeZone: "America/New_York"
  })
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TOGGLE_AWAITING_ORDERS_LOADING:
      return {
        ...state,
        orders_loading: !state.orders_loading
      };
    case types.INCREASE_ORDERS_FULFILLED_COUNT:
      return {
        ...state,
        orders_fulfilled: state.orders_fulfilled + 1
      };
    case types.SET_WORKSTATION_PRINTER_ID:
      return {
        ...state,
        work_station_printer_id: action.payload
      };
    case types.SET_ORDER_PARSE_TYPE:
      return {
        ...state,
        order_parse_type: action.payload
      };
    case types.FETCH_ALL_AWAITING_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        orders_loading: false,
        last_refreshed: new Date().toLocaleString("en-US", {
          timeZone: "America/New_York"
        })
      };
    case types.TOGGLE_GENERATING_PICK_SLIPS_LOADING:
        return {
          ...state,
          generating_pick_slips_loading: !state.generating_pick_slips_loading
        };
    case types.SET_PICK_SLIP_ID:
        return {
          ...state,
          pick_slip_id: action.payload
        };
    case types.FETCH_ALL_WORKSTATION_PRINTER_IDS:
        return {
          ...state,
          work_station_printer_ids: action.payload
        };
    default:
      return state;
  }
}
