import React from "react";
import { Step } from "semantic-ui-react";



class GlassSteps extends React.Component {
  constructor(props) {
    super(props);

    this.renderCompletedStep = this.renderCompletedStep.bind(this);
  }

  handleClick = (e, { title }) => this.props.setGlassStep(title);

  renderMenuSteps() {
    var steps = [ "Item List", "Reference" ];

      return steps

  }
  renderCompletedStep(step, activeStep) {
    var stepDict = {};
    var count = 1;
    this.renderMenuSteps().map(function(step) {
      stepDict[step] = count;
      count += 1;
      return step;
    });
    if (stepDict[step] >= stepDict[activeStep]) {
      return false;
    } else {
      return true;
    }
  }
  renderSteps() {
    var that = this;
    return this.renderMenuSteps().map(function(step) {
      return (
        <Step
          key={step}
          title={step}
          active={that.props.step === step}
          link
          completed={that.renderCompletedStep(step, that.props.step)}
          onClick={that.handleClick}
        />
      );
    });
  }
  render() {
    return (
      <div style={{
          width: "100%",
          margin: "auto",
          marginTop: "21px"
      }}>
        <Step.Group fluid size="small">
          {this.renderSteps()}
        </Step.Group>
      </div>
    );
  }
}

export default GlassSteps;
