import React from "react";
var Barcode = require('react-barcode');
var dateFormat = require("dateformat");


// Single Print SLip
class PrintSlip extends React.Component { 

    renderPickItems(cartItems) {
        let sortedCartItems = Object.assign([], cartItems)
        sortedCartItems = sortedCartItems.sort((a, b) => (a.sku > b.sku) ? 1 : -1)
        return sortedCartItems.map(function(cartItem) {
            return (
                <div className={cartItems.length > 12 ? "pick-slip-row" : "pick-slip-row"} key={cartItem.sku}>
                    <div  className={cartItems.length > 12 ? "pick-slip-row-item-small print-image" : "pick-slip-row-item print-image"}>
                        <img src={cartItem.image} alt={cartItem.image} />
                    </div>
                    <div className="pick-slip-row-item print-location">
                        <p>Bin #: N/A</p>
                    </div>
                    <div className="pick-slip-row-item print-sku">
                        <p>{cartItem.sku}</p>
                    </div>
                    <div className="pick-slip-row-item print-quantity">
                        <p>{cartItem.quantity}</p>
                    </div>
                </div>
            )
        })
        
    }

    render() {
        let { printSlipObject, ifReprint } = this.props;
       
        return (
            <div className="single-slip-container">
                <div className="order-information-container">
                    <p><b>Date Pick Slip Printed:</b> {dateFormat(printSlipObject.order.time_pick_slip_printed, " mm/dd/yyyy, h:MM:ss TT")}</p>
                    <p><b>Pick Slip Group ID:</b> {printSlipObject.order.pick_slip_id.split("-")[1]}</p>
                    <hr />
                    <br />
                    <p><b>Order ID:</b> {printSlipObject.order.id}</p>
                    <p><b>Order Purchased On:</b> {dateFormat(printSlipObject.order.date_charged, " mm/dd/yyyy, h:MM:ss TT")}</p>
                    <p><b>Address:</b>{printSlipObject.shipping_address.address}</p>
                    <p><b>City:</b> {printSlipObject.shipping_address.city}</p>
                    <p><b>State:</b> {printSlipObject.shipping_address.state}</p>
                    <p><b>Zip Code:</b> {printSlipObject.shipping_address.zip_code}</p>
                </div>
                <div className="bar-code-container">
                    {
                        this.props.amazon && (
                            <img alt="amazon" src="//images.ctfassets.net/z3ixs6i8tjtq/5PhR9Nli1Js43AXOz31r8S/b645109055725e22d7194cfa5f171e92/amazon_PNG6.png" style={{width: "70px", position: "absolute", right: "40px", top: "30px"}}/>
                        )
                    }
                    <Barcode value={printSlipObject.order.pick_slip_id}  displayValue={true} />
                    {
                        ifReprint && (
                            <div className="reprint">
                                <b>REPRINT</b>
                            </div>
                        )
                    }
                    {
                        printSlipObject.order.pick_pack_priority && (
                            <div className="priority">
                                <b>PRIORITY ORDER</b>
                            </div>
                        )
                    }
                </div>
                <div className="product-container">
                    {this.renderPickItems(printSlipObject.cart_items)}
                </div>
            </div>
        );
    }
}

export default PrintSlip
