import React from "react";
import {
  Button,
  Card,
  Divider,
  Message,
  Header,
  Icon
} from "semantic-ui-react";
import { createAndPrintOrderLabel } from "../../../../actions/actionsOrders";
import { createAndPrintAmazonOrderLabel } from "../../../../actions/actionsAmazonOrders";
import { connect } from "react-redux";
import { barSoapWorkStationZeroActivate } from "../../../../globalSettings";

class LabelPrinting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labelIsPrinting: false
    };
  }

  createAndPrintLabel() {
    this.setState({
      labelIsPrinting: true
    });
    var data = {
      transaction_id: this.props.order.order.id,
      sales_order_id: this.props.order.order.sales_order_id,
      final_box_configuration: this.props.finalBoxConfiguration,
      workstation_printer_id: this.props.workStationPrinterID,
      usps_mailer_override: this.props.order.order.should_use_mailer &&  barSoapWorkStationZeroActivate// In the event that a mailer needs to be 
    };
    if (this.props.amazon) {
      this.props.createAndPrintAmazonOrderLabel(data);
    } else {
      this.props.createAndPrintOrderLabel(data);
    }
  }

  renderItems() {
    // renders the items inside of the Packing Slip labekl for pcker review
    return this.props.order.cart_items.map(function(cartItem) {
      return (
        <p key={cartItem.id}>
          {cartItem.marketing_name} × {cartItem.quantity}
        </p>
      );
    });
  }
  renderButtonText() {
    if (this.props.order.order.shipping_label_link) {
      return "Regenerate & Print Shipping Label" + this.renderSIfNeedBe();
    } else {
      return "Generate & Print Shipping Label" + this.renderSIfNeedBe();
    }
  }
  renderSIfNeedBe() {
    if (this.props.finalBoxConfiguration.length > 1) {
      return "s";
    } else {
      return "";
    }
  }
  render() {
    return (
      <div className="item-display-container">
        <Header as="h2" content="Label Printing" />
        <Card fluid>
          <Card.Content>
            <Card.Header>
              <Icon name="newspaper outline" /> Shipping Label ×{" "}
              {this.props.finalBoxConfiguration.length}
            </Card.Header>
            <Card.Meta>
              Print the label{this.renderSIfNeedBe()} now to be placed on top of
              the boxe{this.renderSIfNeedBe()} from the Pick Pack stage.
            </Card.Meta>
            <Card.Description>
              <p>{this.props.order.shipping_address.full_name}</p>
              <p>
                {this.props.order.shipping_address.address}{" "}
                {this.props.order.shipping_address.apt_number} <br />
              </p>
              <p>
                {this.props.order.shipping_address.city}{" "}
                {this.props.order.shipping_address.state},{" "}
                {this.props.order.shipping_address.zip_code}
              </p>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              animated="vertical"
              size="huge"
              disabled={this.props.noBoxTypeChoice}
              color={
                this.state.labelIsPrinting || this.props.labelIsPrinting
                  ? "green"
                  : "blue"
              }
              loading={this.props.ordersLoading || this.props.amazonOrdersLoading}
              onClick={() => this.createAndPrintLabel()}
              fluid
            >
              <Button.Content visible>{this.renderButtonText()}</Button.Content>
              <Button.Content hidden>
                <Icon name="print" />
              </Button.Content>
            </Button>
            {(this.state.labelIsPrinting || this.props.labelIsPrinting) && (
              <Message success>
                <Message.Header>
                  The shipping label should start printing momentarily!
                </Message.Header>
              </Message>
            )}
              {this.props.noBoxTypeChoice && (
              <p style={{textAlign: "center"}}>
                  <br /> You need to select the box type used in order to print a label.
                </p>
            )}
          </Card.Content>
        </Card>
        <div className="pick-pack-dock-footer">
          <Divider horizontal>Then</Divider>
          <Button
            animated="vertical"
            size="massive"
            disabled={!this.props.order.order.shipping_label_link}
            color="teal"
            fluid
            onClick={() => this.props.setPickPackStep("Confirm")}
          >
            <Button.Content visible> Next Step (Confirmation)</Button.Content>
            <Button.Content hidden>
              <Icon name="arrow right" />
            </Button.Content>
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ordersLoading: state.orders.orders_loading,
    amazonOrdersLoading: state.amazonOrders.orders_loading,
    workStationPrinterID: state.orders.work_station_printer_id
  };
}

export default connect(
  mapStateToProps,
  {
    createAndPrintOrderLabel,
    createAndPrintAmazonOrderLabel
  }
)(LabelPrinting);
