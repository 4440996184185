import React from "react";
import ReactTable from "react-table";
import { Header, Segment, Label } from "semantic-ui-react";
import { getAgeOfOrder, getAgeSeverity } from "../../helpers";
var dateFormat = require("dateformat");

class Table extends React.Component {
    render() {
        return (
        <Segment className="table-container" loading={this.props.loading}>
            <ReactTable
                data={this.props.data}
                defaultPageSize={10}
                noDataText="No Orders Found"
                columns={[
                    {
                        Header: "Age",
                        id: "age",
                        accessor: d => d.age,
                        Cell: ({ original }) => {
                            return (
                            <Header
                                size="tiny"
                                color={getAgeSeverity(
                                new Date(),
                                new Date(original.order.time_charged),
                                original.order.pick_pack_priority
                                )}
                            >
                                {getAgeOfOrder(
                                new Date(),
                                new Date(original.order.time_charged),
                                original.order.pick_pack_priority
                                )}
                            </Header>
                            );
                        }
                    },
                {
                    Header: "Status",
                    id: "status",
                    Cell: ({ original }) => {
                        return <Label>{original.order.shipment_status}</Label>;
                    }
                },
                {
                    Header: "Date Received",
                    id: "date_received",
                    accessor: d =>
                        dateFormat(d.order.time_charged, " mmmm dS, h:MM:ss TT") +
                        " EST",
                    sortMethod: (a, b) => {
                        var d1 = new Date(a);
                        var d2 = new Date(b);
                        return d1 > d2 ? 1 : -1;
                    }
                },
   
                {
                    Header: "Order Number",
                    id: "order_number",
                    accessor: d => d.order.id,
                    Cell: ({ original }) => {
                        return (
                            <>
                            {
                                this.props.amazon ? (
                                    <p>
                                        { original.order.id}
                                    </p>
                                ) : (
                                    <a rel="noopener noreferrer" href={`/scan-pick-slips/${ original.order.id}`} target="_blank">
                                        { original.order.id}
                                    </a>
                                )
                            }
                            </>
                        )
                    }
                },

            ]}
            className=" -highlight"
            />
        </Segment>
        );
    }
}

export default Table;
