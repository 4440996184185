import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import { toast } from "react-toastify";
import history from "../history";
import BASE_API_URL from "./env";

axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

// Retrives Logged In User Information Upon Initial APplication Load
export function fetchAllAmazonOrdersSuccess(json) {
  return {
    type: types.FETCH_ALL_AMAZON_AWAITING_ORDERS_SUCCESS,
    payload: json
  };
}

// Triggers the user loading function
export function toggleOrdersLoading() {
  return { type: types.TOGGLE_AMAZON_AWAITING_ORDERS_LOADING, payload: null };
}

// Fetch all orders awatint shipments
export function fetchAllAmazonOrders() {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleOrdersLoading());
    return axios({
      url: `${BASE_API_URL}fulfillment/amazon/get-amazon-awaiting-orders/`,
      timeout: 40000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchAllAmazonOrdersSuccess(response.data.orders));
      })
      .catch(function(error) {
        toast.error("Hmmm, there seems to be an error retrieving more amazon orders", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };
}

// Fetch all orders awatint shipments
export function fetchSingleAmazonOrder(pickSlipID, amazonOrderID) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleOrdersLoading());
    return axios({
      url: `${BASE_API_URL}fulfillment/amazon/get-single-amazon-awaiting-order?pick_slip_id=${pickSlipID}&amazon_order_id=${amazonOrderID}`,
      timeout: 40000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchAllAmazonOrdersSuccess(response.data.orders));
        // console.log(response.data)
        return response.data.orders[0].order.id
      })
      .catch(function(error) {
        toast.error("Hmmm, there seems to be an error retrieving the amazon orders", {
          position: toast.POSITION.TOP_RIGHT
        });
        setTimeout(function() {
          history.push("/scan-amz-pick-slips");
        }, 1300);
        return null
      });
  };
}


export function toggleAmazonGeneratingPickSlipsLoading() {
  return { type: types.TOGGLE_AMAZON_GENERATING_PICK_SLIPS_LOADING, payload: null };
}

// Create and configure the pick slips
export function generateAmazonPickSlips(data) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  // console.log("----")
  return function(dispatch) {
    // console.log("sdf")
    dispatch(toggleAmazonGeneratingPickSlipsLoading())
    return axios({
      url: `${BASE_API_URL}fulfillment/amazon/generate-pick-slips/`,
      timeout: 40000,
      method: "post",
      responseType: "json",
      data: data,
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        // Set the new orders into redux state
        dispatch(fetchAllAmazonOrders());
        dispatch(toggleAmazonGeneratingPickSlipsLoading())
      })
      .catch(function(error) {
        dispatch(toggleAmazonGeneratingPickSlipsLoading())
        toast.error("Sorry, there was an error creating the amazon pick slips", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };
}


// Create and print order label
export function createAndPrintAmazonOrderLabel(data) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleOrdersLoading());
    return axios({
      url: `${BASE_API_URL}fulfillment/amazon/create-and-print-amazon-shipping-label/`,
      timeout: 40000,
      method: "post",
      responseType: "json",
      data: data,
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        // Set the new orders into redux state
        dispatch(toggleOrdersLoading());
        dispatch(fetchSingleAmazonOrder(null, data.transaction_id));
        
      })
      .catch(function(error) {
        dispatch(toggleOrdersLoading());
        if (error && error.response && error.response.data) {
          toast.error(error.response.data.error, {
            position: toast.POSITION.TOP_RIGHT
          });
        } else {
            toast.error("Sorry, there was an error printing your amazon shipping label", {
              position: toast.POSITION.TOP_RIGHT
            });
        }
      });
  };
}

// Set an order as shipped
export function setAmazonOrderAsCompleted(data) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleOrdersLoading());
    return axios({
      url: `${BASE_API_URL}fulfillment/amazon/set-amazon-order-as-shipped/`,
      timeout: 40000,
      method: "post",
      responseType: "json",
      data: data,
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        toast.success("The amazon order has been set as shipped", {
          position: toast.POSITION.TOP_RIGHT
        });
        history.push("/scan-amz-pick-slips");
        dispatch(toggleOrdersLoading());
      })
      .catch(function(error) {
        toast.error(
          "Sorry, there was an error setting this amazon order as confirmed",
          {
            position: toast.POSITION.TOP_RIGHT
          }
        );
      });
  };
}