import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import history from "../history";
import { toast } from "react-toastify";
import BASE_API_URL from "./env";

axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

export function fetchAllShipmentsSuccess(json) {
  return {
    type: types.FETCH_ALL_SHIPMENTS_SUCCESS,
    payload: json
  };
}

export function toggleShipmentsLoading() {
  return { type: types.TOGGLE_SHIPMENTS_LOADING, payload: null };
}

// Fetch all orders awatint shipments
export function fetchAllShipments(search, clear) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {


    if (clear) {
      dispatch(fetchAllShipmentsSuccess(null));
      return {}
    } else {
      dispatch(toggleShipmentsLoading());
    }
    return axios({
      url: `${BASE_API_URL}fulfillment/get-all-shipments/?search=${search}`,
      timeout: 20000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchAllShipmentsSuccess(response.data.shipments));
      })
      .catch(function(error) {
        toast.error("Sorry there was an error retreiving the shipments", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };
}

// Send a shipment back to orders for pick pack
export function sendShipmentBackToPickPack(data) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleShipmentsLoading());
    return axios({
      url: `${BASE_API_URL}fulfillment/send-back-shipment-to-pick-pack/`,
      timeout: 20000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        toast.success("The shipment has been sent back to pick pack!", {
          position: toast.POSITION.TOP_RIGHT
        });
        history.push("/shipments");
        dispatch(fetchAllShipments(null, true));
      })
      .catch(function(error) {
        toast.error(
          "Sorry, there was an error sending this shipment back to pick pack",
          {
            position: toast.POSITION.TOP_RIGHT
          }
        );
      });
  };
}


export function fetchAllShipmentsCostsSuccess(json) {
  // console.log(json)
  return {
    type: types.FETCH_ALL_SHIPPING_COSTS,
    payload: json
  };
}

// Fetch all shipping costs
export function fetchAllShipmentsCosts() {

  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    return axios({
      url: `${BASE_API_URL}fulfillment/get-shipping-averages/`,
      timeout: 20000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
   
        dispatch(fetchAllShipmentsCostsSuccess(response.data.averages));
      })
      .catch(function(error) {
        toast.error("Sorry there was an error retreiving the shipping costs", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };
}