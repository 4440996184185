import React from "react";
import { Dropdown, Input } from "semantic-ui-react";
import Cookies from "js-cookie";

const filterOptions = [
    { key: 6, text: "Priority Orders", value: "priority" },
    { key: 2, text: "Awaiting Shipment", value: "Awaiting Shipment" },
    { key: 3, text: "On Hold", value: "On Hold" },
    { key: 4, text: "Delayed", value: "Delayed" },
    { key: 5, text: "Over Box Required", value: "Over Box Required" },
    { key: 6, text: "GMA Orders", value: "GMA Orders" },
];

const orderParseTypes = [
    { key: "2", text: "Refills & Glass", value: "Refills & Glass", category:  "Refills & Glass"},
    { key: "2", text: "Glass Only", value: "Glass Only", category:  "Glass Only"},
    { key: "3", text: "Refills Only", value: "Refills Only", category:  "Refills Only"}
];


class OrderFinderActionRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        
        };
        this.changeOrderParseType = this.changeOrderParseType.bind(this);
        this.setOrderFilter = this.setOrderFilter.bind(this);
    }

    setOrderFilter(event, data) {
        if (data) {
            Cookies.set("orderFilterType", data.value);
            this.props.changeFilter(data.value)
        }
    }

    changeOrderParseType(event, data) {
        this.props.setOrderParseType(data.value);
    }

    render() {
        return (
            <div>
                <div className="table-actions-row" style={{
                    marginBottom: "15px"
                }}>
                    <Input
                        value={this.props.searchTerm}
                        onChange={e => this.props.setSearchTerm(e.target.value)}
                        className="float-left"
                        action={{
                        content: "Clear",
                            onClick: () => this.props.setSearchTerm("")
                        }}
                        placeholder="Search Orders..."
                    />
                    <div style={{
                        width: "fit-content",
                        float: "left"
                    }}>
                    <p className="show-orders-description">Filter Orders By: </p>
                    <Dropdown
                        placeholder="Filter Status"
                        selection
                        value={this.props.filterValue}
                        onChange={this.setOrderFilter}
                        style={{
                            marginRight: "10px"
                        }}
                        className="float-left"
                        options={filterOptions}
                    />
                </div>
            </div>
            <div className="table-actions-row">
                <div style={{
                    width: "fit-content",
                    float: "left"
                }}>
                    <p className="show-orders-description">Filter Orders For: </p>
                        <Dropdown
                            placeholder="Show Orders For"
                            selection
                            multiple
                            value={this.props.orderParseType}
                            onChange={this.changeOrderParseType}
                            style={{
                                marginRight: "10px"
                            }}
                            className="float-right"
                            options={orderParseTypes}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default  OrderFinderActionRow;


