import React from "react";
import { Card, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import LoginForm from "../FormContainers/Authentication/LoginForm";
import Copyright from "../../components/Common/Copyright";
import { fetchLoggedInUserData } from "../../actions/actionsAuth";
import history from "../../history";

class AuthenticationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    // If there is a logged in user then redirect them to the page
    // If this when they hit the back button.
    if (this.props.loggedInUser) {
      history.push("/scan-pick-slips");
    } else {
      if (localStorage.getItem("Token")) {
        this.props.fetchLoggedInUserData(true);
      }
    }
  }

  render() {
    return (
      <div className="authentication-global-wrapper">
        {/* <img src={truckIcon} alt="Pick Up Track" className="truck-icon" /> */}
        <img src={"//images.ctfassets.net/z3ixs6i8tjtq/1LAavKzDMuzsWbQnrFxMxi/85ca01045628d9dc6489f04b6417bbc3/NewLogo.png?w=200"} alt="cleancult" className="logo" />
        <Card>
          <Card.Content>
            {this.props.loggedInUserFailureMessage && (
              <Message color="red">
                {this.props.loggedInUserFailureMessage}
              </Message>
            )}
            <LoginForm />
          </Card.Content>
          <Card.Content extra>
            <p style={{ textAlign: "center" }}>
              Having trouble with your login? Contact your floor supervisor for
              help.
            </p>
          </Card.Content>
        </Card>
        <Copyright />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedInUserFailureMessage: state.auth.logged_in_user_failure_message,
    loggedInUser: state.auth.logged_in_user
  };
}

export default connect(
  mapStateToProps,
  { fetchLoggedInUserData }
)(AuthenticationContainer);
