import React from "react";
import { connect } from "react-redux";
import { fetchAllFulfillmentUsers } from "../../actions/actionsUserManagement";
import UserDock from "./UserDock";
import { Dimmer, Loader, Button, Icon } from "semantic-ui-react";
import Table from "../../components/UserManagement/Table";
import SubMenuContainer from "../Common/SubMenuContainer";
import history from "../../history";

class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    if (!this.props.fulfillmentUsers) {
      this.props.fetchAllFulfillmentUsers();
    }
  }

  render() {
    return (
      <div>
        {!this.props.fulfillmentUsers ? (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        ) : (
          <div>
            <SubMenuContainer
              title={"User Management"}
              loading={this.props.fulfillmentUsersLoading}
              refreshClick={() => this.props.fetchAllFulfillmentUsers()}
              lastRefresh={this.props.lastRefresh}
            />
            <div className="content-body-container">
              <div
                style={{
                  height: "30px",
                  marginTop: "15px"
                }}
              >
                <Button
                  color="green"
                  loading={this.props.fulfillmentUsersLoading}
                  compact
                  onClick={() => history.push("/user-management/add-new-user")}
                  className="floatRight float-right"
                  animated="vertical"
                >
                  <Button.Content visible> Create New User</Button.Content>
                  <Button.Content hidden>
                    <Icon name="plus" />
                  </Button.Content>
                </Button>
              </div>
              <Table
                loading={this.props.fulfillmentUsersLoading}
                data={this.props.fulfillmentUsers}
              />
              <UserDock
                location={this.props.location}
                fulfillmentUsers={this.props.fulfillmentUsers}
              />
            </div>
            <br />
            <br />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fulfillmentUsers: state.userManagement.fulfillment_users,
    fulfillmentUsersLoading: state.userManagement.fulfillment_users_loading,
    lastRefresh: state.userManagement.last_refreshed
  };
}

export default connect(
  mapStateToProps,
  { fetchAllFulfillmentUsers }
)(UserManagement);
