import React from "react";
import { connect } from "react-redux";
import { Button, Form, Divider } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";

import {
  plainTextField,
  checkBoxField
} from "../../../components/Form/FieldRenders";
import { editFulfillmentUser } from "../../../actions/actionsUserManagement";

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required";
  }
  if (!values.first_name) {
    errors.first_name = "Required";
  }
  if (!values.last_name) {
    errors.last_name = "Required";
  }
  if (!values.vdl_employee_id) {
    errors.vdl_employee_id = "Required";
  }
  if (values.vdl_employee_id) {
    if (values.vdl_employee_id.length !== 4) {
      errors.vdl_employee_id = "Required";
    }
    var isnum = /^\d+$/.test(values.vdl_employee_id);
    if (!isnum) {
      errors.vdl_employee_id = "Required";
    }
  }

  if (values.confirm_password !== values.password) {
    errors.confirm_password = "Your passwords do not match";
  }

  return errors;
};

class EditUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(props) {
    this.props.editFulfillmentUser(props);
  }

  render() {
    const { handleSubmit, submitting, invalid } = this.props;
    return (
      <Form
        onSubmit={handleSubmit(this.onFormSubmit)}
        loading={this.props.fulfillmentUsersLoading}
      >
        <Divider horizontal>Personal Information</Divider>
        <Field
          name="email"
          required={true}
          label="Email"
          type="email"
          autoComplete={"email"}
          size="large"
          fluid={true}
          placeholder="Ex. joe@vg.com"
          component={plainTextField}
        />
        <Field
          name="first_name"
          required={true}
          label="First Name"
          type="text"
          autoComplete={"first_name"}
          size="large"
          fluid={true}
          placeholder="Ex. Joe"
          component={plainTextField}
        />
        <Field
          name="last_name"
          required={true}
          label="Last Name"
          type="text"
          autoComplete={"last_name"}
          size="large"
          fluid={true}
          placeholder="Ex. Smith"
          component={plainTextField}
        />
        <Field
          name="vdl_employee_id"
          required={true}
          label="Employee ID"
          type="text"
          size="large"
          fluid={true}
          placeholder="Ex. 3433"
          component={plainTextField}
        />
        <p>Employee IDs be 4 digits long and only contain numbers.</p>
        <Divider horizontal>SITE INFORMATION</Divider>
        <Field
          name="fulfillment_super_user"
          component={checkBoxField}
          label="Super User"
        />
        <p>
          Super users can access the user magement pages and access the
          dashboard
        </p>
        <Field name="is_active" component={checkBoxField} label="Is Active" />{" "}
        <p>
          This allows users to log into the site. If you turn this off they will
          no longer be able to access the platform.
        </p>
        <Divider horizontal>RESET PASSWORD</Divider>
        <p>Only fill this out if you want to reset this user's password</p>
        <Field
          name="password"
          required={false}
          label="Password"
          type="password"
          autoComplete={"password"}
          size="large"
          fluid={true}
          placeholder="Ex. Super Secret Password"
          component={plainTextField}
        />
        <Field
          name="confirm_password"
          required={false}
          label="Confirm Password"
          type="password"
          autoComplete={"confirm_password"}
          size="large"
          fluid={true}
          placeholder="Ex. Confirm Super Secret Password"
          component={plainTextField}
        />
        <br />
        <Button
          content="Save Changes"
          disabled={submitting || invalid}
          type="submit"
          size="large"
          color="blue"
          fluid
        />
      </Form>
    );
  }
}

EditUserForm = reduxForm({
  form: "EditUserForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(EditUserForm);

function mapStateToProps(state) {
  return {
    fulfillmentUsersLoading: state.userManagement.fulfillment_users_loading
  };
}

export default connect(
  mapStateToProps,
  { editFulfillmentUser }
)(EditUserForm);
