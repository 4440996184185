import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { Segment } from "semantic-ui-react";


class Table extends React.Component {
  render() {
    return (
      <Segment className="table-container" loading={this.props.loading}>
        <ReactTable
          data={this.props.data}
          defaultPageSize={20}
 
          noDataText="No Users"
          columns={[

            {
              Header: "Email",
              id: "email",
              filterable: true,
              accessor: d => d.email,
              Cell: ({ original }) => {
                return (
                  <Link to={`/user-management/edit/${original.random_user_id}`}>
                    {original.email}
                  </Link>
                );
              }
            },
            {
              Header: "Employee ID",
              id: "vdl_employee_id",
              filterable: true,
              accessor: d => d.vdl_employee_id
            },
  
            {
              Header: "Name",
              id: "name",
              filterable: true,
              accessor: d => d.first_name + " " + d.last_name
            },

            {
              Header: "Is Active",
              id: "is_active",
              filterable: true,
              accessor: d => d.is_active.toString().toUpperCase(),
              filterMethod: (filter, row) => {
                
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row.is_active === "TRUE";
                }
                return row.is_active === "FALSE";
              },
              Filter: ({ filter, onChange }) =>
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">Show All</option>
                <option value="true">TRUE</option>
                <option value="false">FALSE</option>
              </select>
            },
            {
              Header: "Super User",
              id: "fulfillment_super_user",
              filterable: true,
              accessor: d => d.fulfillment_super_user.toString().toUpperCase(),
              filterMethod: (filter, row) => {
                
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row.fulfillment_super_user === "TRUE";
                }
                return row.fulfillment_super_user === "FALSE";
              },
              Filter: ({ filter, onChange }) =>
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">Show All</option>
                <option value="true">TRUE</option>
                <option value="false">FALSE</option>
              </select>
            }
          ]}
          className=" -highlight"
        />
      </Segment>
    );
  }
}

export default Table;
