import React from "react";
import { connect } from "react-redux";
import { Button, Form } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { plainTextField } from "../../../components/Form/FieldRenders";
import { fetchUserKnoxToken } from "../../../actions/actionsAuth";

const validate = values => {
  const errors = {};
  if (!values.username) {
    errors.username = "Required";
  }
  if (!values.password) {
    errors.password = "Required";
  }
  return errors;
};

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(props) {
    this.props.fetchUserKnoxToken(props);
  }

  render() {
    const { handleSubmit, submitting, invalid } = this.props;
    return (
      <Form
        onSubmit={handleSubmit(this.onFormSubmit)}
        loading={this.props.loggedInUserLoading}
      >
        <Field
          name="username"
          required={true}
          label="Employee ID"
          icon="user circle outline"
          iconPosition="left"
          type="text"
          autoComplete={"username"}
          size="large"
          fluid={true}
          placeholder="Ex. Smith34"
          component={plainTextField}
        />
        <Field
          name="password"
          required={true}
          label="Password"
          icon="key"
          iconPosition="left"
          type="password"
          autoComplete={"current-password"}
          size="large"
          fluid={true}
          placeholder="×××××××"
          component={plainTextField}
        />
        <Button
          content="Login"
          disabled={submitting || invalid}
          type="submit"
          size="large"
          color="teal"
          fluid
        />
      </Form>
    );
  }
}

LoginForm = reduxForm({
  form: "LoginForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(LoginForm);

function mapStateToProps(state) {
  return {
    loggedInUserLoading: state.auth.logged_in_user_loading
  };
}

export default connect(
  mapStateToProps,
  { fetchUserKnoxToken }
)(LoginForm);
