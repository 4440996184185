import { combineReducers } from "redux";
import AuthReducer from "./reducerAuth";
import OrderReducer from "./reducerOrders";
import AmazonOrderReducer from "./reducerAmazonOrders";
import ShipmentsReducer from "./reducerShipments";
import PickPackErrorReducer from "./reducerPickPackErrors";
import UserManagementReducer from "./reducerUserManagement";
import ProjectionsReducer from "./reducerProjections";
import DashboardReducer from "./reducerDashboard";
import InventoryReducer from "./reducerInventory";
import ReconciliationReducer from "./reducerReconciliation";
import { reducer as formReducer } from "redux-form";

const rootReducer = combineReducers({
  auth: AuthReducer,
  projections: ProjectionsReducer,
  userManagement: UserManagementReducer,
  form: formReducer,
  reconciliation: ReconciliationReducer,
  amazonOrders: AmazonOrderReducer,
  inventory: InventoryReducer,
  pickPackErrors:PickPackErrorReducer,
  dashboard: DashboardReducer,
  orders: OrderReducer,
  shipments: ShipmentsReducer
});

export default rootReducer;
