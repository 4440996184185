import React from "react";
import { Button, Message, Modal, Icon, Loader, Dimmer } from 'semantic-ui-react'
import { connect } from "react-redux";
import { quickSwapFulfillmentUsers, resetQuickSwapUserFailureMessage } from "../../actions/actionsAuth";

// Allows users to quickly switch between accounts
class QuickSwitchUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeID: ""
    };

  }

  
  componentWillReceiveProps(nextProps) {
    if (!nextProps.quickSwitchUserModalOpen) {
      this.setState({
        employeeID: ""
      })
      this.props.resetQuickSwapUserFailureMessage()
    }

    if (nextProps.quickSwapUserFailureMessage) {
      this.setState({
        employeeID: ""
      })
    }

    if (nextProps.loggedInUser.last_login !== this.props.loggedInUser.last_login) {
      this.props.toggleQuickSwitchModal()
    }
  }

  setUserID(number) {
      let currentID = this.state.employeeID;

      if (currentID.length !== 4) {
          currentID = currentID + number
          this.setState({
            employeeID: currentID
          })
      }

   
  }

  backSpaceUserId() {
    let currentID = this.state.employeeID;
    currentID = currentID.slice(0, -1); 
    this.setState({
      employeeID: currentID
    })
  }

  submitEmployeeID() {
    let currentID = this.state.employeeID;
    if (currentID.length === 4) {
      this.props.quickSwapFulfillmentUsers({
        vdl_employee_id: currentID
      })
    }
  }

  render() {
    return (
      <Modal
        dimmer={"blurring"}
        open={this.props.quickSwitchUserModalOpen}
        onClose={() => this.props.toggleQuickSwitchModal()}
        size="tiny"
        closeOnDimmerClick={false}
      >
      <Dimmer active={this.props.quickSwapLoggedInUserLoading} inverted>
        <Loader />
      </Dimmer>
        <Modal.Header>Enter Your Employee ID</Modal.Header>
        <Modal.Content className="quick-switch-modal-content">
          {
            this.props.quickSwapUserFailureMessage && (
              <Message negative>
                  <Message.Header>
                    {this.props.quickSwapUserFailureMessage}
                  </Message.Header>
              </Message>
            )
          }
          <div >
            <input value={this.state.employeeID} className="number-input" type="text" name="quantity" min="0000" max="9999" disabled />
          </div>
     
          <Modal.Description>
            <div className="number-button-row">
              <Button    size="massive" onClick={() => this.setUserID("1")}>
                  1
              </Button>
              <Button    size="massive" onClick={() => this.setUserID("2")}>
                  2
              </Button>
              <Button    size="massive" onClick={() => this.setUserID("3")}>
                  3
              </Button>
            </div>
            <div className="number-button-row">
              <Button    size="massive" onClick={() => this.setUserID("4")}>
                  4
              </Button>
              <Button    size="massive" onClick={() => this.setUserID("5")}>
                  5
              </Button>
              <Button    size="massive" onClick={() => this.setUserID("6")}>
                  6
              </Button>
            </div>
            <div className="number-button-row">
              <Button    size="massive" onClick={() => this.setUserID("7")}>
                  7
              </Button>
              <Button    size="massive" onClick={() => this.setUserID("8")}>
                  8
              </Button>
              <Button    size="massive" onClick={() => this.setUserID("9")}>
                  9
              </Button>
            </div>
            <div className="number-button-row align-right">
              <Button    size="massive" onClick={() => this.setUserID("0")}>
                  0
              </Button>
              <Button    size="large" onClick={() => this.backSpaceUserId()} className="backspace-button">
                  <Icon name="angle left" />
                  <Icon name="delete" />
              </Button>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button
              positive
              icon='checkmark'
              labelPosition='right'
              size="large"
              onClick={() => this.submitEmployeeID()}
              content='Confirm'
              disabled={this.state.employeeID.length !== 4}
            />
          </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    quickSwapLoggedInUserLoading: state.auth.quick_swap_logged_in_user_loading,
    quickSwapUserFailureMessage: state.auth.quick_swap_logged_in_user_failure_message,
    loggedInUser: state.auth.logged_in_user
  };
}

export default connect(
  mapStateToProps,
  { quickSwapFulfillmentUsers, resetQuickSwapUserFailureMessage }
)(QuickSwitchUserModal);
