import * as types from "../actions/types";

const INITIAL_STATE = {
  shipments: null,
  shipping_cost: null,
  shipments_loading: false,
  last_refreshed: new Date().toLocaleString("en-US", {
    timeZone: "America/New_York"
  })
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TOGGLE_SHIPMENTS_LOADING:
      return {
        ...state,
        shipments_loading: !state.shipments_loading
      };
    case types.FETCH_ALL_SHIPMENTS_SUCCESS:
      return {
        ...state,
        shipments: action.payload,
        shipments_loading: false,
        last_refreshed: new Date().toLocaleString("en-US", {
          timeZone: "America/New_York"
        })
      };
    case types.FETCH_ALL_SHIPPING_COSTS:
      return {
        ...state,
        shipping_cost: action.payload,
      };

    default:
      return state;
  }
}
