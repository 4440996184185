import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import history from "../../history";
import { fetchLoggedInUserData } from "../../actions/actionsAuth";
import { Dimmer, Loader } from "semantic-ui-react";

class LoggedInUserContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  checkLoginStatus(props) {
    // Check if the user is on the login page
    if (!props.location.pathname.includes("login")) {
      // Check to see if the user has an auth token
      if (!localStorage.getItem("Token")) {
        history.push("/login");
        // If there is not logged in user
        if (!props.loggedInUser) {
          props.fetchLoggedInUserData();
        }
      } else {
        // If there is not logged in user
        if (!this.props.loggedInUser) {
          // If not send them to login
          props.fetchLoggedInUserData();
        }
      }
    }
  }
  componentWillMount() {
    this.checkLoginStatus(this.props);
  }

  render() {
    return (
      <div>
        <Dimmer
          active={
            this.props.loggedInUserLoading &&
            !this.props.location.pathname.includes("login")
          }
        >
          <Loader size="huge">Loading</Loader>
        </Dimmer>
        {this.props.loggedInUser && <div>{this.props.children}</div>}
        {this.props.location.pathname.includes("login") && (
          <div>{this.props.children}</div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedInUserLoading: state.auth.logged_in_user_loading,
    loggedInUser: state.auth.logged_in_user
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { fetchLoggedInUserData }
  )(LoggedInUserContainer)
);
