import React from "react";
import OrderFinderActionRow from "./OrderFinderActionRow";
import OrdersTable from "./OrdersTable";
import { Button } from "semantic-ui-react";

class OrderFinder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ordersRemaining: [],
            filterValue: "Awaiting Shipment",
            searchTerm: "",
            orderParseType: []
        };
        this.setSearchTerm = this.setSearchTerm.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.setOrderParseType = this.setOrderParseType.bind(this);
    }

      // Loop through and generate the orders remaining 
    generateOrdersRemaining(orders) {
        let ordersRemaining = []
        orders.map(function(orderObject) {
            if (!orderObject.order.pick_slip_id) {
                ordersRemaining.push(orderObject)
            }
            return orderObject;
        })
        this.setState({
            ordersRemaining: ordersRemaining
        })
    }

    componentDidMount() {
        this.generateOrdersRemaining(this.props.orders)
    }

    componentWillReceiveProps(nextProps) {
        this.generateOrdersRemaining(nextProps.orders)
    }

  // Changes the order search filter
    changeFilter(data) {
        // console.log(data)
        this.setState({
        filterValue: data
        });
    }

    // Sets the search terms fro the orders search bar
    setSearchTerm(term) {
        this.setState({
        searchTerm: term
        });
    }

    // Sets the search terms fro the orders search bar
    setOrderParseType(parseType) {
        this.setState({
            orderParseType: parseType
        });
    }


    // Filter & Search Orders Remaining
    filterAndSearchOrdersRemaining(orders) {
        let {
            filterValue,
            searchTerm,
            orderParseType
        } = this.state;

        let filteredOrders = Object.assign([], orders)

        if (this.props.amazon) {
            const badID = [
                "111-7872821-0060245"
            ]
            filteredOrders = filteredOrders.filter(function(order) {
                return !badID.includes(order.order.id)
            })
            return filteredOrders
        }
        // Filter for order shiupment type
        if (filterValue !== "All") {
            filteredOrders = filteredOrders.filter((dataPoint) => {
                if (this.state.filterValue === "Over Box Required") {
                    return dataPoint.order.overbox_required
                }
                if (this.state.filterValue === "GMA Orders") {
                    return dataPoint.order.gma_order
                }
                if (this.state.filterValue === "priority") {
                    return dataPoint.order.pick_pack_priority
                }
                return dataPoint.order.shipment_status === this.state.filterValue && !dataPoint.order.overbox_required;
            });
        }
        // Fitler by search term
        if (searchTerm && searchTerm.length > 0) {
            filteredOrders = filteredOrders.filter(function(dataPoint) {
                return dataPoint.order.id.includes(searchTerm);
            });
        }
    
        if (this.state.filterValue !== "Over Box Required" && this.state.filterValue !== "GMA Orders") {
            // console.log("FITLER")
            if (orderParseType.length === 0) {
                filteredOrders = []
            } else {
                // console.log(orderParseType)
                filteredOrders = filteredOrders.filter(function(dataPoint) {
                    return orderParseType.includes(dataPoint.order.product_category)
                });
            }
        }

        // Filter by order parse type
        return filteredOrders
    }

    generatePickSlips(orders) {
        let data = {
            transaction_ids: []
        }
        let ordersMax = Object.assign([], orders)

        if (this.props.amazon) {
            ordersMax = ordersMax.slice(0,25)
        } else {
            ordersMax = ordersMax.slice(0,100)
        }

        ordersMax.map(function(order) {
            data.transaction_ids.push(order.order.id)
            return order
        })
        // console.log("test")
        this.props.generatePickSlips(data)
    }

    slipCount(ordersRemaining) {
        let count = this.filterAndSearchOrdersRemaining(ordersRemaining).length
        if (count > 100) {
            return 100
        } else {
            return count
        }
    }

    render() {
        let { loading, amazon } = this.props;
        let { 
            ordersRemaining,
            filterValue,
            searchTerm,
            orderParseType
        } = this.state;

        return (
            <div className="create-pick-slip-table-container">
                {
                    !amazon && (
                        <OrderFinderActionRow
                            loading={loading}
                            searchTerm={searchTerm}
                            orderParseType={orderParseType}
                            filterValue={filterValue}
                            setSearchTerm={this.setSearchTerm}
                            changeFilter={this.changeFilter}
                            setOrderParseType={this.setOrderParseType}
                        />
                    )
                }
                <Button
                    color="green"
                    size="large"
                    disabled={this.filterAndSearchOrdersRemaining(ordersRemaining).length === 0}
                    loading={loading}
                    onClick={() => this.generatePickSlips(this.filterAndSearchOrdersRemaining(ordersRemaining))}
                >
                    Generate ({this.slipCount(ordersRemaining)}) Pick Slips
                </Button>
                <br />
                {
                    amazon && (
                        <>
                            <br />
                            <br />
                        </>
                    )
                }
                <OrdersTable
                    loading={loading}
                    amazon={amazon}
                    data={this.filterAndSearchOrdersRemaining(ordersRemaining)}
                />
            </div>
                
        );
    }
}

export default OrderFinder;
