import React from "react";
import { Button, Icon, Modal, Header } from "semantic-ui-react";
import mouseTrap from "react-mousetrap";

class KeyboardHelp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayModal: false
    };
    this.toggleModalDisplay = this.toggleModalDisplay.bind(this);
  }
  componentWillMount() {
    this.props.bindShortcut("/", () => this.toggleModalDisplay());
  }

  toggleModalDisplay() {
    this.setState({
      displayModal: !this.state.displayModal
    });
  }

  render() {
    return (
      <div className="keyboard-help-icon">
        <Modal
          closeIcon
          open={this.state.displayModal}
          onClose={() => this.toggleModalDisplay()}
          basic
          size="small"
        >
          <Header icon="keyboard outline" content="Keyboard Shortcuts" />
          <Modal.Content>
            <h3>
              <span className="code">?</span> - Opens / Closes keyboard short
              cut menu
            </h3>
            <h3>
              <span className="code">esc</span> - Closes side dock when open
            </h3>

            <h3>
              <span className="code">o</span> - Goes to orders page
            </h3>
            <h3>
              <span className="code">s</span> - Goes to shipments
            </h3>
          </Modal.Content>
        </Modal>
        <Button
          icon
          basic
          color="blue"
          onClick={() => this.toggleModalDisplay()}
        >
          <Icon name="keyboard outline" />
        </Button>
      </div>
    );
  }
}

export default mouseTrap(KeyboardHelp);
