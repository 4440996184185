import React from "react";
import Dock from "react-dock";
import { Header, Icon, Grid, Label, List, Button, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getAgeOfOrder, getAgeSeverity } from "../../helpers";

class PickSlipGroupDock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ordersRemaining: [],
            ordersRemainingOnHold: 0,
            ordersRemainingAwaitingShipment: 0,
            ordersRemainingDelayed: 0,
        };
    }
    
    // Loop through and generate the orders remaining 
    generateOrdersRemaining(orders, pickSlipID) {
        let ordersRemaining = []
        let ordersRemainingOnHold = 0
        let ordersRemainingAwaitingShipment = 0
        let ordersRemainingDelayed = 0

        orders.map((orderObject) => {
            if (orderObject.order.pick_slip_id) {
                let orderPickSlipID
                if (this.props.amazon) {
                    orderPickSlipID = orderObject.order.pick_slip_id.split("-")[orderObject.order.pick_slip_id.split("-").length -1 ]
                } else {
                    orderPickSlipID = orderObject.order.pick_slip_id.split("-")[1]

                }

                if (orderPickSlipID === pickSlipID) {
                    ordersRemaining.push(orderObject)
                    
                    // Add ot the order shipment status count
                    if (orderObject.order.shipment_status === "Awaiting Shipment") {
                        ordersRemainingAwaitingShipment += 1
                    }
                    if (orderObject.order.shipment_status === "On Hold") {
                        ordersRemainingOnHold += 1
                    }
                    if (orderObject.order.shipment_status === "Delayed") {
                        ordersRemainingDelayed += 1
                    }
                }
            }
            return orderObject;
        })


        this.setState({
            ordersRemaining: ordersRemaining,
            ordersRemainingOnHold: ordersRemainingOnHold,
            ordersRemainingAwaitingShipment: ordersRemainingAwaitingShipment,
            ordersRemainingDelayed: ordersRemainingDelayed,
        })
    }

    componentDidMount() {
        this.generateOrdersRemaining(this.props.orders, this.props.pickSlipID)
    }

    componentWillReceiveProps(nextProps) {
        this.generateOrdersRemaining(nextProps.orders, nextProps.pickSlipID)
    }

    // Loops through the current pick pack ids and deacivates them
    deactivatePickSlips(singleDeactivationObject) {

        if (singleDeactivationObject) {
            this.props.deactivatePickSlips(singleDeactivationObject, true)
        } else {
            let data = {
                transaction_ids: []
            }
            this.state.ordersRemaining.map(function(order) {
                data.transaction_ids.push(order.order.id)
                return order
            })
    
            this.props.deactivatePickSlips(data)
        }
    }

    // render the orders remaining
    renderOrdersRemaining() {
        return this.state.ordersRemaining.map((orderObject) => {
            return (
                <Table.Row key={orderObject.order.id}>
                    <Table.Cell collapsing><a rel="noopener noreferrer" href={this.props.amazon ? `/scan-amz-pick-slips/${orderObject.order.id}` : `/scan-pick-slips/${orderObject.order.id}`} target="_blank">{orderObject.order.id}</a></Table.Cell>
                    <Table.Cell>
                        <Header
                                size="tiny"
                                color={getAgeSeverity(
                                new Date(),
                                new Date(orderObject.order.time_charged),
                                orderObject.order.pick_pack_priority
                                )}
                        >
                                {getAgeOfOrder(
                                new Date(),
                                new Date(orderObject.order.time_charged),
                                orderObject.order.pick_pack_priority
                                )}
                        </Header>
                    </Table.Cell>
                    <Table.Cell><Label>{orderObject.order.shipment_status}</Label></Table.Cell>
                    {
                        !this.props.amazon && (
                            <Table.Cell><Label>{orderObject.order.pick_slip_scanned.toString()}</Label></Table.Cell>
                        )
                    }
                    <Table.Cell collapsing textAlign="right">
                        <Button
                            basic
                            loading={this.props.loading}
                            positive
                            onClick={() => this.props.amazon ? window.open(`/print-amz-pick-slips?pickSlipID=${orderObject.order.pick_slip_id}&reprint=True`, '_blank').focus() : window.open(`/print-pick-slips?pickSlipID=${orderObject.order.pick_slip_id}&reprint=True`, '_blank').focus()}
                        >
                            Reprint
                        </Button>
                    </Table.Cell>
                    {
                        !this.props.amazon && (
                            <Table.Cell collapsing textAlign="right">
                                <Button
                                    basic
                                    loading={this.props.loading}
                                    negative
                                    onClick={() => this.deactivatePickSlips({transaction_ids: [orderObject.order.id]})}
                                >
                                    Deactivate
                                </Button>
                            </Table.Cell>
                        )
                    }
    
                </Table.Row>
            )
        })
    }

    renderPickSlipDetails() {
        return (
            <div className="pick-pack-container">
                <div className="pick-pack-menu">
                    <Header size="large" className="menu-header">
                        Pick Slip Group #{this.props.pickSlipID}
                    </Header>

                    <Link to={this.props.amazon ? "/create-amz-pick-slips" : "/create-pick-slips"}>
                    <Icon
                        name="close"
                        size="big"
                        className="float-right"
                        style={{ color: "black" }}
                    />
                    </Link>
                    <br />
                </div>
                <div className="pick-pack-content pick-slip-header item-display-container">
                    <Grid>
                        <Header as="h3">Group Details</Header>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <List divided>
                                    <List.Item>
                                        <Label horizontal>Orders Awaiting Shipment</Label>
                                        {this.state.ordersRemainingAwaitingShipment}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <List divided>
                                    <List.Item>
                                        <Label horizontal>Orders On Hold</Label>
                                        {this.state.ordersRemainingOnHold}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <List divided>
                                    <List.Item>
                                        <Label horizontal>Orders Delayed</Label>
                                        {this.state.ordersRemainingDelayed}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Table celled striped>
                            <Table.Header>
                                <Table.Row>
                                <Table.HeaderCell colSpan="6">
                                    <Header as="h3">Orders Remaining</Header>
                                </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                <Table.Cell collapsing>
                                    <b>Order ID</b>
                                </Table.Cell>
                                <Table.Cell>
                                    <b>Age</b>
                                </Table.Cell>
                                <Table.Cell>
                                    <b>Shipment Status</b>
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <b>Reprint</b>
                                </Table.Cell>
                                {
                                    !this.props.amazon && (
                                        <Table.Cell collapsing>
                                            <b>Deacitvate</b>
                                        </Table.Cell>
                                    )
                                }
                                </Table.Row>
                                {this.renderOrdersRemaining()}
                            </Table.Body>
                            </Table>
                        </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
                <br />
                <br />
                <br />
                <br />
                {
                    !this.props.amazon && (
                            <div
                                style={{
                                    height: "50px",
                                    marginTop: "80px",
                                    padding: "15px",
                                    marginRight: "18px"
                                }}
                                >
                                <Button.Group style={{ float: "right" }}>
                                    <Button
                                        loading={this.props.loading}
                                        color="red"
                                        onClick={() => this.deactivatePickSlips()}
                                    >
                                        Deactivate All Remaining Print Slips
                                    </Button>
                                </Button.Group>
                            </div>
                    )
                }
                <br />
                        <br />
                        <br />
            </div>
        )
    }

    render() {
        let { active } = this.props;
        return (
            <Dock
                position="right"
                isVisible={active}
                fluid={false}
                dockStyle={{
                    background: "#fff"
                }}
                className="react-dock"
                dimStyle={{
                    background: "rgba(0, 0, 0, 0.45)"
                }}
                duration={190}
                size={900}
                children={this.renderPickSlipDetails()}
            />
        );
    }
}

export default PickSlipGroupDock;
