import React from "react";
import PrintSlip from "./PrintSlip"
import PrintSlipSummary from "./PrintSlipSummary"
import {
    getURLParameter
} from "../../helpers";

// Maps through all the slips
class SlipGroup extends React.Component { 

    // Loop through the props array of slips and return the slip objects
    renderSlips(orders) {
        // Copy the current orders array
        let newOrders = [...orders]

        // Find the current time created time
        let pickSlipID = getURLParameter("pickSlipID", this.props.location.search)
        let ifReprint = getURLParameter("reprint", this.props.location.search)

        // Exclude all orders that do not match that
        newOrders = newOrders.filter(function(order) {
            if (order.order.pick_slip_id) {
                return order.order.pick_slip_id.includes(pickSlipID) 
            } else {
                return false
            }
        })
        
        let ordersArray = []
  // Create a firist page
  if (newOrders.length > 1) {
    ordersArray.push(
    <PrintSlipSummary orders={newOrders} pickSlipID={pickSlipID} />
)
}
        // Return and map out the new prders
        newOrders.map((printSlipObject) => {
            // console.log(this.props.amazon)
            ordersArray.push(<PrintSlip key={printSlipObject.order.id} printSlipObject={printSlipObject} ifReprint={ifReprint} amazon={this.props.amazon} />)
            return null
        })

      

    

    return ordersArray;
    }

    render() {
        let { orders } = this.props;
        return (
            <div>
                {this.renderSlips(orders)}
            </div>
        );
    }
}

export default SlipGroup
