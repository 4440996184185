import React from "react";
import { InventoryItem } from "./InventoryItem";
import { toast } from "react-toastify";
import { Label } from "semantic-ui-react";
import { barSoapWorkStationZeroActivate } from "../globalSettings";
import history from "../history";
var moment = require("moment");

// Gets the age for the order in number form
function getIntAgeOfOrder(dt2, dt1) {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  var diffInMinutes = Math.abs(Math.round(diff));
  return diffInMinutes;
}

// Gets the time difference in minutes between the two dates
export function getAgeOfOrder(dt2, dt1, pickPackPriority) {
  if (pickPackPriority) {
    return "PRIORITY";
  }
  var diffInMinutes = getIntAgeOfOrder(dt2, dt1);
  var hours = diffInMinutes / 60;
  var minutes = hours % 1;
  return Math.abs(Math.round(hours)) + "H " + Math.round(minutes * 60) + "M";
}

// Render Pick Pack Error Main Label
export function renderPickPackMainError(error, justText) {
  if (error) {
    var color = "black";
    if (error === "missing_products") {
      color = "red";
    } else if (error === "products_damaged") {
      color = "orange";
    } else if (error === "shipment_never_received") {
      color = "yellow";
    } else if (error === "defective_products") {
      color = "pink";
    }
    error = error.replace(/_/g, " ");
    error = error.toUpperCase();
    if (justText) {
      return error;
    }
    if (error === "UNDEFINED") {
      return "";
    }
    return (
      <Label color={color} size="small">
        {error}
      </Label>
    );
  } else {
    return "";
  }
}

// get url parementer
export function getURLParameter(name, url) {
  var siteLocation = url;
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=([^&;]+?)(&|#|;|$)").exec(
        siteLocation
      ) || [null, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
}

// Render Pick Pack Missing Or Damaged Products
export function renderMissingOrDamagedProducts(items) {
  if (items === "undefined") {
    return "";
  }
  if (items) {
    return items.map(function(item) {
      item = item.replace(/_/g, " ");
      item = item.toUpperCase();

      return (
        <Label key={item} color="grey" size="small">
          {item}
        </Label>
      );
    });
  } else {
    return "";
  }
}

// Gets the time difference and displays the errors in status
export function getAgeSeverity(dt2, dt1, pickPackPriority) {
  if (pickPackPriority) {
    return "blue";
  }
  var diffInMinutes = getIntAgeOfOrder(dt2, dt1);
  if (diffInMinutes <= 60) {
    return "green";
  } else if (diffInMinutes > 60 && diffInMinutes <= 500) {
    return "yellow";
  } else {
    return "red";
  }
}

// Renders the color amount for items if it goes over a certain amount
export function getQuantityColor(quantity) {
  if (quantity <= 1) {
    return "blue";
  } else if (quantity === 2) {
    return "teal";
  } else {
    return "green";
  }
}

// Takes in a service code and returns a carrier logo
export function convertCarrierImage(carrierID) {
  var carrierImageURL = "";
  if (carrierID === "fedex") {
    carrierImageURL =
      "https://i.dailymail.co.uk/i/pix/2016/12/02/16/3AD3575600000578-3875228-The_FedEx_logo_was_created_in_1994_and_is_instantly_recognisable-m-2_1480696442823.jpg";
  } else if (carrierID === "ups") {
    carrierImageURL =
      "https://cdn.freebiesupply.com/logos/large/2x/ups-logo-png-transparent.png";
  } else if (carrierID === "stamps_com") {
    carrierImageURL =
      "https://uspsblog.com/wp-content/uploads/2015/11/USPS_Eagle-Symbol-web-size-300x253.png";
  }
  return carrierImageURL;
}

// Takes in a carrier name
export function convertCarrierService(serviceID) {
  if (serviceID) {
    var convertedServiceID = serviceID.replace(/_/g, " ");
    return convertedServiceID;
  } else {
    return "";
  }
}

// Extracts the id from the url path
export function extractIDFromPath(location, amazon) {
  if (location.length > 1) {
    if (amazon) {
      let amazonObjectID = location.split('/')
      return amazonObjectID[amazonObjectID.length-1]
    } else {
      var objectID = location.match(/\d+/);
      if (objectID) {
        return objectID[0];
      } else {
        return null;
      }
    }
  
    } else {
      return null;
    }
}

// Gets the order object from the current array of orders
export function getOrderFromURL(location, orders, amazon) {
  var orderID = extractIDFromPath(location, amazon);
  // // console.log("order id")
  // // console.log(orderID)
  var selectedOrder = null;
  orders.map(function(order) {
    if (order.order.id === orderID) {
      selectedOrder = order;
    }
    return order;
  });
  // // console.log(selectedOrder)
  return selectedOrder;
}

// Runs throught eh order cart items and produdces the calculted orddr weight
export function getOrderWeight(cart_items) {
  var weight = 0;
  cart_items.map(function(cartItem) {
    weight += cartItem.weight;
    return cartItem;
  });
  return weight;
}

// Checks user status and pushes back based on whether
export function checkFulfillmentUserStatus(loggedInUser) {
  if (!loggedInUser.fulfillment_super_user) {
    history.push("/scan-pick-slips");
    toast.error("You don't have access to this page.", {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

// Gets the user object from the current array of fullfilment users
export function getUserFromID(users, random_user_id) {
  var selectedUser = null;
  users.map(function(user) {
    if (user.random_user_id === random_user_id) {
      selectedUser = user;
    }
    return user;
  });
  return selectedUser;
}

// Export funciton multi package creator

export const insertCardTypeArray = [
  {
    dearSKU: "1b6197f4-f71e-4cc5-9795-1554df0f4b72",
    text: "First Order Booklet",
    image:
      "//images.ctfassets.net/z3ixs6i8tjtq/4jm8g9ce6EqxfkoU1muZgN/c5c7f81986fd4ffaa5281f56d8436858/INSERT_1.png?q=95&fl=progressive&w=400&fit=fill"
  },
  {
    dearSKU: "8da91e74-6395-4769-aba7-aa4da3cd0ff92",
    text: "First Order (Glass) Booklet",
    image:
      "//images.ctfassets.net/z3ixs6i8tjtq/16WH0OCwrxyrgwZ6jabzYL/c155ee9b6f486e1c426af1cd3ea65040/GlassStarterBooklet.png?q=95&fl=progressive&w=400&fit=fill"
  },
  {
    dearSKU: "8da91e74-6395-4769-aba7-aa4da3cd0ff9",
    text: "Recurring Order Booklet",
    image:
      "//images.ctfassets.net/z3ixs6i8tjtq/6H1xPoSb913mXWSqgK2sCa/af04cf7fb7c04aba8414928c16e3ad7d/INSERT_2.png?q=95&fl=progressive&w=400&fit=fill"
  }
];

// Used only for the inventory count page
export const boxTypeArray = [
  {
    key: "refillBox16oz",
    sales: 0,
    dearSKU: "",
    text: "Refill Box (Small)",
    value: "refillBox16oz",
    otherSku: "Refill Box (Small)",
    image:
      "//images.ctfassets.net/z3ixs6i8tjtq/2iHZiQCX48gBJ1ZpcRefdm/eae65ac04023f6ae829b5f342bc60151/32oz_Refill_Box.png?q=95&fl=progressive&w=400&fit=fill"
  },
  {
    key: "refillBox32oz",
    sales: 0,
    dearSKU: "",
    text: "Refill Box (Medium)",
    value: "refillBox32oz",
    otherSku: "Refill Box",
    image:
      "//images.ctfassets.net/z3ixs6i8tjtq/3Rt8ZWIIgqfgFbvu7Uc5pn/c36dc76c758b1af1660b63e43b82e191/16oz_Refill.png?q=95&fl=progressive&w=400&fit=fill"
  },
  {
    key: "starterKitBox",
    sales: 0,
    text: "Refill Box (Large)",
    dearSKU: "",
    value: "starterKitBox",
    otherSku: "Starter Box",
    image:
      "//images.ctfassets.net/z3ixs6i8tjtq/v1ZyiToEB3Hq02QS7hEFL/508e5a1fb1bd06de9ec32215a0a9dda2/whiteRefillBox.png?q=95&fl=progressive&w=400&fit=fill"
  },

  {
    key: "bigStarterKitBox",
    sales: 0,
    text: "Refill Box (Extra Large)",
    dearSKU: "de934035-6c70-41db-8164-ef08bd798d05",
    value: "bigStarterKitBox",
    image:
      "//images.ctfassets.net/z3ixs6i8tjtq/fSJKIGuO59s67iKMlGJY4/c054c33b960cac142360b0fc52e5952b/BOX_1.png?q=95&fl=progressive&w=400&fit=fill"
  },
  
];

export function renderAllowedBoxOverrides() {
  var newArray = boxTypeArray.slice(0);
  newArray = newArray.filter(function(boxConfig) {
    return !boxConfig.key.includes("Glass");
  });
  newArray = newArray.filter(function(boxConfig) {
    return !boxConfig.key.includes("smallLightBlueBox");
  });

  return newArray;
}


//images.ctfassets.net/z3ixs6i8tjtq/6vORhEltHV3qmRyLl4A1OK/6c03dbe1063cd15a31e467b2c17b9272/Mailer.png

export const boxTypes = {
  barSoapMailer: {
    id: "barSoapMailer",
    weight: .2,
    dearSKU: "N/A",
    fishBowlID: "BAR-MLR",
    eligable: true,
    dimensions: {
      width: 6,
      length: 10,
      height: .5
    },
    components: [
      {
        name: "Mailer",
        sku: "Padded Mailer",
        image:
          "//images.ctfassets.net/z3ixs6i8tjtq/6vORhEltHV3qmRyLl4A1OK/6c03dbe1063cd15a31e467b2c17b9272/Mailer.png?q=95&fl=progressive&w=400&fit=fill"
      }
    ]
  },
  bigStarterKitBox: {
    volume: 59765,
    id: "bigStarterKitBox",
    fishBowlID: "BOX-R-XL-CRF",
    eligable: false,
    weight: 18.08,
    dearSKU: "de934035-6c70-41db-8164-ef08bd798d05",
    dimensions: {
      width: 17.5,
      length: 13,
      height: 6
    },
    components: [
      {
        name: "Box",
        sku: "Refill Box (Extra Large)",
        image:
          "//images.ctfassets.net/z3ixs6i8tjtq/fSJKIGuO59s67iKMlGJY4/c054c33b960cac142360b0fc52e5952b/BOX_1.png?q=95&fl=progressive&w=400&fit=fill"
      }
    ]
  },
  // New Refill Boxes
  singleRefillBox: {
    slots: 1,
    id: "singleRefillBox",
    dearSKU: "N/A",
    weight: 3.2,
    eligable: true,
    fishBowlID: "BOX-R-1",
    dimensions: {
      length: 10.5,
      width: 4.25,
      height: 3
    },
    components: [
      {
        name: "Box",
        sku: "Single Refill Box",
        image:
          "//images.ctfassets.net/z3ixs6i8tjtq/2JPwtzYsXQ9OE9laZElEiJ/09d72f7d5fbeb733711c3a42de750fd4/Single_Refill.png?q=95&fl=progressive&w=400&fit=fill"
      },
    ]
  },
  doubleRefillBox: {
    slots: 2,
    id: "doubleRefillBox",
    dearSKU: "N/A",
    weight: 6.4,
    eligable: true,
    fishBowlID: "BOX-R-2",
    dimensions: {
      length: 10.5,
      width: 7.25,
      height: 3
    },
    components: [
      {
        name: "Box",
        sku: "Double Refill Box",
        image:
          "//images.ctfassets.net/z3ixs6i8tjtq/2yygcq2C6Kmd5vN8EmwRGZ/67eef93c7468c2cb165234e73be9559c/Double_Refill.png?q=95&fl=progressive&w=400&fit=fill"
      },
    ]
  },
  tripleRefillBox: {
    slots: 1,
    id: "tripleRefillBox",
    dearSKU: "N/A",
    weight: 9.6,
    eligable: true,
    fishBowlID: "BOX-R-3",
    dimensions: {
      length: 10.5,
      width: 10.25,
      height: 3
    },
    components: [
      {
        name: "Box",
        sku: "Triple Refill Box",
        image:
          "//images.ctfassets.net/z3ixs6i8tjtq/6VWEmyKWvOgtJFUseTVNLB/b37b9c1b95181b0c12cec3af1209139b/Triple_Refill.png?q=95&fl=progressive&w=400&fit=fill"
      },
    ]
  },

  // New Boxes (9/1)
  refillBox16oz: {
    volume: 5148,
    id: "refillBox16oz",
    eligable: false,
    fishBowlID: "BOX-R-SM",
    weight: 13.6,
    dimensions: {
      length: 11.125,
      width: 8,
      height: 5.8
    },
    dearSKU: "",
    components: [
      {
        name: "Box",
        sku: "Refill Box (Small)",
        image:
          "//images.ctfassets.net/z3ixs6i8tjtq/2iHZiQCX48gBJ1ZpcRefdm/eae65ac04023f6ae829b5f342bc60151/32oz_Refill_Box.png?q=95&fl=progressive&w=400&fit=fill"
      },
    ]
  },
  refillBox32oz: {
    volume: 7148,
    id: "refillBox32oz",
    fishBowlID: "BOX-R-MD",
    weight: 14.88,
    eligable: false,
    dimensions: {
      length: 10.75,
      width: 8,
      height: 5.25
    },
    dearSKU: "",
    components: [
      {
        name: "Box",
        sku: "Refill Box (Medium)",
        image:
          "//images.ctfassets.net/z3ixs6i8tjtq/3Rt8ZWIIgqfgFbvu7Uc5pn/c36dc76c758b1af1660b63e43b82e191/16oz_Refill.png?q=95&fl=progressive&w=400&fit=fill"
      },
    ]
  },
  starterKitBox: {
    volume: 9765,
    id: "starterKitBox",
    dearSKU: "0b95eb2c-38b1-44d1-837f-06adfd468f5a",
    weight: 28.8,
    eligable: true,
    fishBowlID: "BOX-R-LG",
    dimensions: {
      length: 14,
      width: 13.8,
      height: 6.3
    },
    components: [
      {
        name: "Box",
        sku: "Refill Box (Large)",
        image:
          "//images.ctfassets.net/z3ixs6i8tjtq/v1ZyiToEB3Hq02QS7hEFL/508e5a1fb1bd06de9ec32215a0a9dda2/whiteRefillBox.png?q=95&fl=progressive&w=400&fit=fill"
      }
    ]
  },
  starterFor1GlassProduct: {
    slots: 1,
    volume: null,
    id: "starterFor1GlassProduct",
    fishBowlID: "BOX-G-1",
    weight: 12,
    eligable: true,
    dimensions: {
      length: 4.1,
      width: 4.1,
      height: 10.85
    },
    dearSKU: "d6963479-0e2c-4f16-a8ed-76db6e040ba0",
    components: [
      {
        name: "Box",
        sku: "Starter Box for 1 Glass Product Outside",
        image:
          "//images.ctfassets.net/z3ixs6i8tjtq/2fRuqZa6grJW3Ei6HNjIDC/4b3ed8f9036685fe8a914cc84f56956d/Screen_Shot_2020-09-21_at_6.47.10_PM.png?q=95&fl=progressive&w=300&h=300&fit=pad"
      }
    ]
  },
  verticalBoxFor16Items: {
    slots: 16,
    volume: null,
    id: "verticalGlassBoxFor16Items",
    fishBowlID: "BOX-G-16",
    weight: 50.72,
    eligable: true,
    dimensions: {
      length: 24,
      width: 18,
      height: 13
    },
    dearSKU: "",
    components: [
      {
        name: "Box",
        sku: "Vertical Box For 16 Items",
        image:
          "https://images.ctfassets.net/z3ixs6i8tjtq/6cY7WQbL0AdiEWpegweBYJ/b154d1ca4dba164af3f17f2b4f45e275/12.jpg?q=95&fl=progressive&w=961&fit=fill",
      }
    ]
  },
  verticalBoxFor12Items: {
    slots: 12,
    volume: null,
    id: "verticalGlassBoxFor12Items",
    fishBowlID: "BOX-G-12",
    weight: 46.72,
    eligable: true,
    dimensions: {
      length: 22,
      width: 17,
      height: 13
    },
    dearSKU: "",
    components: [
      {
        name: "Box",
        sku: "Vertical Box For 12 Items",
        image:
          "https://images.ctfassets.net/z3ixs6i8tjtq/6cY7WQbL0AdiEWpegweBYJ/b154d1ca4dba164af3f17f2b4f45e275/12.jpg?q=95&fl=progressive&w=961&fit=fill",
      }
    ]
  },
  verticalBoxFor9Items: {
    slots: 9,
    volume: null,
    id: "verticalGlassBoxFor9Items",
    fishBowlID: "BOX-G-9",
    weight: 39.04,
    eligable: true,
    dimensions: {
      length: 17,
      width: 15.3,
      height: 11
    },
    dearSKU: "",
    components: [
      {
        name: "Box",
        sku: "Vertical Box For 9 Items",
        image:
          "https://images.ctfassets.net/z3ixs6i8tjtq/1fXFYYjmOn2IuP1AUhflAH/e996b78535071e19afefe259c4ef4eb9/9.jpg?q=95&fl=progressive&w=961&fit=fill",
      }
    ]
  },
  verticalBoxFor6Items: {
    slots: 6,
    volume: null,
    id: "verticalGlassBoxFor6Items",
    fishBowlID: "BOX-G-6",
    weight: 27.52,
    eligable: true,
    dimensions: {
      length: 16.9,
      width: 10.5,
      height: 11.3
    },
    dearSKU: "",
    components: [
      {
        name: "Box",
        sku: "Vertical Box For 6 Items",
        image:
          "https://images.ctfassets.net/z3ixs6i8tjtq/j0mxWJOn6duKe6XGly9u8/073e6845a1807114ec420b0a14e372dc/6.jpg?q=95&fl=progressive&w=961&fit=fill",
      }
    ]
  },
  verticalBoxFor4Items: {
    slots: 4,
    volume: null,
    id: "verticalGlassBoxFor4Items",
    fishBowlID: "BOX-G-4",
    weight: 21.28,
    eligable: true,
    dimensions: {
      length: 12.1,
      width: 10.75,
      height: 11.25
    },
    dearSKU: "",
    components: [
      {
        name: "Box",
        sku: "Vertical Box For 4 Items",
        image:
          "https://images.ctfassets.net/z3ixs6i8tjtq/nLYAuz6QvIHP32NsIxTVm/99f9cead6384fab395feec989403f284/4.jpg?q=95&fl=progressive&w=961&fit=fill",
      }
    ]
  },


  overkitBoxForVerticalBoxFor9Items: {
    slots: 1,
    volume: null,
    overBoxFor: "verticalBoxFor9Items",
    id: "overkitBoxForVerticalBoxFor9Items",
    fishBowlID: "NA",
    weight: 6,
    dimensions: {
      length: 15.125,
      width: 11.625,
      height: 16.625
    },
    dearSKU: "",
    components: [
      {
        name: "Over Box",
        sku: "Over Kit Box For 9 Glass Product Box Outside",
        image:
          "//images.ctfassets.net/z3ixs6i8tjtq/S0wYXBhqM4cWX7CVwko64/5b297bfd27b4710671f4be81ebbfccc7/overbox.png?q=95&fl=progressive&w=300&fit=fill"
      }
    ]
  },


  overkitBoxForVerticalBoxFor6Items: {
    slots: 1,
    volume: null,
    overBoxFor: "verticalBoxFor6Items",
    id: "overkitBoxForVerticalBoxFor6Items",
    fishBowlID: "NA",
    weight: 6,
    dimensions: {
      length: 10.375,
      width: 11.625,
      height: 16.625
    },
    dearSKU: "",
    components: [
      {
        name: "Over Box",
        sku: "Over Kit Box For 6 Glass Product Box Outside",
        image:
        "//images.ctfassets.net/z3ixs6i8tjtq/S0wYXBhqM4cWX7CVwko64/5b297bfd27b4710671f4be81ebbfccc7/overbox.png?q=95&fl=progressive&w=300&fit=fill"
      }
    ]
  },

};

function configureGlassBoxLayout(boxConfig) {
  // // console.log("----- GLASS BOX CONFIG FUNCTION ----");
  var usableProductSkus = {
    glass: [],
    largeOther: [],
    smallOther: [],
    refills: [],
  };
  // var dryerBallsDownUnder = false;
  // let dryerBallsSqueeze = false;
  // let funnelSqueeze = false;

  // Break down the products into usable chunks
  boxConfig.items.map(function(item) {
    var live_count = 1;
    var potentialItemArray = [];
    while (item.quantity >= live_count) {
      var productObject = {
        name: item.marketing_name,
        sku: item.sku,
        image: item.box_insert_image
      };
      potentialItemArray.push(productObject);
      live_count += 1;
    }
    if (!item.id) {
      // // console.log(item)
    }
    if (item.id) {

        if (item.id.includes("glass")) {
          usableProductSkus.glass = usableProductSkus.glass.concat(
            potentialItemArray
          );
        }
        if (item.id.includes("balls")) {
          usableProductSkus.largeOther = usableProductSkus.largeOther.concat(
            potentialItemArray
          );
        }
        if (item.id.includes("funnel") || item.id.includes("bar")) {
          usableProductSkus.smallOther = usableProductSkus.smallOther.concat(
            potentialItemArray
          );
        }
        if (item.id.includes("refill")) {
          usableProductSkus.refills = usableProductSkus.refills.concat(
            {
              name: item.marketing_name,
              sku: item.sku,
              image: item.image
            }
          );
        }
    }
    return item
  });

  // // console.log("USABLE PRODUCT SKUS");
  // // console.log(usableProductSkus);

  // Determine which products should go in which slots
  // if (boxConfig.glassCount >= 8) {
  //   dryerBallsSqueeze = true;
  //   funnelSqueeze = true;
  // }
  // if (boxConfig.glassCount >= 7) {
  //   dryerBallsSqueeze = true;
  // }

  boxConfig.glassBoxLayout.Reference.products = usableProductSkus

  return boxConfig.glassBoxLayout;
}

// function canProductsFitInBox(boxType, boxQuantity, cartItems) {
//   // console.clear();
//   // Flags

//   // // console.log("sdfsdfs")
//   // Determine flags
//   cartItems.map(function(cartItem) {
//     // // console.log(cartItem)
//     return cartItem;
//   });

//   // Checks to see if the selected amount of products can fit inside of the amount of boxes specificed
//   var totalBoxVolume = boxType.volume * boxQuantity;
//   var totalCartItemVolume = 0;
//   cartItems.map(function(cartItem) {
//     totalCartItemVolume += cartItem.volume * cartItem.quantity;
//     return cartItem;
//   });

//   return totalBoxVolume >= totalCartItemVolume;
// }

// Figures what kinds of boxes we need and how many if it is more than one
function determineBoxConfig(order) {
  var finalBoxConfig = [];

  // Refills box determination only
  var refillCount = 0;

  // Glass Product Detergemein Only
  var glassCount = 0;
  var dryerBallCount = 0;
  let refill32OzCount = 0

  order.cart_items.map(function(cartItem) {
    if (cartItem.id) {
      if (cartItem.id.includes("glass")) {
        glassCount += cartItem.quantity;
      }
      if (cartItem.id.includes("dryer")) {
        dryerBallCount += cartItem.quantity;
      }
      if (cartItem.id.includes("refill") || cartItem.id.includes("box")) {
        refillCount += cartItem.quantity;
      } 
      if (cartItem.id.includes("32")) {
        refill32OzCount += cartItem.quantity;
        // // console.log(cartItem.quantity)
      } 
    }
    return cartItem;
  });
  var box;

  // Determine the total number of products in the box
  var totalItems = 0;
  order.cart_items.map(function(cartItem) {
    totalItems += cartItem.quantity;
    return cartItem;
  });


  // SINGLE BAR SOAPO ORDERS ONLY
  if (order.order.should_use_mailer && barSoapWorkStationZeroActivate) {
    box = {
      box: boxTypes.barSoapMailer,
      quantity: 1,
      items: order.cart_items
    };
    finalBoxConfig.push(box);

    // GLASS ORDERS ONLY
  } else if (order.order.has_glass) {
    let totalRelevantItemCount = refillCount + glassCount
    let totalItemCount = totalItems

    if (totalRelevantItemCount === 1 && dryerBallCount <= 1 && totalItemCount < 2) {
      box = {
        box: boxTypes.starterFor1GlassProduct,
        quantity: 1,
        items: order.cart_items
      };
      finalBoxConfig.push(box);
    } else if (totalRelevantItemCount <= 4 && dryerBallCount <= 3) {
      box = {
        box: boxTypes.verticalBoxFor4Items,
        quantity: 1,
        items: order.cart_items
      };
      finalBoxConfig.push(box);
    } else if (totalRelevantItemCount <= 6 &&  dryerBallCount <= 4) {
      box = {
        box: boxTypes.verticalBoxFor6Items,
        quantity: 1,
        items: order.cart_items
      }
      if (order.order.overbox_required) {
        box.overBox = boxTypes.overkitBoxForVerticalBoxFor6Items;
      }
      finalBoxConfig.push(box);

    } else if (totalRelevantItemCount <= 9 &&  dryerBallCount <= 6) {
      box = {
        box: boxTypes.verticalBoxFor9Items,
        quantity: 1,
        items: order.cart_items
      };
      if (order.order.overbox_required) {
        box.overBox = boxTypes.overkitBoxForVerticalBoxFor9Items;
      }
      finalBoxConfig.push(box);
    } else if (totalRelevantItemCount <= 12 &&  dryerBallCount <= 8) {
      box = {
        box: boxTypes.verticalBoxFor12Items,
        quantity: 1,
        items: order.cart_items
      };
      finalBoxConfig.push(box);
    } else {
      box = {
        box: boxTypes.verticalBoxFor16Items,
        quantity: 1,
        items: order.cart_items
      };
      finalBoxConfig.push(box);
    }

  // REFILL ORDERS
  } else  {
    let totalRelevantItemCount = refillCount + glassCount
    // Has 32oz Cartons
    if (refill32OzCount > 0) {
      console.log(refill32OzCount, totalItems)
      // New Refill box Config
      if (refill32OzCount === 1 && totalItems === 1) {
        console.log("yes")
        box = {
          box: boxTypes.singleRefillBox,
          quantity: 1,
          items: order.cart_items
        };
        finalBoxConfig.push(box);
      } 
      else if (refill32OzCount <= 1 && totalItems === 2) {
        box = {
          box: boxTypes.doubleRefillBox,
          quantity: 1,
          items: order.cart_items
        };
        finalBoxConfig.push(box);
      }  
      else if (refill32OzCount <= 2 && totalItems === 3) {
        box = {
          box: boxTypes.tripleRefillBox,
          quantity: 1,
          items: order.cart_items
        };
        finalBoxConfig.push(box);
      }  
      else if (refill32OzCount === 4 && totalItems <= 6) {
        box = {
          box: boxTypes.verticalBoxFor4Items,
          quantity: 1,
          items: order.cart_items
        };
        finalBoxConfig.push(box);
      }  else if (refill32OzCount >= 5 && totalItems <= 7 && refill32OzCount < 7) {
        box = {
          box: boxTypes.verticalBoxFor6Items,
          quantity: 1,
          items: order.cart_items
        };
        finalBoxConfig.push(box);
      } else if (refill32OzCount >= 7 && totalItems <= 12 && refill32OzCount < 10) {
        box = {
          box: boxTypes.verticalBoxFor9Items,
          quantity: 1,
          items: order.cart_items
        };
        finalBoxConfig.push(box);
      }  else if (refill32OzCount >=10 && totalItems <= 16) {
        box = {
          box: boxTypes.verticalBoxFor12Items,
          quantity: 1,
          items: order.cart_items
        };
        finalBoxConfig.push(box);
      } else {
        console.log("yes")
        // If there is 32oz and other item mixing
        if (totalRelevantItemCount <= 4 && dryerBallCount <= 3) {
          box = {
            box: boxTypes.verticalBoxFor4Items,
            quantity: 1,
            items: order.cart_items
          };
          finalBoxConfig.push(box);
        } else if (totalRelevantItemCount <= 6 &&  dryerBallCount <= 4) {
          box = {
            box: boxTypes.verticalBoxFor6Items,
            quantity: 1,
            items: order.cart_items
          }
          if (order.order.overbox_required) {
            box.overBox = boxTypes.overkitBoxForVerticalBoxFor6Items;
          }
          finalBoxConfig.push(box);
    
        } else if (totalRelevantItemCount <= 9 &&  dryerBallCount <= 6) {
          box = {
            box: boxTypes.verticalBoxFor9Items,
            quantity: 1,
            items: order.cart_items
          };
          if (order.order.overbox_required) {
            box.overBox = boxTypes.overkitBoxForVerticalBoxFor9Items;
          }
          finalBoxConfig.push(box);
        } else if (totalRelevantItemCount <= 12 &&  dryerBallCount <= 8) {
          box = {
            box: boxTypes.verticalBoxFor12Items,
            quantity: 1,
            items: order.cart_items
          };
          finalBoxConfig.push(box);
        } else {
          box = {
            box: boxTypes.verticalBoxFor16Items,
            quantity: 1,
            items: order.cart_items
          };
          finalBoxConfig.push(box);
        }
      }
    } else {
      console.log("sdf")
      if (totalItems === 1) {
        // Only has 16oz Cartons
        box = {
          box: boxTypes.singleRefillBox,
          quantity: 1,
          items: order.cart_items
        };
        finalBoxConfig.push(box);
      }
      else if (totalItems === 2) {
        // Only has 16oz Cartons
        box = {
          box: boxTypes.doubleRefillBox,
          quantity: 1,
          items: order.cart_items
        };
        finalBoxConfig.push(box);
      } 
      else if (totalItems === 3) {
        // Only has 16oz Cartons
        box = {
          box: boxTypes.tripleRefillBox,
          quantity: 1,
          items: order.cart_items
        };
        finalBoxConfig.push(box);
      } 
      else if (totalItems === 4) {
        // Only has 16oz Cartons
        box = {
          box: boxTypes.tripleRefillBox,
          quantity: 1,
          items: order.cart_items
        };
        finalBoxConfig.push(box);
      } 
      else if (totalItems <=12 ) {
          // Only has 16oz Cartons
          box = {
            box: boxTypes.starterKitBox,
            quantity: 1,
            items: order.cart_items
          };
          finalBoxConfig.push(box);
        }
      else if (totalItems <=16 ) {
          // Only has 16oz Cartons
          box = {
            box: boxTypes.verticalBoxFor4Items,
            quantity: 1,
            items: order.cart_items
          };
          finalBoxConfig.push(box);
        } else {
          // Only has 16oz Cartons
          box = {
            box: boxTypes.verticalBoxFor9Items,
            quantity: 1,
            items: order.cart_items
          };
          finalBoxConfig.push(box);
        }
      }  
  }
  

  if (finalBoxConfig.length > 0) {
    finalBoxConfig[0].glassCount = glassCount;
    finalBoxConfig[0].refillCount = refillCount;
    finalBoxConfig[0].dryerBallCount = dryerBallCount;
    finalBoxConfig[0].totalItems = totalItems;
  }

  return finalBoxConfig;
}

// Get the final box weight and dimenstions based on
export function getFinalBoxAssortment(order) {

  // Determibne the final box configuration based on the items in the box
  var boxConfigs = determineBoxConfig(order);

  // Loop through the total boxes
  boxConfigs.map(function(box) {
    // Create a total weight attribute for the box weight
    box.totalWeight = box.box.weight * box.quantity;


    // Add in the product weights into the total box weight
    box.items.map(function(item) {
      box.totalWeight += Math.round(item.weight * item.quantity);
      return item;
    });

    // Pass dimensions also into box
    if (box.box.dimensions) {
      box.finalDimensions = box.box.dimensions;
    }

    // If there is an overbox, then submit that addiontal weight along with the dimensions as well
    if (box.overBox) {
      box.totalWeight += box.overBox.weight;
      box.finalDimensions = box.overBox.dimensions;
    }

    // If the box has glass that needs to be assorited

    if (box.glassBoxLayout) {
      box.glassBoxLayout = configureGlassBoxLayout(box);
    }

    return box;
  });

  return boxConfigs;
}

// PROJECTION HELPERS
// ------------------------------------------------
// Takes in sales data converts it to more of a chart friendly
export function convertSalesProjections(salesProjections) {
  var salesProjectionsInWeeks = [["Dates"]];

  // Convert sales projection into weeks
  var firstDate = moment(salesProjections[0][1], "MM/DD/YYYY");
  var lastDate = moment(
    salesProjections[0][salesProjections[0].length - 1],
    "MM/DD/YYYY"
  );
  // Get the differenace in weeks between the starting date of teh projections ad the last
  var weeksDifferance = lastDate.diff(firstDate, "weeks");

  // Add in all the weeks inbetween teh starting date and the last
  [...Array(weeksDifferance).keys()].map(function(number) {
    salesProjectionsInWeeks[0].push(moment(firstDate.toDate()));
    firstDate = firstDate.clone().add(1, "week");
    return number;
  });

  // Takes the sales data and convert to weeks as well
  salesProjections.map(function(salesArray) {
    // Skip the first array as it contains the dates
    if (salesArray[0] !== "Dates") {
      var newSalesDataInWeeks = [];
      // Loop through the data points
      salesArray.map(function(salesDataPoint) {
        // If the data point can be converted into an int then divide by 4
        if (parseInt(salesDataPoint) || salesDataPoint === "0") {
          salesDataPoint = parseInt(salesDataPoint);
          salesDataPoint = salesDataPoint / 4;
          [...Array(4).keys()].map(function(number) {
            newSalesDataInWeeks.push(Math.round(salesDataPoint));
            return number;
          });
        } else {
          newSalesDataInWeeks.push(salesDataPoint);
        }
        return salesDataPoint;
      });
      salesProjectionsInWeeks.push(newSalesDataInWeeks);
    }
    return newSalesDataInWeeks;
  });

  // loop through sales data AGAIN and add in teh last little bit of informatino
  salesProjectionsInWeeks.map(function(salesArray) {
    // Skip the first array as it contains the dates
    if (salesArray[0] !== "Dates") {
      var lastSalesPoint = salesArray[salesArray.length - 1];
      // Add in a repeat last three data points
      salesArray.push(lastSalesPoint);
      salesArray.push(lastSalesPoint);
      salesArray.push(lastSalesPoint);
    }
    return salesArray;
  });

  // LOOP one last time and add three weeks to everything
  salesProjectionsInWeeks[0].map(function(dataPoint) {
    if (dataPoint !== "Dates") {
      dataPoint.add(3, "weeks");
    }
    return dataPoint;
  });
  return salesProjectionsInWeeks;
}

// Takes in sales data and make it relevant to only this week forward
export function makeSalesProjectionsRelevant(convertedSalesProjecions) {
  var dateRange = convertedSalesProjecions[0];
  var today = moment();
  var sliceStart = 1;
  var sliceEnd = 0;
  dateRange.map(function(dataPoint) {
    if (dataPoint !== "Dates") {
      if (dataPoint <= today) {
        sliceEnd += 1;
      }
    }
    return dataPoint;
  });
  convertedSalesProjecions.map(function(salesRow) {
    salesRow.splice(sliceStart, sliceEnd - 1);
    return salesRow;
  });
  return convertedSalesProjecions;
}

// Takes in the product ID and converts it to a more human readable name
export function convertProductID(productID) {
  var nameDict = {
    laundry_detergent: "Laundry Deterget (Starter Kit)",
    dishwasher_detergent: "Dishwasher Detergent (Starter Kit)",
    dryer_balls: "Wool Dryer Balls (Starter Kit)",
    all_purpose_cleaner: "All Purpose Cleaner (Starter Kit)",
    bar_soap: "Hand Bar Soap (Starter Kit)",
    dish_soap: "Liquid Dish Soap (Starter Kit)",
    foaming_hand_soap: "Foaming Hand Soap (Starter Kit)",
    laundry_detergent_glass: "Laundry Detergent (Glass)",
    dishwasher_detergent_glass: "Dishwasher Detergent (Glass)",
    all_purpose_cleaner_glass: "All Purpose Cleaner (Glass)",
    dish_soap_glass: "Liquid Dish Soap (Glass)",
    foaming_hand_soap_glass: "Foaming Hand Soap (Glass)",
    laundry_detergent_refill: "Laundry Detergent (Refill)",
    dishwasher_detergent_refill: "Dishwasher Detergent (Refill)",
    all_purpose_cleaner_refill: "All Purpose Cleaner (Refill)",
    dish_soap_refill: "Liquid Dish Refill (Refill)",
    foaming_hand_soap_refill: "Foaming Hand Soap (Refill)"
  };
  return nameDict[productID];
}

function matchComponentIDToComponentObject(laborID, labor) {
  // Takes in a labor id and return back the labor completed object
  var laborObject = {};
  // Map through the compnents and matchit do the id
  labor.map(function(laborEntry) {
    // If id amatches assign the the componetn
    if (laborEntry.id === laborID) {
      laborObject = laborEntry;
    }
    return laborEntry;
  });
  return laborObject;
}

function matchLaborIDToLaborObject(componentID, components) {
  // Takes in a compoent id and return back the component completed object
  var componentObject = {};
  // Map through the compnents and matchit do the id
  components.map(function(component) {
    // If id amatches assign the the componetn
    if (component.id === componentID) {
      componentObject = component;
    }
    return component;
  });
  return componentObject;
}

function getUnitConverstion(productID, componentID, allUnitConverstions) {
  var properUnitConverstion = {};
  // Get the proper converstion
  allUnitConverstions.map(function(unitConverstion) {
    var componentsReferenced = unitConverstion.fields.Components;
    var productReferenced = unitConverstion.fields.Product;
    if (
      componentsReferenced.includes(componentID) &&
      productReferenced.includes(productID)
    ) {
      properUnitConverstion = unitConverstion;
    }
    return unitConverstion.fields["1 Component Unit <> 1 Product"];
  });
  return properUnitConverstion.fields["1 Component Unit <> 1 Product"];
}

export function convertInventoryComponents(inventoryComponents) {
  var newInventoryComponents;
  // Takes in all of the product componet
  // 1. Matches products to componetns
  // 2. Matches labor to componets
  // 3. Matches Product TO COmponent
  // 4. Creates 12 Product Inventory Classes
  let products = inventoryComponents.products;
  let components = inventoryComponents.components;
  let unitConverstions = inventoryComponents.product_component;
  let labor = inventoryComponents.labor;

  // Map the labor id to the actual labro obejct
  products.map(function(product) {
    product.fields.laborObjects = [];
    if (product.fields.Labor) {
      // Map through the product components to add in the finished component
      product.fields.Labor.map(function(laborID) {
        // Match the component ID to actual component and save it
        product.fields.laborObjects.push(
          matchLaborIDToLaborObject(laborID, labor)
        );
        return laborID;
      });
    }

    product.fields.componentObjects = [];
    // Map through the product components to add in the finished component
    product.fields.Components.map(function(componentID) {
      // Match the component ID to actual component and save it
      product.fields.componentObjects.push(
        matchComponentIDToComponentObject(componentID, components)
      );
      return componentID;
    });

    product.fields.componentObjects.map(function(componentObject) {
      var componentID = componentObject.id;
      componentObject.fields.unitConverstion = getUnitConverstion(
        product.id,
        componentID,
        unitConverstions
      );
      return componentObject;
    });
    return product;
  });
  // Map the componet to unit id to the product
  newInventoryComponents = products;
  newInventoryComponents.map(function(product) {
    return product;
  });
  return cleanUpInventoryComponents(newInventoryComponents);
}

// Takes in all of the Product arrays and converts each of them into classes with resulable
// Methods
export function cleanUpInventoryComponents(finishedInventoryComponents) {
  var finishedInventoryClasses = [];
  // Delete the object keys
  var cleanedInventoryObject = [...finishedInventoryComponents];
  cleanedInventoryObject.map(function(product) {
    finishedInventoryClasses.push(
      new InventoryItem(
        {
          amazon: product.fields["Amazon Finished Stock"],
          newgistics: product.fields["Newgistics Finished Stock"],
          vanguard: product.fields["Vanguard Finished Stock"]
        },
        product.fields.componentObjects,
        product.fields.laborObjects,
        product.fields["cleancult DB id"],
        product.fields["Finished Good Name"],
        product.fields["Type"]
      )
    );
    return product;
  });
  return finishedInventoryClasses;
}

export function getStartingInventory(inventoryComponents) {
  // Returns a dict of the starting inventory numbers of each product
  return inventoryComponents;
}

// get date severity
export function getDateSeverity(date) {
  var difference = moment.duration(date.diff(moment()));

  difference = difference.asDays();
  if (difference < 0) {
    return "red";
  } else if (difference >= 0 && difference < 70) {
    return "yellow";
  }

  return "green";
}

