import React from "react";
import { Input } from "semantic-ui-react";

class TableActions extends React.Component {
  // Check if the user has selected rows in the table
  // Will engage the dropdowns to no longer be disabled
  checkIfRowsSelected() {
    var count = 0;
    for (var i in this.props.selected) {
      if (this.props.selected.hasOwnProperty(i)) count++;
    }

    // If selected return false for disabled
    if (count > 0) {
      return false;
    } else {
      return true;
    }
  }
  render() {
    return (
      <div className="table-actions-row" style={{display: "fex", margin: "auto", textAlign: "center"}}>
        <Input
          value={this.props.searchTerm}
          onChange={e => this.props.setSearchTerm(e.target.value)}
          className="float-right"
          size="massive"
          action={{
            content: "Clear",
            onClick: () => this.props.setSearchTerm("")
          }}
          placeholder="Search Shipments..."
        />
      </div>
    );
  }
}

export default TableActions;
