import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import Routes from "./routes";
import { toast } from "react-toastify";

import "./assets/css/normalize.css";
import "./assets/css/styles.css";
import "react-table/react-table.css";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

class IndexHot extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    );
  }
}
export default IndexHot;
