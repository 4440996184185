import React from "react";
import Dock from "react-dock";
import DockMenu from "../../components/Shipments/DockMenu";
import {
  Grid,
  Header,
  Segment,
  Label,
  List,
  Modal,
  Image,
  Icon,
  Table,
  Button
} from "semantic-ui-react";
import { connect } from "react-redux";
import { sendShipmentBackToPickPack } from "../../actions/actionsShipments";
import {
  convertCarrierImage,
  convertCarrierService,
  getQuantityColor,
  renderPickPackMainError,
  renderMissingOrDamagedProducts,
  getURLParameter
} from "../../helpers";

var moment = require("moment");

var dateFormat = require("dateformat");

class ShipmentDock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnWidth: 16,
      displayModal: false,
      imageSource: null
    };
    this.sendShipmentPackToPickPack = this.sendShipmentPackToPickPack.bind(
      this
    );
  }

  // Checks if there is an active order and if the doc should be rendered
  checkVisibleStatus() {
    if (this.props.activeShipment) {
      return true;
    } else {
      return false;
    }
  }
  toggleModalDisplay(imageSource) {
    this.setState({
      displayModal: !this.state.displayModal,
      imageSource: imageSource
    });
  }
  renderItems(cartItems) {
    var that = this;
    return cartItems.map(function(cartItem) {
      return (
        <Segment key={cartItem.marketing_name}>
          <img
            alt={cartItem.marketing_name}
            onClick={() => that.toggleModalDisplay(cartItem.image)}
            src={cartItem.image}
            className="cart-item-image"
          />
          {cartItem.marketing_name} <br />
          <Header as="h3">{cartItem.sku} </Header>
          <Label
            size="big"
            className="quantity-label"
            color={getQuantityColor(cartItem.quantity)}
          >
            × {cartItem.quantity}
          </Label>
        </Segment>
      );
    });
  }
  renderInsertComponents(insertType) {
    return (
      <Segment>
        <img
          alt={insertType.name}
          onClick={() => this.toggleModalDisplay(insertType.image)}
          src={insertType.image}
          className="cart-item-image"
        />
        {insertType.name}
        <br />
        <Header as="h3">{insertType.sku}</Header>
        <Label size="big" className="quantity-label">
          × 1
        </Label>
      </Segment>
    );
  }
  renderBoxComponents(boxComponents) {
    var that = this;
    return boxComponents.map(function(boxComponent) {
      return (
        <Segment key={boxComponent.sku}>
          <img
            alt={boxComponent.name}
            src={boxComponent.image}
            onClick={() => that.toggleModalDisplay(boxComponent.image)}
            className="cart-item-image"
          />
          {boxComponent.name}
          <br />
          <Header as="h3">{boxComponent.sku}</Header>
          <Label size="big" className="quantity-label">
            × 1
          </Label>
        </Segment>
      );
    });
  }
  renderOverBoxComponents(overBox) {
    var that = this;
    if (overBox) {
      return overBox.components.map(function(boxComponent) {
        return (
          <Segment key={boxComponent.sku}>
            <img
              alt={boxComponent.name}
              src={boxComponent.image}
              onClick={() => that.toggleModalDisplay(boxComponent.image)}
              className="cart-item-image"
            />
            {boxComponent.name}
            <br />
            <Header as="h3">{boxComponent.sku}</Header>
            <Label size="big" className="quantity-label">
              × 1
            </Label>
          </Segment>
        );
      });

    }
  }

  renderBoxes() {
    var that = this;

    return this.props.activeShipment.order.final_box_configuration.final_box_configuration.map(
      function(boxConfiguration) {
        return (
          <div key={boxConfiguration}>
            <Segment.Group size="tiny">
              {that.renderBoxComponents(boxConfiguration.box.components)}
              {that.renderOverBoxComponents(boxConfiguration.overBox)}
              {
                boxConfiguration.insertType && (
                  that.renderInsertComponents(boxConfiguration.insertType)
                )
              }
              {that.renderItems(boxConfiguration.items)}
            </Segment.Group>
          </div>
        );
      }
    );
  }
  // Changes the order search filter
  sendShipmentPackToPickPack() {
    this.props.sendShipmentBackToPickPack({
      order_id: this.props.activeShipment.order.id
    });
  }

  // REnder out the shipment items
  renderHistoryItems(shipmentHistory) {
    var segmentArray = [];
    for (var key in shipmentHistory) {
      shipmentHistory[key]["date"] = key;
      segmentArray.push(shipmentHistory[key]);
    }
    segmentArray = segmentArray.sort(function(first, second) {
      if (moment(second.date) > moment(first.date)) {
        return -1;
      } else {
        return 1;
      }
    });

    return segmentArray.map(function(segment) {
      return (
        <Table.Row key={segment.date}>
          <Table.Cell collapsing>{segment.user_name}</Table.Cell>
          <Table.Cell>{segment.description}</Table.Cell>
          <Table.Cell collapsing textAlign="right">
            {dateFormat(segment.date, " mmmm dS, yyyy h:MM:ss TT ")} EST
          </Table.Cell>
        </Table.Row>
      );
    });
  }
  getPickPacker(shipmentHistory) {
    var pickPackerName = ""
    if (shipmentHistory) {
      var segmentArray = [];
      for (var key in shipmentHistory) {
        shipmentHistory[key]["date"] = key;
        segmentArray.push(shipmentHistory[key]);
      }
      segmentArray.map(function(historyItem) {
        pickPackerName = historyItem.user_name
        return historyItem
      })
    }
    return pickPackerName
  }
  renderPickPackErrors(location) {
    var completeUrl = "https://cleancult.com" + location.pathname + location.search
    var mainError = getURLParameter("mainError", completeUrl)
    var subError = getURLParameter("subError", completeUrl)
    var items = getURLParameter("items", completeUrl)
    if (items) {
      items = items.split(',')
    }
    if (!mainError) {
      return ""
    }
    return (
      <Grid.Row>
        <Grid.Column width={16}>
          <Header as="h3">Pick Pack Error Details</Header>
          <List divided>
            <List.Item>
              <Label horizontal>Main Error</Label>
              {renderPickPackMainError(mainError)}
            </List.Item>
            {
              subError && (
                <List.Item>
                  <Label horizontal>Secondary Error</Label>
                  {renderPickPackMainError(subError)}
                </List.Item>
              )
            }
            <List.Item>
              <Label horizontal>Missing or Damaged Products</Label>
              {renderMissingOrDamagedProducts(items)}
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
    )
  }
  renderPickPackChildren() {
    if (this.props.activeShipment) {
      return (
        <div className="pick-pack-container shipment-dock">
          <Modal
            closeIcon
            open={this.state.displayModal}
            onClose={() => this.toggleModalDisplay()}
            basic
            size="small"
          >
            <Modal.Content>
              <Image
                src={this.state.imageSource}
                circular
                centered
                size="large"
              />
            </Modal.Content>
          </Modal>
          <DockMenu order={this.props.activeShipment} pickPackError={this.props.pickPackError}/>

          <div className="pick-pack-content item-display-container">
            <Grid>
              {this.renderPickPackErrors(this.props.location)}
              <Grid.Row>
                <Grid.Column width={8}>
                  <Header as="h3">Customer Details</Header>

                  <List divided>
                    <List.Item>
                      <Label horizontal>Name</Label>
                      {this.props.activeShipment.shipping_address.full_name}
                    </List.Item>
                    <List.Item>
                      <Label horizontal>Address</Label>
                      {this.props.activeShipment.shipping_address.address}{" "}
                      {this.props.activeShipment.shipping_address.apt_number}{" "}
                    </List.Item>
                    <List.Item>
                      <Label horizontal>City</Label>
                      {this.props.activeShipment.shipping_address.city}
                    </List.Item>
                    <List.Item>
                      <Label horizontal>State</Label>
                      {this.props.activeShipment.shipping_address.state}
                    </List.Item>
                    <List.Item>
                      <Label horizontal>Zip</Label>
                      {this.props.activeShipment.shipping_address.zip_code}
                    </List.Item>
                    <List.Item>
                      <Label horizontal>Phone Number</Label>
                      {this.props.activeShipment.shipping_address.phone_number}
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as="h3">Shipment Details</Header>
                  <List divided>
                    <List.Item>
                      <Label horizontal>Order Date</Label>
                      {dateFormat(
                        this.props.activeShipment.order.time_charged,
                        " mmmm dS, yyyy h:MM:ss TT"
                      )}{" "}
                      EST
                    </List.Item>
                    <List.Item>
                      <Label horizontal>Shipped Date</Label>
                      {dateFormat(
                        this.props.activeShipment.order.date_pick_packed,
                        " mmmm dS, yyyy h:MM:ss TT"
                      )}
                      EST
                    </List.Item>
                    <List.Item>
                      <Label horizontal>Tracking Link</Label>
                      <a
                        href={
                          this.props.activeShipment.order.tracking_page_link
                        }
                        target="blank"
                      >
                        {this.props.activeShipment.order.tracking_number}{" "}
                        <Icon name="external share" />
                      </a>
                    </List.Item>
                    <List.Item>
                      <Label horizontal>Cost</Label>$
                      {this.props.activeShipment.order.cost_of_shipment}
                    </List.Item>
                    <List.Item>
                      <Label horizontal>Pick Packer</Label>
                      {this.getPickPacker(this.props.activeShipment.order.shipment_history)}
                    </List.Item>
                    <List.Item>
                      <Label horizontal>Provider</Label>
                      <Image
                        width="70px"
                        src={convertCarrierImage(
                          this.props.activeShipment.order.carrier
                        )}
                      />
                    </List.Item>
                    <List.Item>
                      <Label horizontal>Service</Label>
                      <span style={{ textTransform: "capitalize" }}>
                        {convertCarrierService(
                          this.props.activeShipment.order.service
                        )}
                      </span>
                    </List.Item>

                    <List.Item>
                      <Label horizontal>Weight</Label>
                      {
                        this.props.activeShipment.order.final_box_configuration
                          .final_box_configuration[0].totalWeight
                      }{" "}
                      OZs
                    </List.Item>
                  </List>
                  <Button
                    color="grey"
                    compact
                    animated="vertical"
                    as="a"
                    animation="vertical"
                    target="_blank"
                    href={this.props.activeShipment.order.shipping_label_link}
                  >
                    <Button.Content visible>
                      Download Shipping Label
                    </Button.Content>
                    <Button.Content hidden>
                      <Icon name="download" />
                    </Button.Content>
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header as="h3">Items & Box Type</Header>
                  <br />
                  {this.renderBoxes()}
                </Grid.Column>
              </Grid.Row>
              {this.props.activeShipment.order.shipment_history && (
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Table celled striped>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell colSpan="3">
                            <Header as="h3">Shipment History</Header>
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell collapsing>
                            <b>Who</b>
                          </Table.Cell>
                          <Table.Cell>
                            <b>What</b>
                          </Table.Cell>
                          <Table.Cell collapsing>
                            <b>When</b>
                          </Table.Cell>
                        </Table.Row>
                        {this.renderHistoryItems(
                          this.props.activeShipment.order.shipment_history
                        )}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
            <div
              style={{
                height: "50px",
                marginTop: "15px",
                padding: "15px"
              }}
            >
              <Button.Group style={{ float: "right" }}>
                <Button
                  basic
                  loading={this.props.shipmentsLoading}
                  negative
                  onClick={() => this.sendShipmentPackToPickPack()}
                >
                  Send Back To Pick Pack
                </Button>
              </Button.Group>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
  render() {
    return (
      <Dock
        position="right"
        isVisible={this.checkVisibleStatus()}
        fluid={false}
        dockStyle={{
          background: "#fff"
        }}
        className="react-dock"
        dimStyle={{
          background: "rgba(0, 0, 0, 0.45)"
        }}
        duration={290}
        size={900}
        children={this.renderPickPackChildren()}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    shipmentsLoading: state.shipments.shipments_loading
  };
}

export default connect(
  mapStateToProps,
  {
    sendShipmentBackToPickPack
  }
)(ShipmentDock);
