import React from "react";
import { connect } from "react-redux";
import { fetchAllOrders, generatePickSlips, deactivatePickSlips} from "../../actions/actionsOrders";
import SubMenuContainer from "../Common/SubMenuContainer";
import PickSlipTable from "../../components/CreatePickSlips/PickSlipTable";
import PickSlipGroupDock from "../../components/CreatePickSlips/PickSlipGroupDock";
import OrderFinder from "../../components/CreatePickSlips/OrderFinder";
import IdleTimer from "react-idle-timer";
import {
    getURLParameter
} from "../../helpers";

// Generates a list of slips to print based on the print slip group ID
class CreatePickSlips extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            pickSlipsLoading: false,
            pickSlipGroups: []
        };
        this.idleTimer = null;
        this.onIdle = this._onIdle.bind(this);
    }

    componentWillMount() {
        if (!this.props.orders) {
            this.props.fetchAllOrders();
        }  else {
            this.generatePickSlipGroupData(this.props.orders);
        }
    }

    // Loops through the avilable orders and creates the pick slip groups and is baseed on date
    generatePickSlipGroupData(orders) {
        // Pick 
        let pickSlipGroups = [];

        // Pick slip ids
        let pickSlipIDs = []
        let pickSlipTimesPrinted = {}
        let pickSlipGroupCount = {}

        // Loop through the ideas
        orders.map(function(orderObject) {
            if (orderObject.order.pick_slip_id) {
                let pickSlipID = orderObject.order.pick_slip_id.split("-")[1]
                if (!pickSlipIDs.includes(pickSlipID)) {
                    pickSlipIDs.push(pickSlipID)
                    pickSlipTimesPrinted[pickSlipID] = orderObject.order.time_pick_slip_printed

                }
                // Add to the count
                if (!pickSlipGroupCount[pickSlipID]) {
                    pickSlipGroupCount[pickSlipID] = 1
                } else {
                    pickSlipGroupCount[pickSlipID] += 1
                }
            }
            return orderObject;
        })

        
        // Loop through the pick slip ids and creat the objects
        pickSlipIDs.map(function(pickSlipID) {
            pickSlipGroups.push({
                pick_slip_id: pickSlipID,
                time_pick_slip_printed: pickSlipTimesPrinted[pickSlipID],
                orders_remaining: pickSlipGroupCount[pickSlipID],
                status: "",
            })
            return pickSlipID
        })

        this.setState({
            pickSlipGroups: pickSlipGroups
        })
    }

    // If the pick sliprws are succesfully creating then s3e4nd th user to a new page so they can print them out
    componentWillReceiveProps(nextProps) {
        if (nextProps.pickSlipID) {
            if (nextProps.pickSlipID !== this.props.pickSlipID) {
                var win = window.open(`/print-pick-slips?pickSlipID=${nextProps.pickSlipID}`, '_blank');
                win.focus();
            }
        }

        if (nextProps.orders) {
            this.generatePickSlipGroupData(nextProps.orders);
        }
    }



    getNotPrintedOrderCount(orders) {

        if (!orders) {
            return "0"
        }
        // Copy the current orders array
        let newOrders = [...orders]

        // Exclude all orders that do not match that
        newOrders = newOrders.filter(function(order) {
            return !order.order.pick_slip_id
        })

        return newOrders.length.toString()
    }
    render() {
        let { 
            orders, 
            generatingPickSlipsLoading,
            ordersLoading,
            lastRefresh,
            location 
        } = this.props;
        let {
            deactivatePickSlips,
            generatePickSlips
        } = this.props;

        let { pickSlipGroups } = this.state;

        return (
            <div>
                <SubMenuContainer
                    title={"Pick Slips"}
                    totalOrderCount={34}
                    outstandingOrders={this.getNotPrintedOrderCount(orders)}
                    lastRefresh={lastRefresh}
                    loading={ordersLoading}
                    refreshClick={() => this.props.fetchAllOrders()}
                />
                {
                    orders && (
                        <div className="content-body-container">   
                            <br />                         
                            <h3>Pick Slip Groups</h3>
                            <PickSlipTable
                                data={pickSlipGroups}
                                loading={ordersLoading}
                            />
                            <br />                         
                            <h3>Generate Pick Slip Groups (100 Max)</h3>
                            <OrderFinder
                                orders={orders}
                                generatePickSlips={generatePickSlips}
                                loading={ordersLoading || generatingPickSlipsLoading}
                            />
                            <PickSlipGroupDock 
                                orders={orders}
                                deactivatePickSlips={deactivatePickSlips}
                                loading={ordersLoading || generatingPickSlipsLoading}
                                active={getURLParameter("pickSlipID", location.search)}
                                pickSlipID={getURLParameter("pickSlipID", location.search)}

                            />
                            <IdleTimer
                                ref={ref => {
                                this.idleTimer = ref;
                                }}
                                element={document}
                                onIdle={this.onIdle}
                                onAction={this.onAction}
                                debounce={250}
                                timeout={1000 * 60 * 3 }
                            />
                        </div>
                    )
                }
            </div>
        );
    }
    _onIdle() {
        window.location.reload();
      }
}



function mapStateToProps(state) {
    return {
        ordersLoading: state.orders.orders_loading,
        orders: state.orders.orders,
        lastRefresh: state.orders.last_refreshed,
        pickSlipID: state.orders.pick_slip_id,
        generatingPickSlipsLoading: state.orders.generating_pick_slips_loading,
    };
}

export default connect(
    mapStateToProps,
    {  fetchAllOrders, generatePickSlips, deactivatePickSlips }
)(CreatePickSlips);
