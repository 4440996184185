import React from "react";
import { getQuantityColor } from "../../../../helpers";
import GlassSteps from "../GlassSteps";
import GlassReference from "./GlassReference";
import {
  Segment,
  Label,
  Button,
  Divider,
  Header,
  Grid,
  Modal,
  Image
} from "semantic-ui-react";
import { Message } from "semantic-ui-react";



class GlassItemDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayModal: false,
      step: "Item List",
      imageSource: null
    };
    this.renderBoxes = this.renderBoxes.bind(this);
    this.setGlassStep = this.setGlassStep.bind(this);
  }

  toggleModalDisplay(imageSource) {
    this.setState({
      displayModal: !this.state.displayModal,
      imageSource: imageSource
    });
  }
  renderItems(cartItems) {
    var that = this;
    return cartItems.map(function(cartItem) {
      return (
        <Segment key={cartItem.marketing_name}>
          <img
            alt={cartItem.marketing_name}
            src={cartItem.image}
            onClick={() => that.toggleModalDisplay(cartItem.image)}
            className="cart-item-image"
          />
          {cartItem.marketing_name}
          <br />
          <Header as="h3">{cartItem.sku}</Header>
          <Label
            size="huge"
            className="quantity-label"
            color={getQuantityColor(cartItem.quantity)}
          >
            × {cartItem.quantity}
          </Label>
        </Segment>
      );
    });
  }

  setGlassStep(step) {
    this.setState({
      step: step
    });
  }
  renderInsertComponents(insertType) {
    return (
      <Segment>
        <img
          alt={insertType.name}
          src={insertType.image}
          onClick={() => this.toggleModalDisplay(insertType.image)}
          className="cart-item-image"
        />
        {insertType.name}
        <br />
        <Header as="h3">{insertType.sku}</Header>
        <Label size="huge" className="quantity-label">
          × 1
        </Label>
      </Segment>
    );
  }

  renderBoxComponents(boxComponents, quantity) {
    var that = this;
    var count = 0;
    if (boxComponents) {
      return boxComponents.map(function(boxComponent) {
        count += 1;
        return (
          <Segment key={boxComponent.name + count}>
            <img
              alt={boxComponent.name}
              src={boxComponent.image}
              className="cart-item-image"
              onClick={() => that.toggleModalDisplay(boxComponent.image)}
            />
            {boxComponent.name} Type
            <br />
            <Header as="h3">{boxComponent.sku}</Header>
            <Label size="huge" className="quantity-label"   color={getQuantityColor(quantity)}>
              × {quantity ? quantity : "1"}
            </Label>
          </Segment>
        );
      });
    } else {
      return null;
    }
  }
  renderGlassBoxSetUpStep(step, boxConfiguration) {
    if (step === "Item List") {
      return (
        <Segment.Group size="small" piled>
          {boxConfiguration.overBox &&
            this.renderBoxComponents(boxConfiguration.overBox.components)}

          {this.renderBoxComponents(boxConfiguration.box.components, boxConfiguration.quantity)}
          {
            boxConfiguration.insertType && (
              this.renderInsertComponents(boxConfiguration.insertType)
            )
          }
          {this.renderItems(boxConfiguration.items)}
        </Segment.Group>
      );
    } else {
        return (
          <GlassReference reference={boxConfiguration.glassBoxLayout.Reference}  />
        )

    }
  }

  renderGlassLayoutButton(step, boxConfiguration) {
    // console.log("--------")
    // console.log(step)
    // console.log(boxConfiguration)
    if (
      step!== "Item List" || !boxConfiguration.glassBoxLayout
    ) {
      return (
        <Button
          size="massive"
          color="teal"
          fluid
          onClick={() => this.props.setPickPackStep("Box Type")}
        >
          <Button.Content visible> Next Step (Box Type)</Button.Content>
        </Button>
      );
    } else {
      return (
        <Button
          size="massive"
          color="teal"
          fluid
          onClick={() =>
            this.setState({
              step: "Reference"
            })
          }
        >
          <Button.Content visible>
            Next Step (Reference)
          </Button.Content>
        </Button>
      );
    }
  }

  hasAllPurposeCleaner(items) {
    let hasAllPurposeCleaner = false
    items.map(function(item) {
      // console.log(item)
      if (item.id) {
        if (item.id.includes("all_purpose_cleaner_glass")) {
          hasAllPurposeCleaner = true
        }
      }
      return item
    })
    return hasAllPurposeCleaner
  }
  renderBoxes() {
    var count = 0;
    var that = this;
    return this.props.finalBoxConfiguration.map(function(boxConfiguration) {
      count += 1;
      return (
        <Grid.Column key={count} width={16}>
        
          {
            boxConfiguration.glassBoxLayout && (

                <GlassSteps
                  step={that.state.step}
                  setGlassStep={that.setGlassStep}
                />
            )
          }
          {that.props.order.order.pick_pack_special_instructions &&
            that.state.step === "Item List" && (
              <Message size="large" info>
                {that.props.order.order.pick_pack_special_instructions}
              </Message>
            )}
            {
              that.hasAllPurposeCleaner(boxConfiguration.items) && (

                <Message size='small' warning  header='MAKE SURE TO TIGHTEN ALL PURPOSE CLEANER HEAD'></Message>
              )
            }

          {that.renderGlassBoxSetUpStep(
            that.state.step,
            boxConfiguration,
          )}
          <br />
          <div className="pick-pack-dock-footer">
              <Divider horizontal>THEN</Divider>
              <div style={{ textAlign: "center" }}>
                {that.renderGlassLayoutButton(that.state.step, boxConfiguration)}
              </div>
            </div>

        </Grid.Column>
      );
    });
  }
  render() {
    return (
      <div className="item-display-container">
        <Modal
          closeIcon
          open={this.state.displayModal}
          onClose={() => this.toggleModalDisplay()}
          basic
          size="small"
        >
          <Modal.Content>
            <Image src={this.state.imageSource} centered size="huge" />
          </Modal.Content>
        </Modal>
        <Grid>
          <Grid.Row>
            
            {this.renderBoxes()}
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default GlassItemDisplay;
