import React from "react";
import { Header, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

class Menu extends React.Component {
  render() {
    return (
      <div className="pick-pack-menu">
        <Header size="large" className="menu-header">
          {this.props.activeUser
            ? `Edit ${this.props.activeUser.first_name} ${
                this.props.activeUser.last_name
              }`
            : "Add New User"}
        </Header>

        <Link to="/user-management">
          <Icon
            name="close"
            size="big"
            className="float-right"
            style={{ color: "black" }}
          />
        </Link>
        <br />
      </div>
    );
  }
}

export default Menu;
