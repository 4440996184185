import * as types from "../actions/types";

const INITIAL_STATE = {
    orders: null,
    orders_loading: false,
    last_refreshed: new Date().toLocaleString("en-US", {
        timeZone: "America/New_York"
    })
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.TOGGLE_AMAZON_AWAITING_ORDERS_LOADING:
            return {
                ...state,
                orders_loading: !state.orders_loading
            };
        case types.FETCH_ALL_AMAZON_AWAITING_ORDERS_SUCCESS:
                return {
                    ...state,
                    orders: action.payload,
                    orders_loading: false,
                    last_refreshed: new Date().toLocaleString("en-US", {
                    timeZone: "America/New_York"
                })
            };
    default:
        return state;
    }
}
