import * as types from "../actions/types";

const INITIAL_STATE = {
  sales_projections: null,
  inventory_components: null,
  projections_loading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TOGGLE_PROJECTIONS_LOADING:
      return {
        ...state,
        projections_loading: !state.logged_in_user_loading
      };
    case types.FETCH_INVENTORY_COMPONENTS_SUCCESS:
      var loading = true;
      if (state.sales_projections) {
        loading = false;
      }
      return {
        ...state,
        inventory_components: action.payload,
        projections_loading: loading
      };
    case types.FETCH_SALES_PROJECTIONS_SUCCESS:
      loading = true;
      if (state.inventory_components) {
        loading = false;
      }
      return {
        ...state,
        sales_projections: action.payload,
        projections_loading: loading
      };

    default:
      return state;
  }
}
