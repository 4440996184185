import * as types from "../actions/types";

const INITIAL_STATE = {
  inventory_counts: null,
  product_inventory: null,
  all_inventory_loading: false,
  last_refreshed: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TOGGLE_ALL_INVENTORY_LOADING:
        return {
          ...state,
          all_inventory_loading: !state.all_inventory_loading
        };
    case types.FETCH_ALL_INVENTORY_COUNTS_SUCCESS:
      return {
        ...state,
        inventory_counts: action.payload,
        dashboard_data_loading: false,
        last_refreshed: new Date().toLocaleString("en-US", {
          timeZone: "America/New_York"
        })
      };
    case types.FETCH_ALL_PRODUCT_INVENTORY_SUCCESS:
      return {
        ...state,
        product_inventory: action.payload,
        dashboard_data_loading: false,
        last_refreshed: new Date().toLocaleString("en-US", {
          timeZone: "America/New_York"
        })
      };

    default:
      return state;
  }
}
