import * as types from "../actions/types";

const INITIAL_STATE = {
  pick_pack_errors: null,
  pick_pack_errors_loading: false,
  pick_pack_error_detail: null,
  last_refreshed: new Date().toLocaleString("en-US", {
    timeZone: "America/New_York"
  })
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TOGGLE_PICK_PACK_ERRORS_LOADING:
      return {
        ...state,
        pick_pack_errors_loading: !state.pick_pack_errors_loading
      };
    case types.FETCH_ALL_PICK_PACK_ERRORS_SUCCESS:
      return {
        ...state,
        pick_pack_errors: action.payload,
        pick_pack_errors_loading: false,
        last_refreshed: new Date().toLocaleString("en-US", {
          timeZone: "America/New_York"
        })
      };

    default:
      return state;
  }
}
