import React from "react";
import { connect } from "react-redux";
import SlipGroup from "../../components/PrintPickSlips/SlipGroup"
import ReactToPrint from 'react-to-print';
import {  Button, Icon, Segment } from "semantic-ui-react";
import { fetchAllOrders } from "../../actions/actionsOrders";


// Generates a list of slips to print based on the print slip group ID
class PrintSlips extends React.Component { 

    componentWillMount() {
        if (!this.props.orders) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const pickSlipID = urlParams.get('pickSlipID')
            this.props.fetchAllOrders(pickSlipID);
        } 
    }

    render() {
        let { orders } = this.props;
        return (
            <Segment loading={!orders} style={{minHeight: "300px"}}>
                {
                    orders && (
                        <div className="print-pick-slip-container">
                            <ReactToPrint
                                trigger={() => (
                                    <Button
                                        animated="vertical"
                                        size="large"
                                        color="green"
                                        fluid
                                    >
                                        <Button.Content visible> Print Pick Slips</Button.Content>
                                        <Button.Content hidden>
                                            <Icon name="print" />
                                        </Button.Content>
                                    </Button>
                                )}
                                content={() => this.componentRef}
                            />
                            <br />
                            <br />
                            <SlipGroup 
          
                                ref={el => (this.componentRef = el)} 
                                orders={orders} 
                                location={this.props.location}
                            />
                        </div>
                    )
                }
            </Segment>
        );
    }
}

function mapStateToProps(state) {
    return {
        ordersLoading: state.orders.orders_loading,
        orders: state.orders.orders,
    };
}

export default connect(
    mapStateToProps,
    {  fetchAllOrders }
)(PrintSlips);
