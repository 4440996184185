import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logOutUser } from "../../actions/actionsAuth";
import { Button, Menu, Dropdown, Icon } from "semantic-ui-react";
import Copyright from "../../components/Common/Copyright";
import OrderCounter from "../../components/Orders/OrderCounter";
import KeyboardHelp from "../../components/Common/KeyboardHelp";
import { Link } from "react-router-dom";
import { extractIDFromPath } from "../../helpers";
import IdleTimer from "react-idle-timer";
import history from "../../history";
import mouseTrap from "react-mousetrap";
import QuickSwitchUserModal from "./QuickSwitchUserModal";

class PortalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowStyling: {},
      quickSwitchUserModalOpen: false
    };
    this.closeDock = this.closeDock.bind(this);
    this.toggleQuickSwitchModal = this.toggleQuickSwitchModal.bind(this);

    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
  }

  componentWillMount() {

    this.setWindowStyling(this.props);
    this.props.bindShortcut("d", () => history.push("/dashboard"));
    this.props.bindShortcut("o", () => history.push("/scan-pick-slips"));
    this.props.bindShortcut("u", () => history.push("/user-management"));
    this.props.bindShortcut("s", () => history.push("/shipments"));
    this.props.bindShortcut("esc", () => this.closeDock());
  }
  componentWillUnmount() {
    this.props.unbindShortcut("d");
    this.props.unbindShortcut("u");
    this.props.unbindShortcut("o");
    this.props.unbindShortcut("s");
    this.props.unbindShortcut("esc");
  }
  componentWillReceiveProps(nextProps) {
    this.setWindowStyling(nextProps);
  }

  closeDock() {
    if (this.props.location.pathname.includes("shipments")) {
      history.push("/shipments");
    } else if (this.props.location.pathname.includes("orders")) {
      history.push("/scan-pick-slips");
    }
  }

  setWindowStyling(props) {
    if (extractIDFromPath(props.location.pathname)) {
      this.setState({
        windowStyling: {
          position: "fixed",
          width: "100%"
        }
      });
    } else {
      this.setState({
        windowStyling: {}
      });
    }
  }

  toggleQuickSwitchModal() {
    this.setState({
      quickSwitchUserModalOpen: !this.state.quickSwitchUserModalOpen
    })
  }

  render() {
    //   If they are on the login page, dont' show the main portal
    if (this.props.location.pathname.includes("login") || this.props.location.pathname.includes("print-pick-slip") || this.props.location.pathname.includes("print-amz-pick-slip") ) {
      return this.props.children;
    } else {
      return (
        <div className="portal-container" style={this.state.windowStyling}>
          <Menu size="huge" className="margin-bottom-zero">
            <Menu.Item>
              <img src={"//images.ctfassets.net/z3ixs6i8tjtq/1LAavKzDMuzsWbQnrFxMxi/85ca01045628d9dc6489f04b6417bbc3/NewLogo.png?w=200"} alt="cleancult Logo" style={{ width: "120px" }} />
            </Menu.Item>


            {this.props.loggedInUser.fulfillment_super_user && (
              <Dropdown item text="Admin Functions" simple>
              <Dropdown.Menu>
         
          
           
             
                  <Menu.Item
                    active={this.props.location.pathname.includes(
                      "user-management"
                    )}
                    as={Link}
                    to="/user-management"
                  >
                    User Management
                  </Menu.Item>
             
                  <Menu.Item
                    active={this.props.location.pathname.includes(
                      "create-pick-slips"
                    )}
                    as={Link}
                    to="/create-pick-slips"
                  >
                    Create DTC Pick Slips
                  </Menu.Item>
                  <Menu.Item
                    active={this.props.location.pathname.includes(
                      "create-amz-pick-slips"
                    )}
                    as={Link}
                    to="/create-amz-pick-slips"
                  >
                    Create AMZ Pick Slips
                  </Menu.Item>
                  <Menu.Item
                    active={this.props.location.pathname.includes(
                      "edit-workstation-printer-ids"
                    )}
                    as={Link}
                    to="/edit-workstation-printer-ids"
                  >
                    Edit Printer IDs
                  </Menu.Item>
                 
            
       
              </Dropdown.Menu>
            </Dropdown>
                )}
           
            <Menu.Item
              as={Link}
              name="Search Shipments"
              to="/shipments"
              active={this.props.location.pathname.includes("shipments")}
            />
           

              <Dropdown item text="Scan Pick Slips" simple>
              <Dropdown.Menu>
         
                  <Menu.Item
                active={this.props.location.pathname.includes(
                  "scan-pick-slips"
                )}
                as={Link}
                to="/scan-pick-slips"
              >
                Scan DTC Pick Slips
              </Menu.Item>
              <Menu.Item
                active={this.props.location.pathname.includes(
                  "scan-amz-pick-slips"
                )}
                as={Link}
                to="/scan-amz-pick-slips"
              >
                Scan AMZ Pick Slips
              </Menu.Item>
       
              </Dropdown.Menu>
            </Dropdown>

            <Menu.Menu position="right">
              <Menu.Item >
                <p size="medium">
                  {this.props.loggedInUser.first_name}{" "}
                  {this.props.loggedInUser.last_name} # {this.props.loggedInUser.vdl_employee_id}
                </p>
              </Menu.Item>
              <Menu.Item>
                <Button
                  size="large"
                  
                  color="blue"
                  onClick={() => this.toggleQuickSwitchModal()}
                >
                <Icon name="refresh" />
                  Switch User
                </Button>
              </Menu.Item>
              <Menu.Item >
                <Button
                  size="mini"
                  compact
                  color="red"
                  onClick={() => this.props.logOutUser()}
                >
                  <Icon name="sign-out" /> Exit
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
          {this.props.children}
          <Copyright />
          <QuickSwitchUserModal 
              toggleQuickSwitchModal={() => this.toggleQuickSwitchModal()}
              quickSwitchUserModalOpen={this.state.quickSwitchUserModalOpen}
          />
          <OrderCounter />
          <KeyboardHelp />
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            element={document}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={1000 * 60 * 5 }
        />
        </div>
      );
    }
  }

  _onIdle() {
    this.setState({
      quickSwitchUserModalOpen: true
    })
  }
}

function mapStateToProps(state) {
  return {
    loggedInUser: state.auth.logged_in_user,
  };
}

export default mouseTrap(
  withRouter(
    connect(
      mapStateToProps,
      { logOutUser }
    )(PortalContainer)
  )
);
