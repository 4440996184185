import React from "react";
import { connect } from "react-redux";

class OrderCounter extends React.Component {
  renderOpenClass() {
    if (this.props.ordersFulfilled > 0) {
      return "opened";
    } else {
      return "";
    }
  }
  renderBoxes() {
    return this.props.ordersFulfilled
  }
  render() {
    return (
      <div className={"order-counter-container " + this.renderOpenClass()}>
        <p>{this.renderBoxes()} Order Fulfilled</p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ordersFulfilled: state.orders.orders_fulfilled
  };
}

export default connect(
  mapStateToProps,
  null
)(OrderCounter);
