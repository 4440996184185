import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import { toast } from "react-toastify";
import { reset } from "redux-form/es/actions";
import BASE_API_URL from "./env";

import history from "../history";
axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

// Loads in all of the fulfillments users into state
export function fetchAllFulfillmentUsersSuccess(json) {
  return {
    type: types.FETCH_ALL_FULFILLMENT_USERS_SUCCESS,
    payload: json
  };
}

// Triggers the fulfillment user loading function
export function toggleFulfillmentUsersLoading() {
  return { type: types.TOGGLE_FULFILLMENT_USERS_LOADING, payload: null };
}

// Fetch all fulfillment users
export function fetchAllFulfillmentUsers() {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleFulfillmentUsersLoading());
    return axios({
      url: `${BASE_API_URL}fulfillment/get-all-fulfillment-users/`,
      timeout: 20000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchAllFulfillmentUsersSuccess(response.data.users));
      })
      .catch(function(error) {
        toast.error("Hmmm, there seems to be an error retreiving the users", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };
}

// Create a new fulfillment user
export function createNewFulfillmentUser(data) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleFulfillmentUsersLoading());
    return axios({
      url: `${BASE_API_URL}fulfillment/create-new-fulfillment-user/`,
      timeout: 20000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchAllFulfillmentUsers());
        dispatch(reset("CreateUserForm"));
        history.push("/user-management");
        toast.success("Successfully created a new user!", {
          position: toast.POSITION.TOP_LEFT
        });
      })
      .catch(function(error) {
        dispatch(toggleFulfillmentUsersLoading());
        toast.error(error.response.data.error, {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };
}

// Edit a fulfillment user
export function editFulfillmentUser(data) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleFulfillmentUsersLoading());
    return axios({
      url: `${BASE_API_URL}fulfillment/edit-fulfillment-user/`,
      timeout: 20000,
      method: "put",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchAllFulfillmentUsers());
        toast.success("Your edits have been saved!", {
          position: toast.POSITION.TOP_LEFT
        });
      })
      .catch(function(error) {
        dispatch(toggleFulfillmentUsersLoading());
        toast.error(error.response.data.error, {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };
}
