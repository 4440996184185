import React from "react";

class Copyright extends React.Component {
  render() {
    return (
      <p className="copyright-tag">Copyright Bocks Inc. d/b/a Cleancult 2020</p>
    );
  }
}

export default Copyright;
