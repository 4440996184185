import React from "react";
import { Segment, Header, Button, Label } from "semantic-ui-react";

class SubMenuContainer extends React.Component {
  render() {
    return (
      <div className="submenu-container">
        <Segment placeholder className="margin-top-zero">
          <div
            className={
              this.props.long
                ? "content-body-container-long"
                : "content-body-container "
            }
          >
            <Header as="h1">
              {this.props.title}
              {this.props.count && (
                <Label circular color={"teal"}>
                  {this.props.count}
                </Label>
              )}
              {this.props.outstandingOrders && (
                <Label circular color={"orange"}>
                  {this.props.outstandingOrders} Orders Awaiting Shipment Not Printed
                </Label>
              )}
              {this.props.explainerCount && (
                <Label circular color={"grey"}>
                  +
                </Label>
              )}
              {this.props.otherCount && (
                <Label circular color={"orange"}>
                  {this.props.otherCount}
                </Label>
              )}
              {this.props.explainerCount && (
                <Label circular color={"grey"}>
                  +
                </Label>
              )}
              {this.props.otherCount && (
                <Label circular color={"red"}>
                  {this.props.otherOtherCount}
                </Label>
              )}
              {this.props.explainerCount && (
                <Label circular color={"grey"}>
                  +
                </Label>
              )}
              {this.props.otherOtherOtherCount && (
                <Label circular color={"pink"}>
                  {this.props.otherOtherOtherCount}
                </Label>
              )}
              {this.props.explainerCount && (
                <Label circular color={"grey"}>
                  =
                </Label>
              )}
              {this.props.explainerCount && (
                <Label circular color={"green"}>
                  {this.props.explainerCount}
                </Label>
              )}
            </Header>
            {this.props.lastRefresh && (
              <p>Last Refreshed: {this.props.lastRefresh}</p>
            )}
            {!this.props.noButton && (
              <Button
                loading={this.props.loading}
                color="teal"
                className="submenu-button"
                onClick={() => this.props.refreshClick()}
              >
                Refresh {this.props.title}
              </Button>
            )}
          </div>
        </Segment>
      </div>
    );
  }
}

export default SubMenuContainer;
