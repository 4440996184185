import React from "react";
import { connect } from "react-redux";
import { fetchSingleOrder,   setWorkStationPrinterID } from "../../actions/actionsOrders";
import { Icon, Header } from "semantic-ui-react";
import PickPackDock from "../Orders/PickPackDock";
import { getOrderFromURL, extractIDFromPath } from "../../helpers";
import PickSlipIDEntry from "../../components/ScanPickSlips/PickSlipIDEntry";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Dropdown  } from "semantic-ui-react";

const workStationPrintersIDs = [
    { key: "WS1", text: "Print From Work Station 1", value: "WS1", displayname: "Work Station 1" },
    { key: "WS2", text: "Print From Work Station 2", value: "WS2", displayname: "Work Station 2" },
    { key: "WS3", text: "Print From Work Station 3", value: "WS3", displayname: "Work Station 3" },
    { key: "WS4", text: "Print From Work Station 4", value: "WS4", displayname: "Work Station 4" },
    { key: "WS5", text: "Print From Work Station 5", value: "WS5", displayname: "Work Station 5" },
    { key: "WS6", text: "Print From Work Station 6", value: "WS6", displayname: "Work Station 6" },
    { key: "WS7", text: "Print From Work Station 7", value: "WS7", displayname: "Work Station 7" },
];


// Generates a list of slips to print based on the print slip group ID   
class ScanPickSlips extends React.Component { 
    constructor(props) {    
        super(props);
        this.state = {
            labelIsPrinting: false,
            activeOrder: null,
            step: "Pick Pack",
        };
        this.setActiveOrderObject = this.setActiveOrderObject.bind(this);
        this.setPickPackStep = this.setPickPackStep.bind(this);
    }

    
    // Fetches all the orders from the DJ DB
    componentWillMount() {
        let orderID = extractIDFromPath(this.props.location.pathname)
        
        if (orderID) {
            this.props.fetchSingleOrder(null, orderID)
        }
        
        if (Cookies.get("workStationPrinterID")) {
        var defaultWorkStationPrinter = Cookies.get("workStationPrinterID")
            
        this.props.setWorkStationPrinterID(defaultWorkStationPrinter);
        }
}


    // CHeck if the current URL does not match the old one
    componentWillReceiveProps(nextProps) {
        var nextURL = nextProps.location.pathname;
        if (nextProps.orders) {
            if (extractIDFromPath(nextURL)) {
                var orderObject = getOrderFromURL(nextURL, nextProps.orders);
                var pastOrderObjectID = null;
                if (this.props.orders) {
                    pastOrderObjectID = getOrderFromURL(nextURL, this.props.orders);
                    if (pastOrderObjectID) {
                        pastOrderObjectID = pastOrderObjectID.order.id;
                    }
                }
                if (orderObject) {
                    if (orderObject.order.id === pastOrderObjectID) {
                        this.setActiveOrderObject(orderObject, false);
                    } else {
                        this.setActiveOrderObject(orderObject, true);
                    }
                }
            } else {
                this.setActiveOrderObject(null);
            }
        }
    }
    
    changeWorkStationPrinterID = (event, data) => {
        Cookies.set("workStationPrinterID", data.value);
        toast.info("You have switched your work station printer", {
            position: toast.POSITION.TOP_LEFT
        });
        this.props.setWorkStationPrinterID(data.value);
    }

    // Sets the active order object in state
    setActiveOrderObject(orderObject, newOrder) {
        if (orderObject) {
            this.setState({
                activeOrder: orderObject
            });
        if (this.props.step !== "Label Printing" && newOrder) {
            this.setState({
                labelIsPrinting: false
            });
        }
        } else {
            this.setState({
                step: "Pick Pack"
            });
            this.setState({
                activeOrder: null
            });
        }
    }

    // Set Pick Pack Steps
    setPickPackStep(step) {
        this.setState({
        step: step
        });
    }

        
    render() {
        let {
            ordersLoading,
            orders,
            fetchSingleOrder
        } = this.props;

        return (
            <div>
                <div className="bar-code-icon">
                    <Icon name="barcode" />
                    <Header size="medium" as='h5'>DTC Orders</Header>
                </div>
                <div className="content-body-container">
                    <div style={{
                        width: "100%",
                        display: "block",
                        marginBottom: "30px",
                        textAlign: "center"
                    }}>
                        <Dropdown
                            placeholder="Work Station Printer ID"
                            selection
                            value={this.props.workStationPrinterID}
                            onChange={this.changeWorkStationPrinterID}
                            style={{
                                marginRight: "10px"
                            }}
                            options={workStationPrintersIDs}
                        />
                    </div>
                    <PickSlipIDEntry
                        loading={ordersLoading}
                        orders={orders}
                        fetchSingleOrder={fetchSingleOrder}
                    />
                    <PickPackDock
                        labelIsPrinting={this.state.labelIsPrinting}
                        setPickPackStep={this.setPickPackStep}
                        step={this.state.step}
                        pickSlipScreen={true}
                        location={this.props.location}
                        activeOrder={this.state.activeOrder}
                        setActiveOrderObject={this.setActiveOrderObject}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        ordersLoading: state.orders.orders_loading,
        orders: state.orders.orders,
        lastRefresh: state.orders.last_refreshed,
        workStationPrinterID: state.orders.work_station_printer_id,
        loggedInUser: state.auth.logged_in_user,
        orderParseType: state.orders.order_parse_type
    };
}

export default connect(
    mapStateToProps,
    {  fetchSingleOrder, setWorkStationPrinterID }
)(ScanPickSlips);
