import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import { reset } from "redux-form/es/actions";
import history from "../history";
import BASE_API_URL from "./env";
import { toast } from "react-toastify";

axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

// Retrives Logged In User Information Upon Initial APplication Load
export function loggedInUserSuccess(json) {
  return {
    type: types.FETCH_LOGGED_IN_USER_SUCCESS,
    payload: json
  };
}

// Triggers the user loading function
export function toggleLoggedInUserLoading() {
  return { type: types.TOGGLE_LOGGED_IN_USER_LOADING, payload: null };
}

// User did not load in, either 500 or guest checkout
export function loggedInUserFail(json) {
  return {
    type: types.FETCH_LOGGED_IN_USER_FAILURE_MESSAGE,
    payload: json
  };
}

// Gets the Knox token
export function fetchUserKnoxToken(data) {
  return function(dispatch) {
    dispatch(toggleLoggedInUserLoading());
    return axios({
      url: `${BASE_API_URL}users/token-login`,
      timeout: 20000,
      method: "post",
      data: data,
      responseType: "json"
    })
      .then(function(response) {
        // Set the Token to local storage, then dreiclty
        localStorage.setItem("Token", response.data.token);
        setTimeout(function() {
          // Load the new user into state
          dispatch(loggedInUserSuccess(response.data.user));
          history.push("/scan-pick-slips");
        }, 1300);
      })
      .catch(function(error) {
        dispatch(reset("LoginForm"));
        if (error.response) {
          dispatch(loggedInUserFail(error.response.data.error));
        } else {
          dispatch(
            loggedInUserFail(
              "Sorry, there was an error on our end of things. The proper people have been notified."
            )
          );
        }
      });
  };
}

// Retrives the logges in users information up first load of the application
export function fetchLoggedInUserData(redirect) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleLoggedInUserLoading());
    return axios({
      url: `${BASE_API_URL}users/admin-logged-in-user`,
      timeout: 20000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(loggedInUserSuccess(response.data));
        if (redirect) {
          history.push("/scan-pick-slips");
        }
      })
      .catch(function(error) {
        localStorage.removeItem("Token");
        dispatch(loggedInUserFail("You need to login before continuing"));
        history.push("/login");
      });
  };
}

// Logout user and invalidates all tokens
export function logOutUser(redirect) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleLoggedInUserLoading());
    return axios({
      url: `${BASE_API_URL}knox/logout/`,
      timeout: 20000,
      method: "post",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function() {
        dispatch(loggedInUserSuccess(null));
        localStorage.removeItem("Token");

        history.push("/login");
      })
      .catch(function(error) {
        dispatch(loggedInUserSuccess(null));
        localStorage.removeItem("Token");
        history.push("/login");
      });
  };
}

// Triggers the quick sqap user loading function
export function toggleQuickSwapUserLoading() {
  return { type: types.TOGGLE_QUICK_SWAP_USER_LOADING, payload: null };
}

// User did not load in, either 500 or guest checkout
export function setQuickSwapUserFailureMessage(json) {
  
  return {
    type: types.SET_QUICK_SWAP_USER_FAILURE_MESSAGE,
    payload: json
  };
}

// User did not load in, either 500 or guest checkout
export function resetQuickSwapUserFailureMessage() {

  return function(dispatch) {
    dispatch(setQuickSwapUserFailureMessage(""))
  }
  
}


// Gets the Knox token
export function quickSwapFulfillmentUsers(data) {

  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleQuickSwapUserLoading());
    return axios({
      url: `${BASE_API_URL}fulfillment/quick-switch-fulfillment-user-accounts/`,
      timeout: 20000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        // Set the Token to local storage, then dreiclty
        localStorage.setItem("Token", response.data.token);
        dispatch(loggedInUserSuccess(response.data.user));
        toast.success("Succesfully switched user accounts")
      })
      .catch(function(error) {
        if (error.response) {
          dispatch(setQuickSwapUserFailureMessage(error.response.data.error));
        } else {
          dispatch(setQuickSwapUserFailureMessage( "Sorry, there was an error on our end of things. The proper people have been notified."))
        }
      });
  };
}