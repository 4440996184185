import React from "react";
import ReactTable from "react-table";
import {Segment, Icon, Image} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {convertCarrierImage, convertCarrierService} from "../../helpers";
var dateFormat = require("dateformat");

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  componentDidMount() {
    var data = this
      .reactTable
      .getResolvedState()
      .sortedData;
    this.setState({data: data});
  }
  render() {
    return (
      <div>
        <br />
        <Segment className="table-container" loading={this.props.loading}>
          <ReactTable
            data={this.props.data}
            defaultPageSize={10}
            ref={r => (this.reactTable = r)}
            noDataText="No Shipments Found"
            columns={[
            {
              Header: "Order ID",
              id: "order_id",
              width: 145,
              accessor: d => d.order.id,
              Cell: ({original}) => {
                return (
                  <Link to={`/shipments/${original.order.id}`}>
                    {original.order.id}
                  </Link>
                );
              }
            }, {
              Header: "Date Picked",
              id: "date_shipped",
              width: 180,
              accessor: d => d.order.date_pick_packed,
              sortMethod: (a, b) => {
                var d1 = new Date(a);
                var d2 = new Date(b);
                return d1 > d2
                  ? 1
                  : -1;
              },
              Cell: ({original}) => {
                if (!original.order.date_pick_packed) {
                  return <div/>;
                }
                return (
                  <div
                    style={{
                    textAlign: "center"
                  }}
                    className="multi-line-cell">
                    <p>
                      {dateFormat(original.order.date_pick_packed, " mmmm dS, yyyy")}{" "}
                      EST
                    </p>
                    <p>
                      {dateFormat(original.order.date_pick_packed, "h:MM:ss TT ")}
                    </p>
                  </div>
                );
              }
            }, {
              Header: "Provider",
              id: "provider",
              width: 80,
              accessor: d => d.order.carrier,
              Cell: ({original}) => {
                return (<Image src={convertCarrierImage(original.order.carrier)}/>);
              }
            }, {
              Header: "Postage",
              id: "postage_cost",
              width: 80,
              accessor: d => d.order.cost_of_shipment,
              
            }, {
              Header: "Service",
              id: "service",
              width: 210,
              accessor: d => d.order.service,
              Cell: ({original}) => {
                return (
                  <p
                    style={{
                    textTransform: "capitalize"
                  }}>
                    {convertCarrierService(original.order.service)}
                  </p>
                );
              }
            }, {
              Header: "Customer Address",
              id: "customer_address",
              width: 310,
              accessor: d => d.shipping_address.state + ", "  + d.shipping_address.zip_code,
              Cell: ({original}) => {
                return (
                  <div className=" multi-line-cell address-line-cell">
                    <p>{original.shipping_address.full_name}</p>
                    <p>
                      {original.shipping_address.address}{" "} {original.shipping_address.apt_number}{" "}
                    </p>
                    <p>
                      {original.shipping_address.city}{" "} {original.shipping_address.state},{" "} {original.shipping_address.zip_code}
                    </p>
                  </div>
                );
              }
            }, {
              Header: "Tracking #",
              id: "tracking_number",
              width: 245,
              accessor: d => d.order.tracking_number,
              Cell: ({original}) => {
                return (
                  <a href={original.order.tracking_page_link} target="blank">
                    {original.order.tracking_number}{" "}
                    <Icon name="external share"/>
                  </a>
                );
              }
            } ,
            {
              Header: "Weight OZ",
              id: "weight_ozs",
              width: 245,
              accessor: d => d.order.final_box_configuration.final_box_configuration[0].totalWeight,
             
            }
          ]}
            className=" -highlight"/>
        </Segment>
      </div>
    );
  }
}

export default Table;
