// Define the base API URL dpening on which enviroes

var websiteURL = window.location.href;
let BASE_API_URL;

if (websiteURL.includes("staging")) {
  BASE_API_URL = "https://staging.api.cleancult.com/v2/";
} else {
  BASE_API_URL = "https://api.cleancult.com/v2/";
}
if (process.env.NODE_ENV === "development") {
  BASE_API_URL = "http://127.0.0.1:8000/v2/";
}

// console.log(BASE_API_URL);
export default BASE_API_URL;
