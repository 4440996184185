import React from "react";
import Dock from "react-dock";
import DockHeader from "../../components/UserManagement/DockHeader";
import { extractIDFromPath, getUserFromID } from "../../helpers";
import CreateUserForm from "../../containers/FormContainers/UserManagement/CreateUserForm";
import EditUserForm from "../../containers/FormContainers/UserManagement/EditUserForm";

class UserDock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeUser: null
    };
  }
  componentWillMount() {
    this.setState({
      activeUser: null
    });
  }
  checkOpenStatus() {
    // Check if the dock should open
    if (this.props.location.pathname.includes("add")) {
      return true;
    } else if (this.props.location.pathname.includes("edit")) {
      var userID = extractIDFromPath(this.props.location.pathname);
      if (userID) {
        var activeUser = getUserFromID(this.props.fulfillmentUsers, userID);
        if (activeUser) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  renderDockChildren() {
    var activeUser = getUserFromID(
      this.props.fulfillmentUsers,
      extractIDFromPath(this.props.location.pathname)
    );
    return (
      <div className="pick-pack-container">
        <DockHeader activeUser={activeUser} />
        <div className="pick-pack-content">
          {activeUser ? (
            <EditUserForm user={activeUser} initialValues={activeUser} />
          ) : (
            <CreateUserForm />
          )}
        </div>
      </div>
    );
  }
  render() {
    return (
      <Dock
        position="right"
        isVisible={this.checkOpenStatus()}
        fluid={false}
        dockStyle={{
          background: "#fff"
        }}
        className="react-dock"
        dimStyle={{
          background: "rgba(0, 0, 0, 0.45)"
        }}
        duration={290}
        size={500}
        children={this.renderDockChildren()}
      />
    );
  }
}

export default UserDock;
