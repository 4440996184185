import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "./history";

// Common Containers
import LoggedInUserContainer from "./containers/Authentication/LoggedInUserContainer";
import PortalContainer from "./containers/Common/PortalContainer";

// Portal Views
import AuthenticationContainer from "./containers/Authentication";
// import OrdersContainer from "./containers/Orders";
import UserManagement from "./containers/UserManagement";
import ShipmentsContainer from "./containers/Shipments";
import BoxAssembly from "./containers/BoxAssembly";
import PrintPickSlips from "./containers/PrintPickSlips";
import PrintAmazonPickSlips from "./containers/PrintAmazonPickSlips";
import CreatePickSlips from "./containers/CreatePickSlips";
import CreateAmazonPickSlips from "./containers/CreateAmazonPickSlips";
import ScanPickSlips from "./containers/ScanPickSlips";
import ScanAmazonPickSlips from "./containers/ScanAmazonPickSlips";
import EditWorkStationPrinterIds from "./containers/EditWorkStationPrinterIds";

class Routes extends React.Component {
  render() {
    return (
      <Router history={history}>
        <LoggedInUserContainer>
          <PortalContainer>
            <Switch>
              <Route path="/login" component={AuthenticationContainer} />
              <Route path="/user-management" component={UserManagement} />
              <Route path="/box-assembly-video" component={BoxAssembly} />
              <Route path="/print-pick-slips" component={PrintPickSlips} />
              <Route path="/print-amz-pick-slips" component={PrintAmazonPickSlips} />
              <Route path="/create-pick-slips" component={CreatePickSlips} />
              <Route path="/create-amz-pick-slips" component={CreateAmazonPickSlips} />
              <Route path="/scan-pick-slips" component={ScanPickSlips} />
              <Route path="/scan-amz-pick-slips" component={ScanAmazonPickSlips} />
              <Route path="/shipments" component={ShipmentsContainer} />
              <Route path="/edit-workstation-printer-ids" component={EditWorkStationPrinterIds} />
              <Redirect from="*" to="/login" />
            </Switch>
          </PortalContainer>
        </LoggedInUserContainer>
      </Router>
    );
  }
}

export default Routes;
