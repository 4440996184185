import * as types from "../actions/types";

const INITIAL_STATE = {
    reconciliation: null,
    reconciliation_loading: false,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TOGGLE_RECONCILIATION_DATA_LOADING:
      return {
        ...state,
        reconciliation_loading: !state.reconciliation_loading
      };
    case types.FETCH_ALL_RECONCILIATION_DATA:
      return {
        ...state,
        reconciliation: action.payload,
        reconciliation_loading: false,
      };

    default:
      return state;
  }
}
