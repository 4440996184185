import React from "react";
import { Header, Form, Button, Divider, Icon } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { placeOrderOnHold } from "../../../../actions/actionsOrders";
import { textAreaField } from "../../../Form/FieldRenders";
import history from "../../../../history";

const validate = values => {
  const errors = {};
  if (!values.hold_reason) {
    errors.hold_reason = "Required";
  }
  return errors;
};

class PlaceOrderOnHold extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(props) {
    props["order_id"] = this.props.order.order.id;
    this.props.placeOrderOnHold(props);
  }

  render() {
    const { handleSubmit, submitting, invalid } = this.props;
    return (
      <div className="item-display-container">
        <Header color="orange" as="h2">
          Place this order on hold
        </Header>
        <Form
          onSubmit={handleSubmit(this.onFormSubmit)}
          loading={this.props.ordersLoading}
        >
          <Field
            name="hold_reason"
            required={true}
            placeholder="Breifly explain why you want to place this order on hold"
            component={textAreaField}
          />
          <br />
          <br />

          <Button
            content="Place On Hold"
            disabled={submitting || invalid}
            type="submit"
            size="huge"
            color="orange"
            fluid
          />
          <div style={{ textAlign: "center" }}>
            <Divider horizontal>Or</Divider>

            <Button
              animated="vertical"
              size="big"
              loading={this.props.ordersLoading}
              color="blue"
              onClick={() =>
                history.push(
                  `/scan-pick-slips/pick-pack/${this.props.order.order.id}`
                )
              }
            >
              <Button.Content visible>
                {" "}
                Go Back To {this.props.step}
              </Button.Content>
              <Button.Content hidden>
                <Icon name="arrow left" />
              </Button.Content>
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

PlaceOrderOnHold = reduxForm({
  form: "PlaceOrderOnHoldForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(PlaceOrderOnHold);

function mapStateToProps(state) {
  return {
    ordersLoading: state.orders.orders_loading
  };
}


export default connect(
  mapStateToProps,
  { placeOrderOnHold }
)(PlaceOrderOnHold);
