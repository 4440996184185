import { createStore, compose, applyMiddleware } from "redux";
import reducers from "./reducers";
import thunk from "redux-thunk";
import * as Sentry from "@sentry/browser";

var composeEnhancers;
// If ENV not in dev, do not allow REDUX Dev Tools
if (process.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
  Sentry.init({
    dsn: "https://10ca7254797944b0a2bb62427c1a43ca@sentry.io/1421936"
  });
}

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default store;
