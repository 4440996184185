import * as types from "../actions/types";

const INITIAL_STATE = {
  fulfillment_users: null,
  fulfillment_users_loading: false,
  last_refreshed: new Date().toLocaleString("en-US", {
    timeZone: "America/New_York"
  })
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TOGGLE_FULFILLMENT_USERS_LOADING:
      return {
        ...state,
        fulfillment_users_loading: !state.fulfillment_users_loading
      };
    case types.FETCH_ALL_FULFILLMENT_USERS_SUCCESS:
      return {
        ...state,
        fulfillment_users: action.payload,
        fulfillment_users_loading: false,
        last_refreshed: new Date().toLocaleString("en-US", {
          timeZone: "America/New_York"
        })
      };

    default:
      return state;
  }
}
