import React, { createRef } from "react";
import { Input, Header, Button } from 'semantic-ui-react'
import history from "../../history";
import { toast } from "react-toastify";

// Allows users to quickly switch between accounts
class PickSlipIDEntry extends React.Component {
    inputRef = createRef()
    constructor(props) {
        super(props);
        this.state = {
            pickSlipID: ""
        };

    }

    // Set the pick slip id into state
    setPickSlipID = async (newPickSlipID) => {
        this.setState({
            pickSlipID: newPickSlipID
        })
   
        if (this.props.amazon) {

                if (newPickSlipID.length === 25) {
                    let orderID = await  this.props.fetchSingleOrder(newPickSlipID)
                    // console.log(orderID)
                    // If there is an order within the order array then push to the pick pack dockl
                    if (orderID) {
                        // Push to that page for the order id
                        history.push("/scan-amz-pick-slips/" + orderID);
        
                        // REset the pick slip id
                        this.setState({
                            pickSlipID: ""
                        })
                    } else {
                        toast.error("We could not find the amzion order you were looking for", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        // REset the pick slip id
                        this.setState({
                            pickSlipID: ""
                        })
                    }
                }
            } else {
                if (newPickSlipID.length === 18) {
                    let orderID = await  this.props.fetchSingleOrder(newPickSlipID)
                    // console.log(orderID)
                    // If there is an order within the order array then push to the pick pack dockl
                    if (orderID) {
                        // Push to that page for the order id
                        history.push("/scan-pick-slips/" + orderID);
        
                        // REset the pick slip id
                        this.setState({
                            pickSlipID: ""
                        })
                    } else {
                        toast.error("We could not find the order you were looking for", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        // REset the pick slip id
                        this.setState({
                            pickSlipID: ""
                        })
                    }
                }
            }
        }


    render() {
        let { loading } = this.props;
        let { pickSlipID } = this.state;

        return (
            <div className="pick-slip-search-container">
                <Header>Click the search bar and scan the bar code on the pick slip or <br /> type in the Pick Slip ID manually here.</Header>
                <div className="pick-slip-id-search">
                    <br />
                    <Input 
                        size='massive' 
                        ref={this.inputRef}
                        disabled={loading}
                        value={pickSlipID}
                        onChange={e => this.setPickSlipID(e.target.value)}
                        icon="search"
                        loading={loading}
                        placeholder='Pick Slip ID...' 
                    />
                    <br />
                    <br />
                    <Button
                        size='massive' 
                        fluid
                        onClick={() => this.setPickSlipID("")}
                        disabled={!pickSlipID}
                    >
                        CLEAR PICK SLIP ID
                    </Button>
                </div>
            </div>
        );
    }
}

export default PickSlipIDEntry;
