import React from "react";
import { Header, Label, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

var dateFormat = require("dateformat");

class DockMenu extends React.Component {

  renderBackLink() {
    if (this.props.pickPackError) {
      return "/pick-pack-errors"
    } else {
      return "/shipments"
    }
  }
  render() {
    var order = this.props.order;
    return (
      <div className="pick-pack-menu">
        <Header size="large" className="menu-header">
          Shipment: {order.order.id}
        </Header>
        <Label color={"teal"} image>
          <Label.Detail>
            {dateFormat(order.order.date_pick_packed, " mmmm dS, h:MM:ss TT")}
          </Label.Detail>
        </Label>

        <Link to={this.renderBackLink()}>
          <Icon
            name="close"
            size="big"
            className="float-right"
            style={{ color: "black" }}
          />
        </Link>
      </div>
    );
  }
}

export default DockMenu;
