import React from "react";
import ReactTable from "react-table";
import { Segment, Button, Icon } from "semantic-ui-react";
import {Link} from "react-router-dom";
var dateFormat = require("dateformat");

class PickSlipTable extends React.Component {
    render() {
        return (
            <Segment className="table-container" loading={this.props.loading}>
                <ReactTable
                    data={this.props.data}
                    defaultPageSize={5}
                    noDataText="No Pick Slip Groups Found"
                    columns={[
                        {
                            Header: "Pick Slip Group ID",
                            id: "pick_slip_id",
                            Cell: ({original}) => {
                                return (
                                    <Link to={this.props.amazon ? `/create-amz-pick-slips?pickSlipID=${original.pick_slip_id}` : `/create-pick-slips?pickSlipID=${original.pick_slip_id}`}>
                                        {original.pick_slip_id}
                                    </Link>
                                );
                            }
                        },
                        {
                            Header: "Date Printed",
                            id: "time_pick_slip_printed",
                            accessor: d => d.time_pick_slip_printed,
                            Cell: ({original}) => {
                                return (
                                    <p>
                                        {dateFormat(original.time_pick_slip_printed, " mm/dd/yyyy, h:MM:ss TT")}
                                    </p>
                                );
                            }
                        },
                        {
                            Header: "Orders Remaining",
                            id: "orders_remaining",
                            accessor: d => d.orders_remaining,
                        },
                        {
                            Header: "Reprint",
                            id: "status",
                            width: 173,
                            Cell: ({ original }) => {
                                return (
                                    <Button
                                        animated="vertical"
                                        size="mini"
                                        color="green"
                                        onClick={() => this.props.amazon ? window.open(`/print-amz-pick-slips?pickSlipID=${original.pick_slip_id}&reprint=True`, '_blank').focus() : window.open(`/print-pick-slips?pickSlipID=${original.pick_slip_id}&reprint=True`, '_blank').focus()}
                                        fluid
                                    >
                                        <Button.Content visible> Reprint Remaining Orders</Button.Content>
                                        <Button.Content hidden>
                                            <Icon name="print" />
                                        </Button.Content>
                                </Button>
                                );
                            }
                        },
                    ]}
                    className=" -highlight"
                />
            </Segment>
        );
    }
}

export default PickSlipTable;
