import React from "react";
import {  Header} from "semantic-ui-react";

class BoxAssembly extends React.Component {

  render() {
    return (
      <div>
        <div>
            <div className="">
                <br />
                <br />
                <Header as="h1">Glass Box Assembly Video</Header>
                
                <div class="wistia_responsive_padding" style={{padding:"56.25% 0 0 0", position:"relative"}}>
                    <div class="wistia_responsive_wrapper" style={{height:"100%",left:0, position:"absolute", top:0, width:"100%"}}>
                        <iframe src="https://fast.wistia.net/embed/iframe/mrf5jypcay?seo=false&videoFoam=true" title="Box Assembly Video" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%"></iframe>
                    </div>
                </div>
                <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>

            </div>
          </div>
      </div>
    );
  }
}



export default BoxAssembly
