import React from "react";
import {
    Button,
    Divider,
    Header,
    Icon,
    Segment
} from "semantic-ui-react";
import history from "../../../../history";
import { boxTypes } from "../../../../helpers";

class BoxTypeSelection extends React.Component {
    renderBoxTypes() {
        const boxTypeIDArray = Object.keys(boxTypes);
        const boxTypesArray = []
        boxTypeIDArray.map(function(boxTypeID) {
            let boxType = boxTypes[boxTypeID]
            if (boxType.eligable) {
                boxType.realID = boxTypeID
                boxTypesArray.push(boxType)
            }
            return boxTypeIDArray
        })
        
        return boxTypesArray.map((boxObject) => {
            return (
                <button key={boxObject.id} onClick={() => this.props.changeBoxType(boxObject.realID)}>
                    <Segment  size="massive"   className={this.props.selectedBoxtype === boxObject.realID && "box-selected"}>
                        <img
                            alt={boxObject.components[0].name}
                            src={boxObject.components[0].image}
                            className="box-selection-image"
                        />
                        <Header as="h3">{boxObject.components[0].sku}</Header>
                        <br />
                    </Segment>
                </button>
            )
        })
    }

  render() {
    return (
        <div className="item-display-container">
            <Header
                as="h2"
                content="Box Type"
                subheader="Required: Please select the box you used to fulfill the order"
            />
            <div class="box-selection-row">
                {this.renderBoxTypes()}
            </div>
           
            <div className="pick-pack-dock-footer">
                    <Divider horizontal>Then</Divider>
                    <div style={{ textAlign: "center" }}>
                        <Button
                        animated="vertical"
                        size="massive"
                        disabled={!this.props.selectedBoxtype}
                        color="teal"
                        fluid
                        onClick={() => this.props.setPickPackStep("Label Printing")}
                        >
                        <Button.Content visible> Next Step (Label Printing)</Button.Content>
                            <Button.Content hidden>
                                <Icon name="arrow right" />
                            </Button.Content>
                        </Button>
                    </div>
                </div>
                {this.props.order.order.shipment_status === "Awaiting Shipment" && (
                <div style={{ textAlign: "center" }}>
                    <Divider horizontal>Or</Divider>
                    <Button
                        size="big"
                        
                        color="orange"
                        onClick={() =>
                            history.push(
                            `/scan-pick-slips/pick-pack/${this.props.order.order.id}/place-on-hold`
                            )
                        }
                    >
                        Place Order On Hold
                    </Button>
                </div>
            )}
            </div>
        );
    }
}

export default BoxTypeSelection;
