import React from "react";
import { getQuantityColor } from "../../../../helpers";
import {
  Segment,
  Label,
  Button,
  Divider,
  Message,
  Header,
  Icon,
  Grid,
  Modal,
  Image
} from "semantic-ui-react";


class ItemDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnWidth: 16,
      displayModal: false,
      imageSource: null
    };
    this.renderBoxes = this.renderBoxes.bind(this);
  }
  componentWillMount() {
    this.setState({
      columnWidth: Math.floor(
        this.state.columnWidth / this.props.finalBoxConfiguration.length
      )
    });
  }

  toggleModalDisplay(imageSource) {
    this.setState({
      displayModal: !this.state.displayModal,
      imageSource: imageSource
    });
  }
  renderItems(cartItems) {
    var that = this;
    return cartItems.map(function(cartItem) {
      return (
        <Segment key={cartItem.marketing_name}>
          <img
            alt={cartItem.marketing_name}
            src={cartItem.image}
            onClick={() => that.toggleModalDisplay(cartItem.image)}
            className="cart-item-image"
          />
          {cartItem.marketing_name}
          <br />
          <Header as="h3">{cartItem.sku}</Header>
          <Label
            size="huge"
            className="quantity-label"
            color={getQuantityColor(cartItem.quantity)}
          >
            × {cartItem.quantity}
          </Label>
        </Segment>
      );
    });
  }
  renderInsertComponents(insertType) {
    return (
      <Segment>
        <img
          alt={insertType.name}
          src={insertType.image}
          onClick={() => this.toggleModalDisplay(insertType.image)}
          className="cart-item-image"
        />
        {insertType.name}
        <br />
        <Header as="h3">{insertType.sku}</Header>
        <Label size="huge" className="quantity-label">
          × 1
        </Label>
      </Segment>
    );
  }
  renderBoxComponents(boxComponents) {
    var that = this;
    var count = 0;
    if (boxComponents) {

      return boxComponents.map(function(boxComponent) {
        count += 1;
        return (
          <Segment key={boxComponent.name + count}>
            <img
              alt={boxComponent.name}
              src={boxComponent.image}
              className="cart-item-image"
              onClick={() => that.toggleModalDisplay(boxComponent.image)}
            />
            Recommended  {boxComponent.name} Type
            <br />
            <Header as="h3">{boxComponent.sku}</Header>
            <Label size="huge" className="quantity-label">
              × 1
            </Label>
          </Segment>
        );
      });
    } else {
      return null
    }
  }
  renderBoxes() {
    var count = 0;
    var that = this;
    return this.props.finalBoxConfiguration.map(function(boxConfiguration) {
      // console.log(boxConfiguration)
      count += 1;
      return (
        <Grid.Column key={count} width={that.state.columnWidth}>
          <Header
            as="h2"
            content="Pick Pack"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          />
          <br />

          {
            that.props.order.order.pick_pack_special_instructions && (
              <Message size='large' info>{that.props.order.order.pick_pack_special_instructions}</Message>
              
            )
          }
          

          <Segment.Group size="small" piled>
            {
              boxConfiguration.overBox && (
                that.renderBoxComponents(boxConfiguration.overBox.components)
              )
            }
            {that.renderBoxComponents(boxConfiguration.box.components)}
            {
              boxConfiguration.insertType && (
                that.renderInsertComponents(boxConfiguration.insertType)
              )
            }
            {that.renderItems(boxConfiguration.items)}
          </Segment.Group>
        </Grid.Column>
      );
    });
  }
  render() {
    return (
      <div className="item-display-container">
        <Modal
          closeIcon
          open={this.state.displayModal}
          onClose={() => this.toggleModalDisplay()}
          basic
          size="small"
        >
          <Modal.Content>
            <Image
              src={this.state.imageSource}
             
              centered
              size="huge"
            />
          </Modal.Content>
        </Modal>
        <Grid>
          <Grid.Row>{this.renderBoxes()}</Grid.Row>
          <br />
          <br />
          <br />
          <br />
          <br />
        </Grid>

        <div className="pick-pack-dock-footer">
          <Divider horizontal>THEN</Divider>
          <div style={{ textAlign: "center" }}>
            <Button
              animated="vertical"
              size="massive"
              color="teal"
              fluid
              onClick={() => this.props.setPickPackStep("Box Type")}
            >
              <Button.Content visible> Next Step (Box Type)</Button.Content>
              <Button.Content hidden>
                <Icon name="arrow right" />
              </Button.Content>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ItemDisplay;
