import React from "react";
var dateFormat = require("dateformat");


// Single Print SLip
class PrintSlipSummary extends React.Component { 


    renderSummaryStats(orders, pickSlipID) {
        let totalOrders = orders.length

        let lineItems = 0;
        let totalUnits = 0;

        orders.map(function(order) {
            order.cart_items.map(function(cartItem) {
                lineItems += 1;
                totalUnits += cartItem.quantity;

                return cartItem;
            })

            return order;
        })

        return (
            <div className="order-information-container">
                <p><b>Date Printed:</b> {dateFormat( null, " mm/dd/yyyy, h:MM:ss TT")}</p>
                <p><b>Pick Slip ID:</b> {pickSlipID}</p>
                <p><b>Total Orders:</b> {totalOrders}</p>
                <p><b>Total Line Items:</b> {lineItems}</p>
                <p><b>Total Units:</b> {totalUnits}</p>
            </div>
        )
    }

    render() {
        let { orders, pickSlipID } = this.props;
       
        return (
            <div className="single-slip-container">
               {this.renderSummaryStats(orders, pickSlipID)}
            </div>
        );
    }
}

export default PrintSlipSummary
