import React from "react";
import { connect } from "react-redux";
import { fetchAllShipments } from "../../actions/actionsShipments";
import {Segment, Button} from "semantic-ui-react";
import SubMenuContainer from "../Common/SubMenuContainer";
import Table from "../../components/Shipments/Table";
import { getOrderFromURL, extractIDFromPath } from "../../helpers";
import TableActions from "../../components/Shipments/TableActions";
import ShipmentDock from "./ShipmentDock";
import mouseTrap from "react-mousetrap";

var debounce = require("lodash.debounce");

class ShipmentsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: {},
      selectAll: 0,
      searchTerm: "",
      sixtyRecent: false,
      activeShipment: null
    };
    this.searchShipments = debounce(this.searchShipments, 2000);
    this.setSearchTerm = this.setSearchTerm.bind(this);
    this.setActiveShipmentObject = this.setActiveShipmentObject.bind(this);
  }

  // Fetches all the orders from the DJ DB
  componentWillMount() {
    if (this.props.shipments) {
      var shipmentObject = getOrderFromURL(
        this.props.location,
        this.props.shipments
      );
      this.setActiveShipmentObject(shipmentObject);
    }
  }


  // CHeck if the current URL does not match the old one
  componentWillReceiveProps(nextProps) {
    var nextURL = nextProps.location.pathname;
    if (nextProps.shipments) {
      if (extractIDFromPath(nextURL)) {
        var shipmentObject = getOrderFromURL(nextURL, nextProps.shipments);
        this.setActiveShipmentObject(shipmentObject);
      } else {
        this.setActiveShipmentObject(null);
      }
    }
  }

  // Sets the active shipment object in state
  setActiveShipmentObject(shipmentObject) {
    if (shipmentObject) {
      this.setState({
        activeShipment: shipmentObject
      });
    } else {
      this.setState({
        activeShipment: null
      });
    }
  }

  searchShipments(term) {
    // console.log("search shipments")
    this.props.fetchAllShipments(null, true);
    this.props.fetchAllShipments(term);
  }
  // Sets the search terms fro the orders search bar
  setSearchTerm(term) {
    this.setState({
      searchTerm: term,
      sixtyRecent: false
    });
    if (term === "" || !term) {
      // console.log("not searching")
    } else {
      this.searchShipments(term, false);
    }

  }

  getSixtyShipments() {
    this.setState({
      sixtyRecent: true
    });
    this.props.fetchAllShipments(null, true);
    this.props.fetchAllShipments("60", false);
  }




  // If the data is still loading load nothign
  renderData() {
    if (this.props.shipments) {
      var data = this.props.shipments;


      return data;
    } else {
      return null;
    }
  }

  // Renders the total order count for the Sub Menu counter
  renderShipmentCount() {
    if (this.props.shipments) {
      return this.props.shipments.length.toString();
    } else {
      return null;
    }
  }
  render() {
    return (
      <div>
        <SubMenuContainer
          title={"Search Shipments"}
          count={this.renderShipmentCount()}
          lastRefresh={this.props.lastRefresh}
          noButton={true}
          loading={this.props.shipmentsLoading}
        />
        <div className="content-body-container">
          <TableActions
            loading={this.props.shipmentsLoading}
            selected={this.state.selected}
            searchTerm={this.state.searchTerm}
            setSearchTerm={this.setSearchTerm}
            changeFilter={this.changeFilter}
            filterValue={this.state.filterValue}
          />
          {
            this.renderData() && (this.state.searchTerm !== "" || this.state.sixtyRecent) ? (
              <Table
                loading={this.props.shipmentsLoading}
                data={this.renderData()}
                loggedInUser={this.props.loggedInUser}
                selected={this.state.selected}
                selectAll={this.state.selectAll}

              />
            ) :
            <>
            <br />
            <Segment className="table-container" loading={this.props.shipmentsLoading}>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <h2 style={{textAlign: "center"}}>Please search for a shipment</h2>
              <h3 style={{textAlign: "center"}}>You can search for a name "Jeff" or order id # "34234" </h3>
              <h3 style={{textAlign: "center"}}>or</h3>
              <div style={{textAlign: "center"}}>
                <Button
                  loading={this.state.sixtyRecent}
                  color="teal"
                  className="submenu-button"
                  onClick={() => this.getSixtyShipments()}
                >
                  GET 60 MOST RECENT SHIPMENTS
                </Button>
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </Segment>
            </>
          }
       
          <ShipmentDock
            location={this.props.location}
            activeShipment={this.state.activeShipment}
            setActiveShipmentObject={this.setActiveShipmentObject}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    shipmentsLoading: state.shipments.shipments_loading,
    shipments: state.shipments.shipments,
    lastRefresh: state.shipments.last_refreshed,
    loggedInUser: state.auth.logged_in_user
  };
}

export default mouseTrap(
  connect(
    mapStateToProps,
    { fetchAllShipments }
  )(ShipmentsContainer)
);
