import React from "react";
import { connect } from "react-redux";
import { fetchWorkStationPrinterIDs, setWorkStationPrinterIDs } from "../../actions/actionsOrders";
import SubMenuContainer from "../Common/SubMenuContainer";
import { Button, Form } from 'semantic-ui-react'

// Generates a list of slips to print based on the print slip group ID   
class EditWorkStationPrinterIds extends React.Component { 
    constructor(props) {    
        super(props);
        this.state = {
            workStationPrinterIDs: null
        };
    }

    componentDidMount() {
        this.props.fetchWorkStationPrinterIDs()
        
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            workStationPrinterIDs: nextProps.workStationPrinterIDs
        })
    }

    editWorkStationPrinterID(workStationID, newValue) {
        let newWorkStationPrinterIDs = Object.assign(this.state.workStationPrinterIDs, {})
        newWorkStationPrinterIDs[workStationID] = newValue
        this.setState({
            workStationPrinterIDs: newWorkStationPrinterIDs
        })
    }

    submitValues() {
        this.props.setWorkStationPrinterIDs(this.state.workStationPrinterIDs)
    }

    render() {
        const {
            workStationPrinterIDs
        } = this.state;

        if (!workStationPrinterIDs) {
            return <div /> 
        }

        return (
            <div>
                <SubMenuContainer
                    title={"Work Station Printer Ids"}
                    noButton={true}
                />
                    <div className="content-body-container">
                        <div
                            style={{
                                height: "30px",
                                marginTop: "15px"
                            }}
                        >
                            <Form onSubmit={() => this.submitValues()}>
                            <Form.Field>
                                <label>Work Station 1 Printer ID</label>
                                <input value={workStationPrinterIDs.WS1} type="number" onChange={(event) => this.editWorkStationPrinterID("WS1", event.target.value)} />
                            </Form.Field>
                            <Form.Field>
                                <label>Work Station 2 Printer ID</label>
                                <input value={workStationPrinterIDs.WS2} type="number" onChange={(event) => this.editWorkStationPrinterID("WS2", event.target.value)} />
                            </Form.Field>
                            <Form.Field>
                                <label>Work Station 3 Printer ID</label>
                                <input value={workStationPrinterIDs.WS3} type="number" onChange={(event) => this.editWorkStationPrinterID("WS3", event.target.value)} />
                            </Form.Field>
                            <Form.Field>
                                <label>Work Station 4 Printer ID</label>
                                <input value={workStationPrinterIDs.WS4} type="number" onChange={(event) => this.editWorkStationPrinterID("WS4", event.target.value)} />
                            </Form.Field>
                            <Form.Field>
                                <label>Work Station 5 Printer ID</label>
                                <input value={workStationPrinterIDs.WS5} type="number" onChange={(event) => this.editWorkStationPrinterID("WS5", event.target.value)} />
                            </Form.Field>
                            <Form.Field>
                                <label>Work Station 6 Printer ID</label>
                                <input value={workStationPrinterIDs.WS6} type="number" onChange={(event) => this.editWorkStationPrinterID("WS6", event.target.value)} />
                            </Form.Field>
                            <Form.Field>
                                <label>Work Station 7 Printer ID</label>
                                <input value={workStationPrinterIDs.WS7} type="number" onChange={(event) => this.editWorkStationPrinterID("WS7", event.target.value)} />
                            </Form.Field>
                            <Button type='submit' fluid color="green" size="large">Save IDs</Button>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        workStationPrinterIDs: state.orders.work_station_printer_ids,
    };
}

export default connect(
    mapStateToProps,
    {  fetchWorkStationPrinterIDs, setWorkStationPrinterIDs  }
)(EditWorkStationPrinterIds);
