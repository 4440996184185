import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import history from "../history";
import { toast } from "react-toastify";
import BASE_API_URL from "./env";

axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

// Retrives Logged In User Information Upon Initial APplication Load
export function fetchAllOrdersSuccess(json) {
  return {
    type: types.FETCH_ALL_AWAITING_ORDERS_SUCCESS,
    payload: json
  };
}

// Triggers the user loading function
export function toggleOrdersLoading() {
  return { type: types.TOGGLE_AWAITING_ORDERS_LOADING, payload: null };
}

// Increase the amount of orders fulfilled by one
export function increaseOrdersFulfilled() {
  return { type: types.INCREASE_ORDERS_FULFILLED_COUNT, payload: null };
}

// Fetch all orders awatint shipments
export function fetchAllOrders(pickSlipID) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleOrdersLoading());
    return axios({
      url: `${BASE_API_URL}fulfillment/get-all-orders/${pickSlipID && "?pick_slip_id=" + pickSlipID}`,
      timeout: 40000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchAllOrdersSuccess(response.data.orders));
      })
      .catch(function(error) {
        toast.error("Hmmm, there seems to be an error retrieving more orders", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };
}

// Fetch all orders awatint shipments
export function fetchSingleOrder(pickSlipID, orderID) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleOrdersLoading());
    return axios({
      url: `${BASE_API_URL}fulfillment/get-single-awaiting-order?pick_slip_id=${pickSlipID}&order_id=${orderID}`,
      timeout: 40000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchAllOrdersSuccess(response.data.orders));
        // console.log(response.data)
        return response.data.orders[0].order.id
      })
      .catch(function(error) {
        toast.error("Hmmm, there seems to be an error retrieving more orders", {
          position: toast.POSITION.TOP_RIGHT
        });
        setTimeout(function() {
          history.push("/scan-pick-slips");
        }, 1300);
        return null
      });
  };
}

// Fetch all orders awatint shipments
export function placeOrderOnHold(data) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleOrdersLoading());
    return axios({
      url: `${BASE_API_URL}fulfillment/place-order-on-hold/`,
      timeout: 40000,
      method: "post",
      responseType: "json",
      data: data,
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        setTimeout(function() {
          history.push("/scan-pick-slips");
        }, 1300);
        dispatch(toggleOrdersLoading());
      })
      .catch(function(error) {
        toast.error("Sorry, there was an error placing this order on hold", {
          position: toast.POSITION.TOP_RIGHT
        });
        dispatch(toggleOrdersLoading());
      });
  };
}

// Create and print order label
export function createAndPrintOrderLabel(data) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleOrdersLoading());
    return axios({
      url: `${BASE_API_URL}fulfillment/create-and-print-shipping-label/`,
      timeout: 40000,
      method: "post",
      responseType: "json",
      data: data,
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        // Set the new orders into redux state
        dispatch(toggleOrdersLoading());
        dispatch(fetchSingleOrder(null, data.transaction_id));
        
      })
      .catch(function(error) {
        dispatch(toggleOrdersLoading());
        if (error && error.response && error.response.data) {
          toast.error(error.response.data.error, {
            position: toast.POSITION.TOP_RIGHT
          });
        } else {
            toast.error("Sorry, there was an error printing your shipping label", {
              position: toast.POSITION.TOP_RIGHT
            });
        }
      });
  };
}


// Set an order as shipped
export function setOrderAsCompleted(data, pickSlipScreen) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleOrdersLoading());
    return axios({
      url: `${BASE_API_URL}fulfillment/set-order-as-shipped/`,
      timeout: 40000,
      method: "post",
      responseType: "json",
      data: data,
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        toast.success("The order has been set as shipped", {
          position: toast.POSITION.TOP_RIGHT
        });
        history.push("/scan-pick-slips");
        dispatch(increaseOrdersFulfilled());
        dispatch(toggleOrdersLoading());
      })
      .catch(function(error) {
        toast.error(
          "Sorry, there was an error setting this order as confirmed",
          {
            position: toast.POSITION.TOP_RIGHT
          }
        );
      });
  };
}

// Sets the global work station printer id
function setWorkStationPrinterIDSuccess(workStationPrinterID) {
  return {
    type: types.SET_WORKSTATION_PRINTER_ID,
    payload: workStationPrinterID
  };
}
export function setWorkStationPrinterID(workStationPrinterID) {
  return function(dispatch) {
    dispatch(setWorkStationPrinterIDSuccess(workStationPrinterID));
  };
}

// Sets the global order parser
function setOrderParseTypeSuccess(orderParseType) {
  return {
    type: types.SET_ORDER_PARSE_TYPE,
    payload: orderParseType
  };
}
export function setOrderParseType(orderParseType) {
  return function(dispatch) {
    dispatch(setOrderParseTypeSuccess(orderParseType));
  };
}


// PICK SLIPS


export function toggleGeneratingPickSlipsLoading() {
  return { type: types.TOGGLE_GENERATING_PICK_SLIPS_LOADING, payload: null };
}

export function setPickSlipId(pickSlipID) {
  return { type: types.SET_PICK_SLIP_ID, payload: pickSlipID };
}


// Create and configure the pick slips
export function generatePickSlips(data) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleGeneratingPickSlipsLoading())
    return axios({
      url: `${BASE_API_URL}fulfillment/generate-pick-slips/`,
      timeout: 40000,
      method: "post",
      responseType: "json",
      data: data,
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        // Set the new orders into redux state
        dispatch(fetchAllOrders());
        dispatch(toggleGeneratingPickSlipsLoading())
        dispatch(setPickSlipId(response.data.pick_slip_id))
      })
      .catch(function(error) {
        dispatch(toggleGeneratingPickSlipsLoading())
        toast.error("Sorry, there was an error creating the pick slips", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };
}

// Deactivate all pick slips with the transation ids
export function deactivatePickSlips(data, noHistoryPush) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleGeneratingPickSlipsLoading())
    return axios({
      url: `${BASE_API_URL}fulfillment/deactivate-pick-slips/`,
      timeout: 40000,
      method: "post",
      responseType: "json",
      data: data,
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        // Set the new orders into redux state
        dispatch(fetchAllOrders());
        dispatch(toggleGeneratingPickSlipsLoading())
        dispatch(setPickSlipId(null))
        
        // If we don't want t
        if (!noHistoryPush) {
          history.push("/create-pick-slips");
        }
        
        toast.success("Pick slip group has been removed", {
          position: toast.POSITION.TOP_RIGHT
        });
      })
      .catch(function(error) {
        dispatch(toggleGeneratingPickSlipsLoading())
        toast.error("Sorry, there was an error deactiving the pick slips", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };
}

// Fethces all of the work station printer ids
function fetchWorkStationPrinterIDsSuccess(workStationPrinterIDs) {
  return {
    type: types.FETCH_ALL_WORKSTATION_PRINTER_IDS,
    payload: workStationPrinterIDs
  };
}
export function fetchWorkStationPrinterIDs() {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    
    return axios({
      url: `${BASE_API_URL}fulfillment/manage-workstation-printer-ids/`,
      timeout: 40000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchWorkStationPrinterIDsSuccess(response.data.data));
      })
      .catch(function(error) {

        toast.error("Sorry, there was an error getting the work station printer ids", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };
}

export function setWorkStationPrinterIDs(newWorkStationPrinterIds) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    
    return axios({
      url: `${BASE_API_URL}fulfillment/manage-workstation-printer-ids/`,
      timeout: 40000,
      method: "post",
      data: newWorkStationPrinterIds,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        toast.success("Update Successful", {
          position: toast.POSITION.TOP_RIGHT
        });
      })
      .catch(function(error) {

        toast.error("Sorry, there was an error getting the work station printer ids", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };
}
