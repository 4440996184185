import React from "react";
import { Form, TextArea, Checkbox } from "semantic-ui-react";

export const plainTextField = ({
  input,
  label,
  type,
  placeholder,
  required,
  size,
  iconPosition,
  icon,
  autoComplete,
  fluid,
  meta: { touched, error, active, autofilled }
}) => (
  <Form.Input
    label={label}
    icon={icon}
    iconPosition={iconPosition}
    type={type}
    error={touched && error && !active}
    size={size}
    autoComplete={autoComplete}
    fluid={fluid}
    required={required}
    placeholder={placeholder}
    {...input}
  />
);

export const textAreaField = ({
  input,
  placeholder,
  required,
  autoComplete,
  meta: { touched, error, active, autofilled }
}) => (
  <TextArea
    autoComplete={autoComplete}
    required={required}
    placeholder={placeholder}
    {...input}
  />
);

export const checkBoxField = ({
  label,
  input: { value, onChange, ...input },
  meta: { touched, error, active, autofilled }
}) => (
  <div>
    <Checkbox
      label={label}
      {...input}
      default={false}
      toggle
      defaultChecked={!!value}
      value={input.value}
      onChange={(e, data) => onChange(data.checked)}
    />{" "}
  </div>
);
