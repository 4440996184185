import React from "react";
import { Grid, Segment, Image } from "semantic-ui-react";

class GlassReference extends React.Component {
  render() {
    return (
      <>
        <h2>Dividers</h2>
        <Segment.Group horizontal>
          {this.props.reference.dividers.map(function(divider) {
            return (
              <Segment center>
                <Image src={divider.image} />
                <br />
                <p>{divider.message}</p>
              </Segment>
            );
          })}
        </Segment.Group>
        <>
          <h2>Glass Products</h2>
          <p>
            These should be placed in descending order from largest to smallest.
          </p>
          <Grid columns={6}>
            {this.props.reference.products.glass.map(function(item) {
              return (
                <Grid.Column>
                  <Segment>
                    <Image src={item.image} />
                    <br />
                    <p>{item.name}</p>
                  </Segment>
                </Grid.Column>
              );
            })}
          </Grid>
        </>
        {this.props.reference.products.largeOther.length > 0 && (
          <>
            <h2>Large Items</h2>
            <p>
              These should be placed inside of an open slot if there is room. If
              there is no room, it should be placed underneight the funnel and
              bar soap compartment.
            </p>
            <Grid columns={6}>
              {this.props.reference.products.largeOther.map(function(item) {
                return (
                  <Grid.Column>
                    <Segment>
                      <Image src={item.image} />
                      <br />
                      <p>{item.name}</p>
                    </Segment>
                  </Grid.Column>
                );
              })}
            </Grid>
          </>
        )}

        {this.props.reference.products.smallOther.length > 0 && (
          <>
            <h2>Smaller Items</h2>
            <p>
              These should be placed Inside of the funnel bar soap compartment
              below
            </p>
            <p>
              If there is no room to place in the funnel bar soap divider, then
              you should try to squeeze them in were available
            </p>
            <Grid  columns={6}>
              {this.props.reference.products.smallOther.map(function(item) {
                return (
                  <Grid.Column>
                    <Segment>
                      <Image src={item.image} />
                      <br />
                      <p>{item.name}</p>
                    </Segment>
                  </Grid.Column>
                );
              })}
            </Grid>
          </>
        )}
        {this.props.reference.products.refills.length > 0 && (
          <>
            <h2>Refills Items</h2>
            <p>
              These should be gentyly placed inside the box whether they might fit.
            </p>
         
            <Grid  columns={6}>
              {this.props.reference.products.refills.map(function(item) {
                return (
                  <Grid.Column>
                    <Segment>
                      <Image src={item.image} />
                      <br />
                      <p>{item.name}</p>
                    </Segment>
                  </Grid.Column>
                );
              })}
            </Grid>
          </>
        )}
      </>
    );
  }
}

export default GlassReference;
