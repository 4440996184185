import React from "react";
import { Step } from "semantic-ui-react";

var steps = [ "Pick Pack", "Box Type", "Label Printing", "Confirm"];

class Steps extends React.Component {
  constructor(props) {
    super(props);

    this.renderCompletedStep = this.renderCompletedStep.bind(this);
  }

  handleClick = (e, { title }) => this.props.setPickPackStep(title);

  renderCompletedStep(step, activeStep) {
    var stepDict = {};
    var count = 1;
    steps.map(function(step) {
      stepDict[step] = count;
      count += 1;
      return step;
    });
    if (stepDict[step] >= stepDict[activeStep]) {
      return false;
    } else {
      return true;
    }
  }
  renderSteps() {
    var that = this;
    return steps.map(function(step) {
      return (
        <Step
          key={step}
          title={step}
          active={that.props.step === step}
          link
          completed={that.renderCompletedStep(step, that.props.step)}
          onClick={that.handleClick}
        />
      );
    });
  }
  render() {
    return (
      <div className="pick-pack-steps">
        <Step.Group fluid ordered>
          {this.renderSteps()}
        </Step.Group>
      </div>
    );
  }
}

export default Steps;
