// Authentication Types
export const FETCH_LOGGED_IN_USER_SUCCESS = "FETCH_LOGGED_IN_USER_SUCCESS";
export const FETCH_LOGGED_IN_USER_FAILURE_MESSAGE = "FETCH_LOGGED_IN_USER_FAIL";
export const TOGGLE_LOGGED_IN_USER_LOADING = "TOGGLE_LOGGED_IN_USER_LOADING";

export const TOGGLE_QUICK_SWAP_USER_LOADING = "TOGGLE_QUICK_SWAP_USER_LOADING";
export const SET_QUICK_SWAP_USER_FAILURE_MESSAGE = "SET_QUICK_SWAP_USER_FAILURE_MESSAGE";

// Order Types
export const FETCH_ALL_AWAITING_ORDERS_SUCCESS =
  "FETCH_ALL_AWAITING_ORDERS_SUCCESS";
export const TOGGLE_AWAITING_ORDERS_LOADING =
  "FETCH_ALL_AWAITING_ORDERS_LOADING";
export const INCREASE_ORDERS_FULFILLED_COUNT =
  "INCREASE_ORDERS_FULFILLED_COUNT";
export const SET_WORKSTATION_PRINTER_ID = "SET_WORKSTATION_PRINTER_ID";
export const SET_ORDER_PARSE_TYPE = "SET_ORDER_PARSE_TYPE";

// Pick Slips
export const TOGGLE_GENERATING_PICK_SLIPS_LOADING =
  "TOGGLE_GENERATING_PICK_SLIPS_LOADING";
export const SET_PICK_SLIP_ID =
  "SET_PICK_SLIP_ID";

// Shipment Types
export const FETCH_ALL_SHIPMENTS_SUCCESS = "FETCH_ALL_SHIPMENTS_SUCCESS";
export const FETCH_ALL_SHIPPING_COSTS = "FETCH_ALL_SHIPPING_COSTS";
export const TOGGLE_SHIPMENTS_LOADING = "TOGGLE_SHIPMENTS_LOADING";

// Projection Types
export const FETCH_SALES_PROJECTIONS_SUCCESS =
  "FETCH_SALES_PROJECTIONS_SUCCESS";
export const FETCH_INVENTORY_COMPONENTS_SUCCESS =
  "FETCH_INVENTORY_COMPONENTS_SUCCESS";
export const TOGGLE_PROJECTIONS_LOADING = "TOGGLE_PROJECTIONS_LOADING";

// User Management Types
export const FETCH_ALL_FULFILLMENT_USERS_SUCCESS =
  "FETCH_ALL_FULFILLMENT_USERS_SUCCESS";
export const TOGGLE_FULFILLMENT_USERS_LOADING =
  "TOGGLE_FULFILLMENT_USERS_LOADING";

// Workstation Printer Ids
export const FETCH_ALL_WORKSTATION_PRINTER_IDS =
  "FETCH_ALL_WORKSTATION_PRINTER_IDS";



// Dashboard Data Types
export const FETCH_ALL_DASHBOARD_DATA = "FETCH_ALL_DASHBOARD_DATA";
export const TOGGLE_DASHBOARD_DATA_LOADING = "TOGGLE_DASHBOARD_DATA_LOADING";


// Dashboard Data Types
export const FETCH_ALL_RECONCILIATION_DATA = "FETCH_ALL_RECONCILIATION_DATA";
export const TOGGLE_RECONCILIATION_DATA_LOADING = "TOGGLE_RECONCILIATION_DATA_LOADING";


// Order Types
export const FETCH_ALL_PICK_PACK_ERRORS_SUCCESS =
  "FETCH_ALL_PICK_PACK_ERRORS_SUCCESS";
export const TOGGLE_PICK_PACK_ERRORS_LOADING =
  "TOGGLE_PICK_PACK_ERRORS_LOADING";


// Inventory Types
export const FETCH_ALL_INVENTORY_COUNTS_SUCCESS =
  "FETCH_ALL_INVENTORY_COUNTS_SUCCESS";
export const FETCH_ALL_PRODUCT_INVENTORY_SUCCESS =
  "FETCH_ALL_PRODUCT_INVENTORY_SUCCESS";
export const TOGGLE_ALL_INVENTORY_LOADING =
"TOGGLE_ALL_INVENTORY_LOADING";


// Amazon Types
export const FETCH_ALL_AMAZON_AWAITING_ORDERS_SUCCESS =  "FETCH_ALL_AMAZON_AWAITING_ORDERS_SUCCESS"
export const TOGGLE_AMAZON_AWAITING_ORDERS_LOADING =  "TOGGLE_AMAZON_AWAITING_ORDERS_LOADING"
export const TOGGLE_AMAZON_GENERATING_PICK_SLIPS_LOADING =  "TOGGLE_AMAZON_GENERATING_PICK_SLIPS_LOADING"