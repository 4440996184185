import React from "react";
import Dock from "react-dock";
import { Message, Button, Divider } from "semantic-ui-react";
import Menu from "../../components/Orders/PickPack/Menu";
import Steps from "../../components/Orders/PickPack/Steps";
import { connect } from "react-redux";
import { placeOrderOnHold } from "../../actions/actionsOrders";

// Pick Pack Steps
import ItemDisplay from "../../components/Orders/PickPack/Steps/ItemDisplay";
import GlassItemDisplay from "../../components/Orders/PickPack/Steps/GlassItemDisplay";
import PlaceOnHold from "../../components/Orders/PickPack/Steps/PlaceOnHold";
import LabelPrinting from "../../components/Orders/PickPack/Steps/LabelPrinting";
import BoxTypeSelection from "../../components/Orders/PickPack/Steps/BoxTypeSelection";
import Confirmation from "../../components/Orders/PickPack/Steps/Confirmation";

import { getFinalBoxAssortment, boxTypes } from "../../helpers";

var dateFormat = require("dateformat");

var moment = require("moment");

class PickPackDock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: moment(),
      finalBoxAssortment: null,
      boxChoiceOverRide: null
    };
    this.changeBoxType = this.changeBoxType.bind(this);
  }

  componentWillMount() {
    this.setState({ finalBoxAssortment: null });
    // Configure the final box assortment on the mount stage of the dock
    if (this.props.activeOrder) {
      this.setState({
        finalBoxAssortment: getFinalBoxAssortment(this.props.activeOrder)
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.activeOrder) {
      this.setState({ finalBoxAssortment: null });
    }
    if (!this.state.finalBoxAssortment && nextProps.activeOrder) {
      this.setState({
        finalBoxAssortment: getFinalBoxAssortment(nextProps.activeOrder)
      });
    }
  }

  // Picks Whether or not if the steps should be rendered
  renderSteps() {
    if (this.props.location.pathname.includes("place-on-hold")) {
      return null;
    } else {
      return (
        <Steps
          order={this.props.activeOrder}
          step={this.props.step}
          setPickPackStep={this.props.setPickPackStep}
        />
      );
    }
  }

  // Users can manully change the auto matcailly selected box type
  changeBoxType(boxID) {
    var currentBoxAssortment = this.state.finalBoxAssortment;
    var selectedBox = boxTypes[boxID];
    if (selectedBox) {
      currentBoxAssortment[0].box = selectedBox;
    }
    

    // Change around the final dimensions & weight based on the new box type
    currentBoxAssortment.map(function(box) {
      box.totalWeight = box.box.weight;
  
      box.items.map(function(item) {
        box.totalWeight += Math.round(item.weight * item.quantity);
        return item;
      });
  
      // Pass dimensions also into box
      if (box.box.dimensions) {
        box.finalDimensions = box.box.dimensions
      }
      return box;
    });

    this.setState({
      finalBoxAssortment: currentBoxAssortment,
      boxChoiceOverRide: boxID
    });
  }
  // Render the page for the Pick Packer
  renderPickPackStep(step, finalBoxConfiguration) {
    if (this.props.location.pathname.includes("place-on-hold")) {
      return (
        <PlaceOnHold step={this.props.step} order={this.props.activeOrder} />
      );
    }
    if (step === "Pick Pack" && (!this.props.activeOrder.order.has_glass || finalBoxConfiguration.glassCount === 1)) {
      return (
        <ItemDisplay
        
          order={this.props.activeOrder}
          finalBoxConfiguration={finalBoxConfiguration}
          amazon={this.props.amazon}
          setPickPackStep={this.props.setPickPackStep}
        />
      );
    }
    if (step === "Pick Pack" && this.props.activeOrder.order.has_glass) {
      return (
        <GlassItemDisplay
       
          order={this.props.activeOrder}
          finalBoxConfiguration={finalBoxConfiguration}
          amazon={this.props.amazon}
          setPickPackStep={this.props.setPickPackStep}
        />
      );
    }

    if (step === "Box Type") {
      return (
        <BoxTypeSelection
          order={this.props.activeOrder}
          changeBoxType={this.changeBoxType}
          selectedBoxtype={this.state.boxChoiceOverRide}
          finalBoxConfiguration={finalBoxConfiguration}
          amazon={this.props.amazon}
          setPickPackStep={this.props.setPickPackStep}
        />
      );
    }

    if (step === "Label Printing") {
      return (
        <LabelPrinting
          labelIsPrinting={this.props.labelIsPrinting}
          order={this.props.activeOrder}
          finalBoxConfiguration={finalBoxConfiguration}
          noBoxTypeChoice={!this.state.boxChoiceOverRide}
          amazon={this.props.amazon}
          setPickPackStep={this.props.setPickPackStep}
        />
      );
    }
    if (step === "Confirm") {
      return (
        <Confirmation
          startTime={this.state.startTime}
          noBoxTypeChoice={!this.state.boxChoiceOverRide}
          finalBoxConfiguration={finalBoxConfiguration}
          amazon={this.props.amazon}
          data={this.props.data}
          pickSlipScreen={this.props.pickSlipScreen}
          order={this.props.activeOrder}
          setPickPackStep={this.props.setPickPackStep}
        />
      );
    }
  }

  // Functino that will take an order off hold
  takeOrderOffHold() {
    var data = {
      order_id: this.props.activeOrder.order.id,
      hold_reason: "",
      take_off_hold: true
    };
    this.props.placeOrderOnHold(data);
  }

  // Checks if there is an active order and if the doc should be rendered
  checkVisibleStatus() {
    if (this.props.activeOrder) {
      return true;
    } else {
      return false;
    }
  }

  // Renders the reason the order was placed on hold
  renderOnHoldReason() {
    if (this.props.activeOrder.order.shipment_hold_reason) {
      return (
        <div>
          <br />
          <Message warning>
            <Message.Header>This order has been placed on hold</Message.Header>
            <p>{this.props.activeOrder.order.shipment_hold_reason}.</p>
          </Message>
          <div style={{ textAlign: "center" }}>
            <Divider horizontal>Or</Divider>
            <Button
              size="medium"
              color="green"
              onClick={() => this.takeOrderOffHold()}
            >
              Take Order Off Hold
            </Button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
  // Renders the reason the order was placed on hold
  renderDelayedReason() {
    if (this.props.activeOrder.order.shipment_status === "Delayed") {

      var itemsOutOfStock = [];
      var latestestPossibleRestockDate = new Date()
  
      // Loop through the cart items and check 
      this.props.activeOrder.cart_items.map(function(cartItem) {
        if (cartItem.out_of_stock) {
            itemsOutOfStock.push(cartItem.sku)
            // If the restock date is greater then today, set it to today
            if (new Date(cartItem.restock_date) > latestestPossibleRestockDate) {
              latestestPossibleRestockDate = new Date(cartItem.restock_date)
            }
        }
        return cartItem
      })
  
      var itemsOutOfStockString = ""
      
      var count = 1;
      itemsOutOfStock.map(function(productObject) { 
        if (itemsOutOfStock.length === 1) {
          itemsOutOfStockString = productObject
        } else if (itemsOutOfStock.length === 2) {
          if (count === 1) {
            itemsOutOfStockString += productObject + " or "
          } else {
            itemsOutOfStockString += productObject
          }
        } else if (itemsOutOfStock.length > 2) {
          if (count < itemsOutOfStock.length) {
            itemsOutOfStockString += productObject + " or "
          } else {
            itemsOutOfStockString += productObject
          }
        }
        count += 1
        return productObject
      })

      return (
        <div>
          <br />
          <Message error>
            <Message.Header>This order has been delayed until {dateFormat(latestestPossibleRestockDate, " mmmm d")}</Message.Header>
            <p>Any orders containing {itemsOutOfStockString} will be available to fulfill on {dateFormat(latestestPossibleRestockDate, " mmmm d")}</p>
          </Message>
        </div>
      );
    } else {
      return null;
    }
  }
  renderPickPackChildren() {
    if (this.props.activeOrder) {
      return (
        <div className="pick-pack-container">
          <Menu order={this.props.activeOrder} pickSlipScreen={this.props.pickSlipScreen} amazon={this.props.amazon}/>
          <div className="pick-pack-content">
            {this.renderSteps()}
            {this.renderOnHoldReason()}
            {this.renderDelayedReason()}
            {this.renderPickPackStep(
              this.props.step,
              this.state.finalBoxAssortment
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
  render() {
    return (
      <Dock
        position="right"
        isVisible={this.checkVisibleStatus()}
        fluid={false}
        dockStyle={{
          background: "#fff"
        }}
        className="react-dock"
        dimStyle={{
          background: "rgba(0, 0, 0, 0.45)"
        }}
        duration={290}
        size={document.body.clientWidth * .8}
        children={this.renderPickPackChildren()}
      />
    );
  }
}

export default connect(
  null,
  {
    placeOrderOnHold
  }
)(PickPackDock);
