import React from "react";
import { Button, Grid, Divider, Header, Icon, Card } from "semantic-ui-react";
import { setOrderAsCompleted } from "../../../../actions/actionsOrders";
import { setAmazonOrderAsCompleted } from "../../../../actions/actionsAmazonOrders";
import { connect } from "react-redux";

var moment = require("moment");

class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnWidth: 16
    };
  }
  componentWillMount() {
    this.setState({
      columnWidth: Math.floor(
        this.state.columnWidth / this.props.finalBoxConfiguration.length
      )
    });
  }
  finalizeOrder() {
    var currentTime = moment();
    var difference = currentTime.diff(this.props.startTime, "seconds");

    var finalBoxConfiguration = this.props.finalBoxConfiguration
    if (finalBoxConfiguration.glassBoxLayout) {
      delete finalBoxConfiguration.glassBoxLayout; 
    }
    
    if (this.props.amazon) {
      this.props.setAmazonOrderAsCompleted({
        order_id: this.props.order.order.id,
        sales_order_id: this.props.order.order.sales_order_id,
        difference: difference,
        finalBoxConfiguration: finalBoxConfiguration
      }, this.props.pickSlipScreen);
    } else {
      this.props.setOrderAsCompleted({
        order_id: this.props.order.order.id,
        sales_order_id: this.props.order.order.sales_order_id,
        difference: difference,
        finalBoxConfiguration: finalBoxConfiguration
      }, this.props.pickSlipScreen);

    }
  }
  renderItems(cartItems) {
    return cartItems.map(function(cartItem) {
      return (
        <p key={cartItem.sku}>
          {cartItem.sku} × {cartItem.quantity}
        </p>
      );
    });
  }
  renderBoxes() {
    var count = 0;
    var that = this;
    return this.props.finalBoxConfiguration.map(function(boxConfiguration) {
      count += 1;
      return (
        <Grid.Column key={count} width={that.state.columnWidth}>
          <Card fluid>
            <Card.Content>
              <Card.Header>Picked Items (Box {count})</Card.Header>
              <Card.Meta>
                Picked these items and placed in the appropiate box.
              </Card.Meta>
              <Card.Description>
                {that.renderItems(boxConfiguration.items)}
              </Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>
      );
    });
  }

  renderSIfNeedBe() {
    if (this.props.finalBoxConfiguration.length > 1) {
      return "s";
    } else {
      return "";
    }
  }
  render() {
    return (
      <div className="item-display-container">
        <Header
          as="h2"
          content="Order Confirmation"
          subheader="Please confirm the below information before continuing on to the next order"
        />
        <Grid>
          <Grid.Row>{this.renderBoxes()}</Grid.Row>
        </Grid>
        <Divider horizontal>AND</Divider>
        <Card fluid>
          <Card.Content>
            <Card.Header>Shipping Label</Card.Header>
            <Card.Meta>
              Place label{this.renderSIfNeedBe()} on top of the sealed box
              {this.renderSIfNeedBe()} .
            </Card.Meta>
            <Card.Description>
              <p>{this.props.order.shipping_address.full_name}</p>
              <p>
                {this.props.order.shipping_address.address}{" "}
                {this.props.order.shipping_address.apt_number} <br />
              </p>
              <p>
                {this.props.order.shipping_address.city}{" "}
                {this.props.order.shipping_address.state},{" "}
                {this.props.order.shipping_address.zip_code}
              </p>
            </Card.Description>
          </Card.Content>
        </Card>
        <div className="pick-pack-dock-footer">
          <Divider horizontal>THEN</Divider>
          <div style={{ textAlign: "center" }}>
            <Button
              animated="vertical"
              size="massive"
              disabled={!this.props.order.order.shipping_label_link || this.props.noBoxTypeChoice}
              loading={this.props.ordersLoading || this.props.amazonOrdersLoading}
              color="green"
              fluid
              onClick={() => this.finalizeOrder()}
            >
              <Button.Content visible>
                Confirm (Go To Next Order)
              </Button.Content>
              <Button.Content hidden>
                <Icon name="checkmark" />
              </Button.Content>
            </Button>
            {!this.props.order.order.shipping_label_link && (
              <p>
                <br /> You need to generate a label before confirming the order.
              </p>
            )}
              {this.props.noBoxTypeChoice && (
              <p style={{textAlign: "center"}}>
                  <br /> You need to select the box type used in order to print a label.
                </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ordersLoading: state.orders.orders_loading,
    amazonOrdersLoading: state.amazonOrders.orders_loading,
  };
}

export default connect(
  mapStateToProps,
  { setOrderAsCompleted, setAmazonOrderAsCompleted }
)(Confirmation);
