import React from "react";
import { Header, Label, Icon } from "semantic-ui-react";
import { getAgeOfOrder, getAgeSeverity } from "../../../helpers";
import { Link } from "react-router-dom";

var dateFormat = require("dateformat");

class Menu extends React.Component {
  render() {
    var order = this.props.order;
    return (
      <div className="pick-pack-menu">
        <Header size="large" className="menu-header">

          Order: {order.order.id}
        </Header>
        <Label
          color={getAgeSeverity(
            new Date(),
            new Date(order.order.time_charged),
            order.order.pick_pack_priority
          )}
          image
        >
          {dateFormat(order.order.time_charged, " mmmm dS")}
          <Label.Detail>
            {getAgeOfOrder(
              new Date(),
              new Date(order.order.time_charged),
              order.order.pick_pack_priority
            )}
          </Label.Detail>
        </Label>
        <Label color={"grey"} image>
          {order.order.shipment_status}
        </Label>
        {
          this.props.amazon && (  
            <Label color={"orange"} image>
                AMAZON ORDER
            </Label>
          )
        }
        <Label color={"grey"} image>
          {order.order.shipment_status}
        </Label>
        {
          this.props.pickSlipScreen ? (
            <Link to={this.props.amazon ? "/scan-amz-pick-slips" : "/scan-pick-slips"}>
              <Icon
                name="close"
                size="big"
                className="float-right"
                style={{ color: "black" }}
              />
            </Link>
          ) : (
            <Link to="/orders">
              <Icon
                name="close"
                size="big"
                className="float-right"
                style={{ color: "black" }}
              />
            </Link>
          )
        }
      </div>
    );
  }
}

export default Menu;
