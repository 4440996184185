import React from "react";
import ReactDOM from "react-dom";
import IndexHot from "./indexHot";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<IndexHot />, document.getElementById("root"));

if (module.hot) {
  module.hot.accept("./indexHot", () => {
    const IndexHot = require("./indexHot").default;
    ReactDOM.render(<IndexHot />, document.getElementById("root"));
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
